{
  "type": "FeatureCollection",
  "name": "wb-adm0-tweaked-centroids",
  "crs": {
    "type": "name",
    "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" }
  },
  "features": [
    {
      "type": "Feature",
      "properties": {
        "ISO_A3": "SJM",
        "NAM_0": "Svalbard and Jan Mayen (Nor.)"
      },
      "geometry": { "type": "Point", "coordinates": [18.3303, 78.8705] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "BRN", "NAM_0": "Brunei Darussalam" },
      "geometry": { "type": "Point", "coordinates": [114.7458, 4.5257] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "CAF", "NAM_0": "Central African Republic" },
      "geometry": { "type": "Point", "coordinates": [20.4853, 6.5773] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "LSO", "NAM_0": "Lesotho" },
      "geometry": { "type": "Point", "coordinates": [28.2458, -29.5844] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "TUN", "NAM_0": "Tunisia" },
      "geometry": { "type": "Point", "coordinates": [9.5769, 34.1073] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "CIV", "NAM_0": "Côte d'Ivoire" },
      "geometry": { "type": "Point", "coordinates": [-5.5555, 7.6234] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "PRY", "NAM_0": "Paraguay" },
      "geometry": { "type": "Point", "coordinates": [-58.3937, -23.2338] }
    },
    {
      "type": "Feature",
      "properties": {
        "ISO_A3": "VGB",
        "NAM_0": "British Virgin Islands (U.K.)"
      },
      "geometry": { "type": "Point", "coordinates": [-64.6172, 18.4264] }
    },
    {
      "type": "Feature",
      "properties": {
        "ISO_A3": "ATF",
        "NAM_0": "French Southern and Antarctic Lands (Fr.)"
      },
      "geometry": { "type": "Point", "coordinates": [69.4896, -49.3177] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "LIE", "NAM_0": "Liechtenstein" },
      "geometry": { "type": "Point", "coordinates": [9.5589, 47.1665] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "SVK", "NAM_0": "Slovak Republic" },
      "geometry": { "type": "Point", "coordinates": [19.4862, 48.7088] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "NIC", "NAM_0": "Nicaragua" },
      "geometry": { "type": "Point", "coordinates": [-85.0042, 12.9379] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "FJI", "NAM_0": "Fiji" },
      "geometry": { "type": "Point", "coordinates": [172.5231, -17.4009] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "CPV", "NAM_0": "Cabo Verde" },
      "geometry": { "type": "Point", "coordinates": [-23.9936, 15.8969] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "ARM", "NAM_0": "Armenia" },
      "geometry": { "type": "Point", "coordinates": [44.9508, 40.2875] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "UGA", "NAM_0": "Uganda" },
      "geometry": { "type": "Point", "coordinates": [32.3904, 1.2787] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "LTU", "NAM_0": "Lithuania" },
      "geometry": { "type": "Point", "coordinates": [23.8986, 55.3385] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "PLW", "NAM_0": "Palau" },
      "geometry": { "type": "Point", "coordinates": [134.5749, 7.5103] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "VNM", "NAM_0": "Vietnam" },
      "geometry": { "type": "Point", "coordinates": [106.2999, 16.6446] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "BDI", "NAM_0": "Burundi" },
      "geometry": { "type": "Point", "coordinates": [29.8892, -3.3737] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "CHN", "NAM_0": "China" },
      "geometry": { "type": "Point", "coordinates": [103.9923, 36.5238] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "ATG", "NAM_0": "Antigua and Barbuda" },
      "geometry": { "type": "Point", "coordinates": [-61.7998, 17.0796] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "KHM", "NAM_0": "Cambodia" },
      "geometry": { "type": "Point", "coordinates": [104.9179, 12.716] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "ZMB", "NAM_0": "Zambia" },
      "geometry": { "type": "Point", "coordinates": [27.797, -13.4539] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "GIB", "NAM_0": "Gibraltar (U.K.)" },
      "geometry": { "type": "Point", "coordinates": [-5.349, 36.1399] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "IRL", "NAM_0": "Ireland" },
      "geometry": { "type": "Point", "coordinates": [-8.151, 53.1756] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "BGR", "NAM_0": "Bulgaria" },
      "geometry": { "type": "Point", "coordinates": [25.237, 42.7564] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "MMR", "NAM_0": "Myanmar" },
      "geometry": { "type": "Point", "coordinates": [96.5153, 21.1575] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "NER", "NAM_0": "Niger" },
      "geometry": { "type": "Point", "coordinates": [9.4041, 17.4175] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "IRQ", "NAM_0": "Iraq" },
      "geometry": { "type": "Point", "coordinates": [43.7644, 33.0524] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "AZE", "NAM_0": "Azerbaijan" },
      "geometry": { "type": "Point", "coordinates": [47.5216, 40.2982] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "GRC", "NAM_0": "Greece" },
      "geometry": { "type": "Point", "coordinates": [22.9159, 39.1105] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "OMN", "NAM_0": "Oman" },
      "geometry": { "type": "Point", "coordinates": [56.1044, 20.5986] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "MAC", "NAM_0": "Macau, SAR" },
      "geometry": { "type": "Point", "coordinates": [113.564, 22.143] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "LKA", "NAM_0": "Sri Lanka" },
      "geometry": { "type": "Point", "coordinates": [80.7089, 7.608] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "ZAF", "NAM_0": "South Africa" },
      "geometry": { "type": "Point", "coordinates": [25.0911, -29.0001] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "MOZ", "NAM_0": "Mozambique" },
      "geometry": { "type": "Point", "coordinates": [35.5577, -17.2592] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "GIN", "NAM_0": "Guinea" },
      "geometry": { "type": "Point", "coordinates": [-10.925, 10.445] }
    },
    {
      "type": "Feature",
      "properties": {
        "ISO_A3": "HMD",
        "NAM_0": "Heard Island and McDonald Islands (Aus.)"
      },
      "geometry": { "type": "Point", "coordinates": [73.5392, -53.0963] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "LBY", "NAM_0": "Libya" },
      "geometry": { "type": "Point", "coordinates": [18.031, 27.0402] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "GGY", "NAM_0": "Guernsey (U.K.)" },
      "geometry": { "type": "Point", "coordinates": [-2.5771, 49.4638] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "BRA", "NAM_0": "Brazil" },
      "geometry": { "type": "Point", "coordinates": [-53.0843, -10.7639] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "KAZ", "NAM_0": "Kazakhstan" },
      "geometry": { "type": "Point", "coordinates": [67.2926, 48.1634] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "MNG", "NAM_0": "Mongolia" },
      "geometry": { "type": "Point", "coordinates": [103.0714, 46.8352] }
    },
    {
      "type": "Feature",
      "properties": {
        "ISO_A3": "IOT",
        "NAM_0": "British Indian Ocean Territory (U.K.)"
      },
      "geometry": { "type": "Point", "coordinates": [71.2562, -6.339] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "SLB", "NAM_0": "Solomon Islands" },
      "geometry": { "type": "Point", "coordinates": [159.6249, -8.9133] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "TON", "NAM_0": "Tonga" },
      "geometry": { "type": "Point", "coordinates": [-175.1333, -21.1954] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "JOR", "NAM_0": "Jordan" },
      "geometry": { "type": "Point", "coordinates": [36.7853, 31.2433] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "TKM", "NAM_0": "Turkmenistan" },
      "geometry": { "type": "Point", "coordinates": [59.4032, 39.1122] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "COL", "NAM_0": "Colombia" },
      "geometry": { "type": "Point", "coordinates": [-73.0778, 3.905] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "NFK", "NAM_0": "Norfolk Island (Aus.)" },
      "geometry": { "type": "Point", "coordinates": [167.9507, -29.0278] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "ISR", "NAM_0": "Israel" },
      "geometry": { "type": "Point", "coordinates": [34.9643, 31.3598] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "SMR", "NAM_0": "San Marino" },
      "geometry": { "type": "Point", "coordinates": [12.4592, 43.9415] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "ASM", "NAM_0": "American Samoa (U.S.)" },
      "geometry": { "type": "Point", "coordinates": [-170.7375, -14.3066] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "TUR", "NAM_0": "Turkey" },
      "geometry": { "type": "Point", "coordinates": [35.1716, 39.0613] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "TUV", "NAM_0": "Tuvalu" },
      "geometry": { "type": "Point", "coordinates": [178.6724, -7.4712] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "BTN", "NAM_0": "Bhutan" },
      "geometry": { "type": "Point", "coordinates": [90.4482, 27.3899] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "MEX", "NAM_0": "Mexico" },
      "geometry": { "type": "Point", "coordinates": [-102.5409, 23.9487] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "MHL", "NAM_0": "Marshall Islands" },
      "geometry": { "type": "Point", "coordinates": [171.04, 7.1396] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "CMR", "NAM_0": "Cameroon" },
      "geometry": { "type": "Point", "coordinates": [12.7433, 5.6952] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "JEY", "NAM_0": "Jersey (U.K.)" },
      "geometry": { "type": "Point", "coordinates": [-2.0719, 49.1979] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "NCL", "NAM_0": "New Caledonia (Fr.)" },
      "geometry": { "type": "Point", "coordinates": [165.6831, -21.3178] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "BLZ", "NAM_0": "Belize" },
      "geometry": { "type": "Point", "coordinates": [-88.7083, 17.1964] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "PYF", "NAM_0": "French Polynesia (Fr.)" },
      "geometry": { "type": "Point", "coordinates": [-146.3537, -14.8719] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "ZWE", "NAM_0": "Zimbabwe" },
      "geometry": { "type": "Point", "coordinates": [29.8719, -18.9986] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "AFG", "NAM_0": "Afghanistan" },
      "geometry": { "type": "Point", "coordinates": [66.0267, 33.8316] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "SVN", "NAM_0": "Slovenia" },
      "geometry": { "type": "Point", "coordinates": [14.8084, 46.1231] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "GRL", "NAM_0": "Greenland (Den.)" },
      "geometry": { "type": "Point", "coordinates": [-41.2915, 74.7462] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "BLR", "NAM_0": "Belarus" },
      "geometry": { "type": "Point", "coordinates": [28.0484, 53.5441] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "NZL", "NAM_0": "New Zealand" },
      "geometry": { "type": "Point", "coordinates": [171.8737, -41.8073] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "JAM", "NAM_0": "Jamaica" },
      "geometry": { "type": "Point", "coordinates": [-77.3245, 18.1568] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "DNK", "NAM_0": "Denmark" },
      "geometry": { "type": "Point", "coordinates": [9.9914, 55.9917] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "NGA", "NAM_0": "Nigeria" },
      "geometry": { "type": "Point", "coordinates": [8.1014, 9.5972] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "GRD", "NAM_0": "Grenada" },
      "geometry": { "type": "Point", "coordinates": [-61.6801, 12.1149] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "WLF", "NAM_0": "Wallis and Futuna (Fr.)" },
      "geometry": { "type": "Point", "coordinates": [-176.2028, -13.2851] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "CUB", "NAM_0": "Cuba" },
      "geometry": { "type": "Point", "coordinates": [-78.9833, 21.6131] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "EGY", "NAM_0": "Arab Republic of Egypt" },
      "geometry": { "type": "Point", "coordinates": [29.8714, 26.5021] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "IMN", "NAM_0": "Isle of Man (U.K.)" },
      "geometry": { "type": "Point", "coordinates": [-4.5563, 54.2168] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "URY", "NAM_0": "Uruguay" },
      "geometry": { "type": "Point", "coordinates": [-56.0184, -32.799] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "KGZ", "NAM_0": "Kyrgyz Republic" },
      "geometry": { "type": "Point", "coordinates": [74.5295, 41.4643] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "COK", "NAM_0": "Cook Islands (N.Z.)" },
      "geometry": { "type": "Point", "coordinates": [-159.7765, -21.24] }
    },
    {
      "type": "Feature",
      "properties": {
        "ISO_A3": "CCK",
        "NAM_0": "Cocos (Keeling) Islands (Aus.)"
      },
      "geometry": { "type": "Point", "coordinates": [96.8232, -12.1508] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "SXM", "NAM_0": "Sint Maarten (Neth.)" },
      "geometry": { "type": "Point", "coordinates": [-63.0489, 18.0363] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "KSV", "NAM_0": "Kosovo" },
      "geometry": { "type": "Point", "coordinates": [20.8919, 42.5808] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "ERI", "NAM_0": "Eritrea" },
      "geometry": { "type": "Point", "coordinates": [38.8291, 15.3819] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "HKG", "NAM_0": "Hong Kong, SAR" },
      "geometry": { "type": "Point", "coordinates": [114.1445, 22.4229] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "KOR", "NAM_0": "Republic of Korea" },
      "geometry": { "type": "Point", "coordinates": [127.8132, 36.3879] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "DMA", "NAM_0": "Dominica" },
      "geometry": { "type": "Point", "coordinates": [-61.3507, 15.4359] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "SRB", "NAM_0": "Serbia" },
      "geometry": { "type": "Point", "coordinates": [20.7927, 44.2259] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "COG", "NAM_0": "Congo" },
      "geometry": { "type": "Point", "coordinates": [15.224, -0.8403] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "LBR", "NAM_0": "Liberia" },
      "geometry": { "type": "Point", "coordinates": [-9.316, 6.4508] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "SLE", "NAM_0": "Sierra Leone" },
      "geometry": { "type": "Point", "coordinates": [-11.79, 8.5603] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "TCD", "NAM_0": "Chad" },
      "geometry": { "type": "Point", "coordinates": [18.6669, 15.3712] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "SSD", "NAM_0": "South Sudan" },
      "geometry": { "type": "Point", "coordinates": [30.2784, 7.314] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "BFA", "NAM_0": "Burkina Faso" },
      "geometry": { "type": "Point", "coordinates": [-1.7361, 12.2743] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "UZB", "NAM_0": "Uzbekistan" },
      "geometry": { "type": "Point", "coordinates": [63.1149, 41.7745] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "GMB", "NAM_0": "The Gambia" },
      "geometry": { "type": "Point", "coordinates": [-15.4365, 13.4465] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "AIA", "NAM_0": "Anguilla (U.K.)" },
      "geometry": { "type": "Point", "coordinates": [-63.0425, 18.2202] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "ABW", "NAM_0": "Aruba (Neth.)" },
      "geometry": { "type": "Point", "coordinates": [-69.9915, 12.5269] }
    },
    {
      "type": "Feature",
      "properties": {
        "ISO_A3": "LAO",
        "NAM_0": "Lao People's Democratic Republic"
      },
      "geometry": { "type": "Point", "coordinates": [103.7641, 18.4974] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "MKD", "NAM_0": "FYR of Macedonia" },
      "geometry": { "type": "Point", "coordinates": [21.7078, 41.6027] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "VAT", "NAM_0": "Vatican City" },
      "geometry": { "type": "Point", "coordinates": [12.4517, 41.9029] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "PAN", "NAM_0": "Panama" },
      "geometry": { "type": "Point", "coordinates": [-80.0905, 8.5144] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "PRK", "NAM_0": "D. P. R. of Korea" },
      "geometry": { "type": "Point", "coordinates": [127.1678, 40.1412] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "GHA", "NAM_0": "Ghana" },
      "geometry": { "type": "Point", "coordinates": [-1.2054, 7.962] }
    },
    {
      "type": "Feature",
      "properties": {
        "ISO_A3": "GBR",
        "NAM_0": "Sovereign Base Areas of Akrotiri and Dhekelia (U.K.)"
      },
      "geometry": { "type": "Point", "coordinates": [-1.8124, 53.4431] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "PHL", "NAM_0": "Philippines" },
      "geometry": { "type": "Point", "coordinates": [122.8803, 11.7581] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "SYR", "NAM_0": "Syrian Arab Republic" },
      "geometry": { "type": "Point", "coordinates": [38.5104, 35.0251] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "QAT", "NAM_0": "Qatar" },
      "geometry": { "type": "Point", "coordinates": [51.1911, 25.2784] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "PNG", "NAM_0": "Papua New Guinea" },
      "geometry": { "type": "Point", "coordinates": [145.2071, -6.4688] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "BEL", "NAM_0": "Belgium" },
      "geometry": { "type": "Point", "coordinates": [4.6653, 50.6374] }
    },
    {
      "type": "Feature",
      "properties": {
        "ISO_A3": "FSM",
        "NAM_0": "Federated States of Micronesia"
      },
      "geometry": { "type": "Point", "coordinates": [158.2248, 6.8809] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "CUW", "NAM_0": "Curaçao (Neth.)" },
      "geometry": { "type": "Point", "coordinates": [-69.0005, 12.2124] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "MCO", "NAM_0": "Monaco" },
      "geometry": { "type": "Point", "coordinates": [7.4233, 43.7361] }
    },
    {
      "type": "Feature",
      "properties": {
        "ISO_A3": "MNP",
        "NAM_0": "Northern Mariana Islands (U.S.)"
      },
      "geometry": { "type": "Point", "coordinates": [145.7758, 15.2294] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "MWI", "NAM_0": "Malawi" },
      "geometry": { "type": "Point", "coordinates": [34.3045, -13.218] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "ETH", "NAM_0": "Ethiopia" },
      "geometry": { "type": "Point", "coordinates": [39.6342, 8.6324] }
    },
    {
      "type": "Feature",
      "properties": {
        "ISO_A3": "COD",
        "NAM_0": "Democratic Republic of Congo"
      },
      "geometry": { "type": "Point", "coordinates": [23.6573, -2.8755] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "HND", "NAM_0": "Honduras" },
      "geometry": { "type": "Point", "coordinates": [-86.6117, 14.8228] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "AND", "NAM_0": "Andorra" },
      "geometry": { "type": "Point", "coordinates": [1.5784, 42.5507] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "KIR", "NAM_0": "Kiribati" },
      "geometry": { "type": "Point", "coordinates": [-157.3567, 1.8668] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "RWA", "NAM_0": "Rwanda" },
      "geometry": { "type": "Point", "coordinates": [29.9245, -2.0054] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "YEM", "NAM_0": "Republic of Yemen" },
      "geometry": { "type": "Point", "coordinates": [47.5973, 15.9118] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "MDA", "NAM_0": "Moldova" },
      "geometry": { "type": "Point", "coordinates": [28.4621, 47.2009] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "DEU", "NAM_0": "Germany" },
      "geometry": { "type": "Point", "coordinates": [10.3917, 51.1067] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "IDN", "NAM_0": "Indonesia" },
      "geometry": { "type": "Point", "coordinates": [117.2253, -2.2186] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "NLD", "NAM_0": "Netherlands" },
      "geometry": { "type": "Point", "coordinates": [5.5878, 52.2299] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "BHS", "NAM_0": "The Bahamas" },
      "geometry": { "type": "Point", "coordinates": [-76.9008, 24.2286] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "ISL", "NAM_0": "Iceland" },
      "geometry": { "type": "Point", "coordinates": [-18.6205, 64.9924] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "AGO", "NAM_0": "Angola" },
      "geometry": { "type": "Point", "coordinates": [17.5506, -12.2991] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "FRO", "NAM_0": "Faroe Islands (Den.)" },
      "geometry": { "type": "Point", "coordinates": [-6.9626, 62.2103] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "HTI", "NAM_0": "Haiti" },
      "geometry": { "type": "Point", "coordinates": [-72.6686, 18.9382] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "POL", "NAM_0": "Poland" },
      "geometry": { "type": "Point", "coordinates": [19.3962, 52.1277] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "VEN", "NAM_0": "R. B. de Venezuela" },
      "geometry": { "type": "Point", "coordinates": [-66.1533, 7.1245] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "FIN", "NAM_0": "Finland" },
      "geometry": { "type": "Point", "coordinates": [26.2612, 64.5084] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "CHL", "NAM_0": "Chile" },
      "geometry": { "type": "Point", "coordinates": [-71.3266, -37.7441] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "SWZ", "NAM_0": "Eswatini" },
      "geometry": { "type": "Point", "coordinates": [31.4997, -26.5639] }
    },
    {
      "type": "Feature",
      "properties": {
        "ISO_A3": "SHN",
        "NAM_0": "Saint Helena, Ascension and Tristan da Cunha (U.K.)"
      },
      "geometry": { "type": "Point", "coordinates": [-5.714, -15.9657] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "CRI", "NAM_0": "Costa Rica" },
      "geometry": { "type": "Point", "coordinates": [-84.1959, 9.9738] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "NAM", "NAM_0": "Namibia" },
      "geometry": { "type": "Point", "coordinates": [17.2224, -22.1381] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "KNA", "NAM_0": "Saint Kitts and Nevis" },
      "geometry": { "type": "Point", "coordinates": [-62.783, 17.3505] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "PER", "NAM_0": "Peru" },
      "geometry": { "type": "Point", "coordinates": [-74.3587, -9.1697] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "GUM", "NAM_0": "Guam (U.S.)" },
      "geometry": { "type": "Point", "coordinates": [144.7798, 13.4496] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "KEN", "NAM_0": "Kenya" },
      "geometry": { "type": "Point", "coordinates": [37.8587, 0.5345] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "CXR", "NAM_0": "Christmas Island (Aus.)" },
      "geometry": { "type": "Point", "coordinates": [105.6316, -10.4753] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "ARG", "NAM_0": "Argentina" },
      "geometry": { "type": "Point", "coordinates": [-65.1686, -35.3858] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "MYS", "NAM_0": "Malaysia" },
      "geometry": { "type": "Point", "coordinates": [109.707, 3.784] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "IND", "NAM_0": "India" },
      "geometry": { "type": "Point", "coordinates": [79.3023, 22.7666] }
    },
    {
      "type": "Feature",
      "properties": {
        "ISO_A3": "VIR",
        "NAM_0": "United States Virgin Islands (U.S.)"
      },
      "geometry": { "type": "Point", "coordinates": [-64.8257, 17.7361] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "NPL", "NAM_0": "Nepal" },
      "geometry": { "type": "Point", "coordinates": [83.9473, 28.2569] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "GUY", "NAM_0": "Guyana" },
      "geometry": { "type": "Point", "coordinates": [-58.9793, 4.7911] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "MLT", "NAM_0": "Malta" },
      "geometry": { "type": "Point", "coordinates": [14.4236, 35.9055] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "STP", "NAM_0": "São Tomé and Príncipe" },
      "geometry": { "type": "Point", "coordinates": [6.5971, 0.2369] }
    },
    {
      "type": "Feature",
      "properties": {
        "ISO_A3": "BES",
        "NAM_0": "Bonaire, Sint Eustatius and Saba"
      },
      "geometry": { "type": "Point", "coordinates": [-67.7792, 12.7133] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "IRN", "NAM_0": "Islamic Republic of Iran" },
      "geometry": { "type": "Point", "coordinates": [54.305, 32.5626] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "WSM", "NAM_0": "Samoa" },
      "geometry": { "type": "Point", "coordinates": [-172.1406, -13.7571] }
    },
    {
      "type": "Feature",
      "properties": {
        "ISO_A3": "TCA",
        "NAM_0": "Turks and Caicos Islands (U.K.)"
      },
      "geometry": { "type": "Point", "coordinates": [-71.9423, 21.8968] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "SLV", "NAM_0": "El Salvador" },
      "geometry": { "type": "Point", "coordinates": [-88.8672, 13.735] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "TTO", "NAM_0": "Trinidad and Tobago" },
      "geometry": { "type": "Point", "coordinates": [-61.2614, 10.4542] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "MNE", "NAM_0": "Montenegro" },
      "geometry": { "type": "Point", "coordinates": [19.2541, 42.7878] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "HUN", "NAM_0": "Hungary" },
      "geometry": { "type": "Point", "coordinates": [19.4155, 47.1657] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "NIU", "NAM_0": "Niue (N.Z.)" },
      "geometry": { "type": "Point", "coordinates": [-169.8617, -19.059] }
    },
    {
      "type": "Feature",
      "properties": {
        "ISO_A3": "VCT",
        "NAM_0": "Saint Vincent and the Grenadines"
      },
      "geometry": { "type": "Point", "coordinates": [-61.1937, 13.2425] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "DZA", "NAM_0": "Algeria" },
      "geometry": { "type": "Point", "coordinates": [2.6776, 28.1584] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "LVA", "NAM_0": "Latvia" },
      "geometry": { "type": "Point", "coordinates": [24.9312, 56.8547] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "SOM", "NAM_0": "Somalia" },
      "geometry": { "type": "Point", "coordinates": [45.8693, 6.0634] }
    },
    {
      "type": "Feature",
      "properties": {
        "ISO_A3": "AUS",
        "NAM_0": "Ashmore and Cartier Islands (Aus.)"
      },
      "geometry": { "type": "Point", "coordinates": [134.4912, -25.7361] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "NRU", "NAM_0": "Nauru" },
      "geometry": { "type": "Point", "coordinates": [166.9324, -0.5304] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "THA", "NAM_0": "Thailand" },
      "geometry": { "type": "Point", "coordinates": [101.0139, 15.116] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "BMU", "NAM_0": "Bermuda (U.K.)" },
      "geometry": { "type": "Point", "coordinates": [-64.7257, 32.3211] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "ITA", "NAM_0": "Italy" },
      "geometry": { "type": "Point", "coordinates": [12.0849, 42.7975] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "TLS", "NAM_0": "Timor-Leste" },
      "geometry": { "type": "Point", "coordinates": [125.8663, -8.82] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "GEO", "NAM_0": "Georgia" },
      "geometry": { "type": "Point", "coordinates": [43.5064, 42.1796] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "BIH", "NAM_0": "Bosnia and Herzegovina" },
      "geometry": { "type": "Point", "coordinates": [17.7837, 44.1668] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "SUR", "NAM_0": "Suriname" },
      "geometry": { "type": "Point", "coordinates": [-55.8723, 4.1017] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "PAK", "NAM_0": "Pakistan" },
      "geometry": { "type": "Point", "coordinates": [69.3833, 29.9944] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "DOM", "NAM_0": "Dominican Republic" },
      "geometry": { "type": "Point", "coordinates": [-70.5025, 18.9022] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "VUT", "NAM_0": "Vanuatu" },
      "geometry": { "type": "Point", "coordinates": [167.6538, -16.2002] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "GAB", "NAM_0": "Gabon" },
      "geometry": { "type": "Point", "coordinates": [11.7909, -0.6066] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "PSE", "NAM_0": "West Bank and Gaza" },
      "geometry": { "type": "Point", "coordinates": [35.2045, 31.9099] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "LCA", "NAM_0": "Saint Lucia" },
      "geometry": { "type": "Point", "coordinates": [-60.9699, 13.891] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "BVT", "NAM_0": "Bouvet Island (Nor.)" },
      "geometry": { "type": "Point", "coordinates": [3.3504, -54.4183] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "UKR", "NAM_0": "Ukraine" },
      "geometry": { "type": "Point", "coordinates": [31.2625, 49.1723] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "TGO", "NAM_0": "Togo" },
      "geometry": { "type": "Point", "coordinates": [0.9768, 8.5395] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "SDN", "NAM_0": "Sudan" },
      "geometry": { "type": "Point", "coordinates": [29.9603, 16.0276] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "BGD", "NAM_0": "Bangladesh" },
      "geometry": { "type": "Point", "coordinates": [90.2939, 23.889] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "PRT", "NAM_0": "Portugal" },
      "geometry": { "type": "Point", "coordinates": [-8.3604, 39.6117] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "BOL", "NAM_0": "Bolivia" },
      "geometry": { "type": "Point", "coordinates": [-64.665, -16.7122] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "JPN", "NAM_0": "Japan" },
      "geometry": { "type": "Point", "coordinates": [138.0486, 37.6121] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "LUX", "NAM_0": "Luxembourg" },
      "geometry": { "type": "Point", "coordinates": [6.0962, 49.7788] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "USA", "NAM_0": "United States of America" },
      "geometry": { "type": "Point", "coordinates": [-98.1604, 38.4363] }
    },
    {
      "type": "Feature",
      "properties": {
        "ISO_A3": "SPM",
        "NAM_0": "Saint-Pierre-et-Miquelon (Fr.)"
      },
      "geometry": { "type": "Point", "coordinates": [-56.3269, 47.0744] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "SYC", "NAM_0": "Seychelles" },
      "geometry": { "type": "Point", "coordinates": [55.492, -4.719] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "GNQ", "NAM_0": "Equatorial Guinea" },
      "geometry": { "type": "Point", "coordinates": [10.3386, 1.7017] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "SEN", "NAM_0": "Senegal" },
      "geometry": { "type": "Point", "coordinates": [-14.4659, 14.3594] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "CHE", "NAM_0": "Switzerland" },
      "geometry": { "type": "Point", "coordinates": [8.2308, 46.8037] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "CZE", "NAM_0": "Czech Republic" },
      "geometry": { "type": "Point", "coordinates": [15.3286, 49.7367] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "PRI", "NAM_0": "Puerto Rico (U.S.)" },
      "geometry": { "type": "Point", "coordinates": [-66.4729, 18.225] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "DJI", "NAM_0": "Djibouti" },
      "geometry": { "type": "Point", "coordinates": [42.5789, 11.7335] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "SAU", "NAM_0": "Saudi Arabia" },
      "geometry": { "type": "Point", "coordinates": [44.5502, 24.1247] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "BHR", "NAM_0": "Bahrain" },
      "geometry": { "type": "Point", "coordinates": [50.5422, 26.0498] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "MAR", "NAM_0": "Morocco" },
      "geometry": { "type": "Point", "coordinates": [-6.2837, 31.8418] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "ECU", "NAM_0": "Ecuador" },
      "geometry": { "type": "Point", "coordinates": [-78.7254, -1.4227] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "PCN", "NAM_0": "Pitcairn Islands (U.K.)" },
      "geometry": { "type": "Point", "coordinates": [-128.3205, -24.3804] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "CYM", "NAM_0": "Cayman Islands (U.K.)" },
      "geometry": { "type": "Point", "coordinates": [-81.2402, 19.3182] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "ALB", "NAM_0": "Albania" },
      "geometry": { "type": "Point", "coordinates": [20.0718, 41.1412] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "MDV", "NAM_0": "Maldives" },
      "geometry": { "type": "Point", "coordinates": [73.5433, 1.925] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "SWE", "NAM_0": "Sweden" },
      "geometry": { "type": "Point", "coordinates": [16.7496, 62.7911] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "GNB", "NAM_0": "Guinea-Bissau" },
      "geometry": { "type": "Point", "coordinates": [-14.9367, 12.0437] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "HRV", "NAM_0": "Croatia" },
      "geometry": { "type": "Point", "coordinates": [16.4342, 45.0937] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "MUS", "NAM_0": "Mauritius" },
      "geometry": { "type": "Point", "coordinates": [57.5813, -20.2799] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "MSR", "NAM_0": "Montserrat (U.K.)" },
      "geometry": { "type": "Point", "coordinates": [-62.1959, 16.7441] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "UMI", "NAM_0": "U.S. Minor Outlying Islands" },
      "geometry": { "type": "Point", "coordinates": [-116.6291, 14.0191] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "LBN", "NAM_0": "Lebanon" },
      "geometry": { "type": "Point", "coordinates": [35.8922, 33.917] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "ROU", "NAM_0": "Romania" },
      "geometry": { "type": "Point", "coordinates": [24.9826, 45.8371] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "MRT", "NAM_0": "Mauritania" },
      "geometry": { "type": "Point", "coordinates": [-10.3357, 20.2586] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "COM", "NAM_0": "Comoros" },
      "geometry": { "type": "Point", "coordinates": [43.6697, -11.8186] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "KWT", "NAM_0": "Kuwait" },
      "geometry": { "type": "Point", "coordinates": [47.6023, 29.3394] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "TZA", "NAM_0": "Tanzania" },
      "geometry": { "type": "Point", "coordinates": [34.8168, -6.2634] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "TKL", "NAM_0": "Tokelau (N.Z.)" },
      "geometry": { "type": "Point", "coordinates": [-171.7771, -9.1846] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "AUT", "NAM_0": "Austria" },
      "geometry": { "type": "Point", "coordinates": [14.1227, 47.5853] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "GTM", "NAM_0": "Guatemala" },
      "geometry": { "type": "Point", "coordinates": [-90.3574, 15.6953] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "BWA", "NAM_0": "Botswana" },
      "geometry": { "type": "Point", "coordinates": [23.8151, -22.1873] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "BEN", "NAM_0": "Benin" },
      "geometry": { "type": "Point", "coordinates": [2.3399, 9.6521] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "EST", "NAM_0": "Estonia" },
      "geometry": { "type": "Point", "coordinates": [25.5874, 58.6712] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "CYP", "NAM_0": "Cyprus" },
      "geometry": { "type": "Point", "coordinates": [33.1999, 35.0471] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "RUS", "NAM_0": "Russian Federation" },
      "geometry": { "type": "Point", "coordinates": [96.653, 61.9638] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "BLM", "NAM_0": "Saint-Barthélemy (Fr.)" },
      "geometry": { "type": "Point", "coordinates": [-62.8285, 17.8998] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "BRB", "NAM_0": "Barbados" },
      "geometry": { "type": "Point", "coordinates": [-59.5507, 13.1641] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "ARE", "NAM_0": "United Arab Emirates" },
      "geometry": { "type": "Point", "coordinates": [54.3401, 23.9043] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "MAF", "NAM_0": "Saint-Martin (Fr.)" },
      "geometry": { "type": "Point", "coordinates": [-63.0516, 18.0791] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "SGP", "NAM_0": "Singapore" },
      "geometry": { "type": "Point", "coordinates": [103.7963, 1.3559] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "MLI", "NAM_0": "Mali" },
      "geometry": { "type": "Point", "coordinates": [-3.5123, 17.3567] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "ESP", "NAM_0": "Spain" },
      "geometry": { "type": "Point", "coordinates": [-3.6341, 40.2432] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "CAN", "NAM_0": "Canada" },
      "geometry": { "type": "Point", "coordinates": [-103.4526, 55.1105] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "MDG", "NAM_0": "Madagascar" },
      "geometry": { "type": "Point", "coordinates": [46.6963, -19.3925] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "FRA", "NAM_0": "France" },
      "geometry": { "type": "Point", "coordinates": [2.8999, 46.4834] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "NOR", "NAM_0": "Norway" },
      "geometry": { "type": "Point", "coordinates": [9.4246, 61.7802] }
    },
    {
      "type": "Feature",
      "properties": { "ISO_A3": "TJK", "NAM_0": "Tajikistan" },
      "geometry": { "type": "Point", "coordinates": [71.0101, 38.5293] }
    }
  ]
}
