import { Component } from "react";
import { NavAboutMenu } from "../components/NavAboutMenu";

import App from "./App";

import { navPages } from "./StaticPage";

class About extends Component {
  render() {
    return (
      <App pageTitle="About">
        <article className="inpage inpage--about">
          <header className="inpage__header">
            <div className="inpage__subheader">
              <div className="inpage__headline">
                <h1 className="inpage__title">About</h1>
              </div>
            </div>
          </header>
          <NavAboutMenu pages={navPages} />
          <div className="inpage__body">
            <div className="prose">
              <h1 id="all-about-the-climate-mineral-explorer">
                All about the Climate Mineral Explorer
              </h1>
              <p>
                The Climate Mineral Explorer (CME) focuses on the materiality of
                the low-carbon transition, required to combat the threat of the
                climate change and the scale of increase in demand for critical
                minerals for the transition such as aluminum, copper and lithium
                (
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://openknowledge.worldbank.org/handle/10986/28312"
                >
                  <u>World Bank, 2017</u>
                </a>
                ;{" "}
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://pubdocs.worldbank.org/en/961711588875536384/Minerals-for-Climate-Action-The-Mineral-Intensity-of-the-Clean-Energy-Transition.pdf"
                >
                  <u>World Bank, 2020</u>
                </a>
                ). The platform builds on the work of the{" "}
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://www.worldbank.org/en/topic/extractiveindustries/brief/climate-smart-mining-minerals-for-climate-action"
                >
                  <u>Climate-Smart Mining Initiative</u>
                </a>{" "}
                (CSM).
              </p>
              <p>
                There is a growing cognizance of the importance of understanding
                the supply chains of these minerals to help facilitate their
                steady and secure supply, while integrating an ESG approach to
                managing these mineral-technology supply chains. These recent
                developments have led CSM team to develop a new program to
                better understand minerals supply chains, particularly in
                respect to developing countries, as well as the associated
                emissions of the minerals needed for{" "}
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://www.un.org/sustainabledevelopment/climate-change/"
                >
                  <u>Climate Action</u>
                </a>{" "}
                (SDG 13).
              </p>
              <p>
                CME maps mineral supply chains from extraction to end-use of
                low-carbon technologies, identify where greenhouse gas (GHG)
                emissions are located within each step of the supply chain. It
                provides key information to enable users to make decisions
                regarding how these emissions could be minimized while ensuring
                a steady supply of critical minerals globally.
              </p>
              <p>
                In this first version, CME 1.0 provides view of the GHG, energy
                and shipping footprints for stakeholders along the supply chain
                of <strong>lithium</strong>, a mineral that is critical to
                deploy electric vehicles (EV) at scale to enhance climate
                action. This dashboard compares different lithium supply chain
                routes from mine to end user to determine possible options to
                reduce GHG emissions, shipping emissions and energy use, in
                accordance with user preferences and country location. In the
                second version, CME 2.0 focuses on the supply chain of
                <strong> natural graphite</strong>. The tool was meticulously
                designed in a modular format, facilitating the seamless
                incorporation of new mineral-technology combinations and policy
                recommendations, paving the way for its evolution into a
                versatile multimineral-technology platform.
              </p>
              <p>
                Data and information for this dashboard are drawn from publicly
                available sources, including the{" "}
                <a rel="noreferrer" target="_blank" href="https://www.anl.gov/">
                  <u>Argonne National Laboratory</u>
                </a>{" "}
                and{" "}
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://worldbankgroup-my.sharepoint.com/personal/caguiarparera_worldbank_org/Documents/Documents/#2021/"
                >
                  <u>International Energy Agency,</u>
                </a>{" "}
                and was obtained via an extensive review of literature. This
                data provides estimates of country and supply-chain stage
                estimates of energy, shipping and GHG emissions.
              </p>
              <p>
                This data is then used in the dashboard to provide information
                on the key global lithium and graphite supply chains, all the
                while enabling users to customize their own supply chain and
                compare the emissions footprint of their supply chain against
                others. Information and data are then provided to users to help
                them understand their options in reducing their GHG emissions.
              </p>
              <p>
                For any inquiries and potential collaboration please contact the
                development team:
              </p>
              <ul>
                <li>
                  <p>
                    <strong>
                      The World Bank, Climate Smart Mining Initiative:
                    </strong>{" "}
                    –{" "}
                    <a href="mailto:csm@worldbank.org">
                      <u>csm@worldbank.org</u>
                    </a>
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Derilinx:</strong> Eric Soroos –{" "}
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href="https://derilinx.com/"
                    >
                      <u>https://derilinx.com</u>
                    </a>
                  </p>
                </li>
              </ul>
              <h1 id="climate-smart-mining">Climate-Smart Mining </h1>
              <p>
                Climate-Smart Mining (CSM) supports the sustainable extraction,
                processing and recycling of minerals and metals needed to secure
                supply for low-carbon technologies and other critical sectors by
                creating shared value, delivering social, economic and
                environmental benefits throughout their value chain in
                developing and emerging economies.
              </p>
              <p>
                The World Bank’s Climate-Smart Mining Initiative is a
                public-private partnership led by the World Bank and IFC with
                the aim of achieving more sustainable mineral supply chains by
                providing technical and policy advice, direct investment
                financing, leveraging private sector financing, providing risk
                mitigation instruments, and helping countries define and craft
                tangible solutions for decarbonizing and improving ESG standards
                for climate action minerals.
              </p>
              <p>
                CSM achieves this objective by focusing its activities on a
                framework developed in consultation with key stakeholders in
                government, industry, and civil society, serving as guidance to
                help developing countries integrate climate-smart approaches
                through four pillars:
              </p>
              <ol type="I">
                <li>
                  <p>Decarbonization</p>
                </li>
                <li>
                  <p>Climate Resilience</p>
                </li>
                <li>
                  <p>Circular Economy</p>
                </li>
                <li>
                  <p>Market Opportunities.</p>
                </li>
              </ol>
              <p>
                <em>Figure 1: Climate-Smart Mining Framework </em>
              </p>
              <p>
                <img
                  src={
                    new URL(
                      "/app/assets/graphics/content/csm.png",
                      import.meta.url
                    )
                  }
                  style={{ maxWidth: "100%" }}
                />
              </p>
              <p>
                These four pillars formed the basis of the structure for the{" "}
                <a href="https://csm.staging.derilinx.com/policies">
                  Policies and Regulations
                </a>{" "}
                page, with information on policies, strategies, laws and targets
                categorized into one of these four pillars.
              </p>

              <h2 className="visually-hidden">Credits</h2>
              <dl className="logo-list">
                <dd>
                  <a
                    href="https://energydata.info/"
                    title="Visit Energydata.info"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      alt="WBG Logo"
                      src={
                        new URL(
                          "/app/assets/graphics/content/logos/logo-wbg-ibrd.png",
                          import.meta.url
                        )
                      }
                    />
                    <span>WBG</span>
                  </a>
                </dd>
              </dl>
            </div>
          </div>
        </article>
      </App>
    );
  }
}

export default About;
