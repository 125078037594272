{
  "type": "FeatureCollection",
  "name": "specific-routes-export",
  "features": [
    {
      "type": "Feature",
      "properties": { "SRC": "CHN", "DST": "CHN" },
      "geometry": {
        "type": "LineString",
        "coordinates": [
          [103.9923189, 36.5237805],
          [104.0026274, 36.5228475],
          [104.0896311, 36.5155134],
          [104.1767808, 36.5093998],
          [104.2640499, 36.5045088],
          [104.351412, 36.5008422],
          [104.4388403, 36.4984012],
          [104.5263082, 36.4971867],
          [104.6137893, 36.4971991],
          [104.7012567, 36.4984384],
          [104.7886839, 36.5009042],
          [104.8760443, 36.5045957],
          [104.9633113, 36.5095114],
          [105.0504583, 36.5156498],
          [105.1374588, 36.5230085],
          [105.2242863, 36.5315852],
          [105.3109144, 36.5413766],
          [105.3973167, 36.5523794],
          [105.4834669, 36.5645897],
          [105.5693388, 36.5780033],
          [105.6549062, 36.5926154],
          [105.7401431, 36.6084208],
          [105.8250235, 36.6254141],
          [105.9095216, 36.6435893],
          [105.9936118, 36.66294],
          [106.0772683, 36.6834594],
          [106.1604657, 36.7051404],
          [106.2431787, 36.7279753],
          [106.3253822, 36.7519562],
          [106.407051, 36.7770747],
          [106.4881603, 36.8033221],
          [106.5686855, 36.8306891],
          [106.6486021, 36.8591662],
          [106.7278856, 36.8887436],
          [106.806512, 36.9194109],
          [106.8844573, 36.9511574],
          [106.9616979, 36.9839722],
          [107.0382101, 37.0178438],
          [107.1139707, 37.0527606],
          [107.1889567, 37.0887103],
          [107.2631452, 37.1256806],
          [107.3365136, 37.1636588],
          [107.4090396, 37.2026316],
          [107.4807011, 37.2425858],
          [107.5514763, 37.2835074],
          [107.6213437, 37.3253825],
          [107.6902819, 37.3681967],
          [107.7582701, 37.4119353],
          [107.8252875, 37.4565833],
          [107.8913137, 37.5021255],
          [107.9563286, 37.5485462],
          [108.0203124, 37.5958298],
          [108.0832456, 37.6439601],
          [108.1451092, 37.6929207],
          [108.2058842, 37.7426951],
          [108.2655521, 37.7932663],
          [108.3240948, 37.8446174],
          [108.3814945, 37.8967308],
          [108.4377337, 37.9495892],
          [108.4927952, 38.0031747],
          [108.5466624, 38.0574693],
          [108.5993188, 38.1124549],
          [108.6507484, 38.168113],
          [108.7009355, 38.224425],
          [108.7498648, 38.2813723],
          [108.7975215, 38.3389358],
          [108.8438911, 38.3970966],
          [108.8889594, 38.4558352],
          [108.9327127, 38.5151324],
          [108.9751377, 38.5749685],
          [109.0162215, 38.635324],
          [109.0559516, 38.6961789],
          [109.0943158, 38.7575133],
          [109.1313026, 38.8193072],
          [109.1669006, 38.8815405],
          [109.201099, 38.944193],
          [109.2338874, 39.0072442],
          [109.2652558, 39.0706738],
          [109.2951947, 39.1344613],
          [109.323695, 39.1985862],
          [109.3507479, 39.2630278],
          [109.3763453, 39.3277656],
          [109.4004793, 39.3927788],
          [109.4231426, 39.4580466],
          [109.4443284, 39.5235484],
          [109.4640301, 39.5892634],
          [109.4822417, 39.6551707],
          [109.4989578, 39.7212496],
          [109.5141732, 39.7874794],
          [109.5278833, 39.8538391],
          [109.5400839, 39.9203081],
          [109.5507713, 39.9868657],
          [109.5599423, 40.0534911],
          [109.5675941, 40.1201636],
          [109.5737242, 40.1868627],
          [109.578331, 40.2535677],
          [109.5814129, 40.3202582],
          [109.582969, 40.3869137],
          [109.5829989, 40.4535139],
          [109.5815026, 40.5200383],
          [109.5784804, 40.5864669],
          [109.5739334, 40.6527795],
          [109.5678628, 40.718956],
          [109.5602707, 40.7849766],
          [109.5511591, 40.8508213],
          [109.5405311, 40.9164706],
          [109.5283896, 40.9819047],
          [109.5147386, 41.0471044],
          [109.4995821, 41.1120501],
          [109.4829247, 41.1767227],
          [109.4647715, 41.2411032],
          [109.4451281, 41.3051727],
          [109.4240004, 41.3689123],
          [109.4013948, 41.4323035],
          [109.3773182, 41.4953278],
          [109.351778, 41.5579669],
          [109.324782, 41.6202028],
          [109.2963383, 41.6820174],
          [109.2664555, 41.7433931],
          [109.2351429, 41.8043122],
          [109.20241, 41.8647573],
          [109.1682666, 41.9247113],
          [109.1327232, 41.984157],
          [109.0957907, 42.0430778],
          [109.0574801, 42.1014569],
          [109.0178033, 42.159278],
          [108.9767723, 42.2165249],
          [108.9343996, 42.2731815],
          [108.8906981, 42.3292321],
          [108.8456811, 42.3846611],
          [108.7993622, 42.4394532],
          [108.7517556, 42.4935932],
          [108.7028759, 42.5470663],
          [108.6527378, 42.5998577],
          [108.6013566, 42.6519531],
          [108.5487479, 42.7033381],
          [108.4949279, 42.753999],
          [108.4399128, 42.8039218],
          [108.3837194, 42.8530932],
          [108.3263649, 42.9014998],
          [108.2678666, 42.9491286],
          [108.2082424, 42.9959669],
          [108.1475104, 43.0420022],
          [108.0856892, 43.0872221],
          [108.0227975, 43.1316147],
          [107.9588544, 43.1751681],
          [107.8938796, 43.2178709],
          [107.8278926, 43.2597118],
          [107.7609137, 43.3006798],
          [107.6929632, 43.340764],
          [107.6240617, 43.3799541],
          [107.5542303, 43.4182398],
          [107.4834903, 43.4556111],
          [107.4118631, 43.4920582],
          [107.3393705, 43.5275718],
          [107.2660347, 43.5621426],
          [107.1918779, 43.5957617],
          [107.1169228, 43.6284205],
          [107.041192, 43.6601105],
          [106.9647088, 43.6908236],
          [106.8874964, 43.720552],
          [106.8095782, 43.7492879],
          [106.730978, 43.7770242],
          [106.6517198, 43.8037538],
          [106.5718276, 43.8294697],
          [106.4913258, 43.8541656],
          [106.4102389, 43.8778351],
          [106.3285915, 43.9004723],
          [106.2464085, 43.9220714],
          [106.163715, 43.942627],
          [106.080536, 43.9621339],
          [105.996897, 43.9805872],
          [105.9128234, 43.9979822],
          [105.8283408, 44.0143147],
          [105.7434748, 44.0295804],
          [105.6582514, 44.0437756],
          [105.5726965, 44.0568967],
          [105.486836, 44.0689405],
          [105.4006963, 44.0799039],
          [105.3143034, 44.0897841],
          [105.2276836, 44.0985788],
          [105.1408634, 44.1062858],
          [105.0538692, 44.112903],
          [104.9667275, 44.1184289],
          [104.8794647, 44.122862],
          [104.7921074, 44.1262014],
          [104.7046823, 44.1284461],
          [104.6172159, 44.1295956],
          [104.5297349, 44.1296496],
          [104.4422659, 44.1286081],
          [104.3548356, 44.1264713],
          [104.2674705, 44.1232399],
          [104.1801972, 44.1189145],
          [104.0930423, 44.1134963],
          [104.0060324, 44.1069866],
          [103.9191938, 44.099387],
          [103.8325532, 44.0906994],
          [103.7461367, 44.0809261],
          [103.6599708, 44.0700694],
          [103.5740817, 44.058132],
          [103.4884955, 44.0451169],
          [103.4032382, 44.0310275],
          [103.3183359, 44.0158671],
          [103.2338143, 43.9996396],
          [103.1496992, 43.9823492],
          [103.0660163, 43.964],
          [102.9827908, 43.9445967],
          [102.9000483, 43.9241443],
          [102.8178139, 43.9026478],
          [102.7361126, 43.8801127],
          [102.6549693, 43.8565447],
          [102.5744087, 43.8319498],
          [102.4944554, 43.8063341],
          [102.4151336, 43.7797042],
          [102.3364674, 43.7520669],
          [102.258481, 43.7234291],
          [102.1811979, 43.6937983],
          [102.1046418, 43.6631819],
          [102.0288358, 43.6315878],
          [101.9538032, 43.5990242],
          [101.8795667, 43.5654994],
          [101.806149, 43.531022],
          [101.7335723, 43.4956009],
          [101.6618588, 43.4592454],
          [101.5910303, 43.4219648],
          [101.5211084, 43.3837689],
          [101.4521144, 43.3446675],
          [101.3840692, 43.3046709],
          [101.3169935, 43.2637896],
          [101.2509079, 43.2220343],
          [101.1858324, 43.179416],
          [101.1217867, 43.1359459],
          [101.0587905, 43.0916355],
          [100.9968629, 43.0464966],
          [100.9360227, 43.0005412],
          [100.8762885, 42.9537815],
          [100.8176784, 42.90623],
          [100.7602103, 42.8578995],
          [100.7039017, 42.808803],
          [100.6487697, 42.7589536],
          [100.5948311, 42.7083649],
          [100.5421024, 42.6570506],
          [100.4905995, 42.6050246],
          [100.4403381, 42.5523012],
          [100.3913336, 42.4988947],
          [100.3436009, 42.4448198],
          [100.2971545, 42.3900913],
          [100.2520085, 42.3347244],
          [100.2081766, 42.2787344],
          [100.1656724, 42.2221368],
          [100.1245085, 42.1649475],
          [100.0846977, 42.1071823],
          [100.0462519, 42.0488574],
          [100.009183, 41.9899893],
          [99.9735022, 41.9305946],
          [99.9392203, 41.87069],
          [99.9063479, 41.8102925],
          [99.8748948, 41.7494193],
          [99.8448707, 41.6880879],
          [99.8162848, 41.6263158],
          [99.7891456, 41.5641206],
          [99.7634615, 41.5015204],
          [99.7392404, 41.4385333],
          [99.7164894, 41.3751775],
          [99.6952157, 41.3114714],
          [99.6754257, 41.2474336],
          [99.6571254, 41.1830829],
          [99.6403203, 41.1184383],
          [99.6250156, 41.0535186],
          [99.6112159, 40.9883431],
          [99.5989255, 40.9229312],
          [99.5881481, 40.8573023],
          [99.5788869, 40.791476],
          [99.5711449, 40.7254719],
          [99.5649242, 40.6593098],
          [99.5602269, 40.5930098],
          [99.5570544, 40.5265918],
          [99.5554076, 40.4600759],
          [99.555287, 40.3934823],
          [99.5566927, 40.3268313],
          [99.5596242, 40.2601434],
          [99.5640807, 40.1934389],
          [99.5700608, 40.1267383],
          [99.5775626, 40.0600622],
          [99.5865839, 39.9934313],
          [99.597122, 39.9268661],
          [99.6091736, 39.8603874],
          [99.6227351, 39.794016],
          [99.6378022, 39.7277725],
          [99.6543706, 39.6616778],
          [99.672435, 39.5957526],
          [99.6919901, 39.5300178],
          [99.7130298, 39.464494],
          [99.7355478, 39.3992022],
          [99.7595371, 39.334163],
          [99.7849906, 39.2693972],
          [99.8119004, 39.2049254],
          [99.8402583, 39.1407684],
          [99.8700558, 39.0769467],
          [99.9012837, 39.0134809],
          [99.9339326, 38.9503915],
          [99.9679924, 38.8876988],
          [100.0034529, 38.8254233],
          [100.0403033, 38.7635851],
          [100.0785322, 38.7022045],
          [100.1181282, 38.6413014],
          [100.1590791, 38.5808959],
          [100.2013724, 38.5210077],
          [100.2449954, 38.4616565],
          [100.2899346, 38.4028619],
          [100.3361765, 38.3446434],
          [100.383707, 38.2870201],
          [100.4325115, 38.2300112],
          [100.4825753, 38.1736357],
          [100.5338831, 38.1179123],
          [100.5864192, 38.0628596],
          [100.6401678, 38.0084961],
          [100.6951123, 37.9548399],
          [100.7512361, 37.901909],
          [100.8085222, 37.8497213],
          [100.866953, 37.7982944],
          [100.9265108, 37.7476455],
          [100.9871775, 37.6977918],
          [101.0489346, 37.6487502],
          [101.1117632, 37.6005374],
          [101.1756443, 37.5531696],
          [101.2405584, 37.506663],
          [101.3064858, 37.4610336],
          [101.3734064, 37.4162967],
          [101.4412997, 37.3724678],
          [101.5101453, 37.3295618],
          [101.579922, 37.2875935],
          [101.6506087, 37.2465772],
          [101.7221838, 37.2065271],
          [101.7946255, 37.1674569],
          [101.8679119, 37.1293801],
          [101.9420205, 37.0923099],
          [102.0169288, 37.056259],
          [102.0926141, 37.0212398],
          [102.1690533, 36.9872647],
          [102.2462231, 36.9543452],
          [102.3241, 36.9224928],
          [102.4026603, 36.8917187],
          [102.4818801, 36.8620334],
          [102.5617353, 36.8334473],
          [102.6422016, 36.8059705],
          [102.7232544, 36.7796123],
          [102.8048692, 36.7543821],
          [102.8870211, 36.7302887],
          [102.969685, 36.7073404],
          [103.0528358, 36.6855453],
          [103.1364481, 36.6649111],
          [103.2204966, 36.6454449],
          [103.3049557, 36.6271535],
          [103.3897996, 36.6100434],
          [103.4750025, 36.5941206],
          [103.5605385, 36.5793906],
          [103.6463815, 36.5658587],
          [103.7325054, 36.5535296],
          [103.818884, 36.5424075],
          [103.905491, 36.5324965],
          [103.9923, 36.5238]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "SRC": "CHN", "DST": "USA" },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [103.9923, 36.5238],
            [105.5998521, 37.6303686],
            [107.2311424, 38.6985352],
            [108.8856791, 39.7288325],
            [110.5629633, 40.7218359],
            [112.2624894, 41.6781559],
            [113.9837449, 42.5984315],
            [115.7262108, 43.483324],
            [117.4893618, 44.3335113],
            [119.2726662, 45.1496827],
            [121.0755864, 45.9325342],
            [122.8975787, 46.6827639],
            [124.7380939, 47.4010686],
            [126.596577, 48.0881399],
            [128.4724676, 48.7446616],
            [130.3652002, 49.3713065],
            [132.274204, 49.9687341],
            [134.1989035, 50.5375886],
            [136.1387184, 51.078497],
            [138.0930638, 51.5920674],
            [140.0613505, 52.0788875],
            [142.0429849, 52.5395239],
            [144.0373697, 52.9745204],
            [146.0439035, 53.3843976],
            [148.0619812, 53.7696521],
            [150.0909945, 54.1307558],
            [152.1303315, 54.4681553],
            [154.1793775, 54.7822719],
            [156.2375145, 55.0735008],
            [158.3041221, 55.3422113],
            [160.378577, 55.5887463],
            [162.460254, 55.8134222],
            [164.5485253, 56.016529],
            [166.6427612, 56.1983299],
            [168.7423305, 56.3590615],
            [170.8465999, 56.4989338],
            [172.9549351, 56.6181301],
            [175.0667004, 56.716807],
            [177.181259, 56.7950943],
            [179.2979735, 56.8530953],
            [180.0, 56.8656201]
          ],
          [
            [-180.0, 56.8656201],
            [-178.5837944, 56.8908866],
            [-176.4646834, 56.9085181],
            [-174.3453325, 56.9060131],
            [-172.2263805, 56.8833684],
            [-170.1084664, 56.840554],
            [-167.9922287, 56.7775132],
            [-165.8783056, 56.694163],
            [-163.7673344, 56.5903934],
            [-161.6599515, 56.4660679],
            [-159.5567924, 56.3210232],
            [-157.4584911, 56.1550695],
            [-155.3656805, 55.9679902],
            [-153.2789913, 55.7595419],
            [-151.1990528, 55.5294549],
            [-149.1264922, 55.2774324],
            [-147.0619343, 55.0031517],
            [-145.0060015, 54.706263],
            [-142.9593138, 54.3863908],
            [-140.9224884, 54.0431333],
            [-138.8961391, 53.6760628],
            [-136.8808772, 53.2847264],
            [-134.8773101, 52.868646],
            [-132.886042, 52.427319],
            [-130.9076733, 51.960219],
            [-128.9428004, 51.4667963],
            [-126.9920157, 50.946479],
            [-125.0559076, 50.3986743],
            [-123.1350596, 49.8227691],
            [-121.230051, 49.2181321],
            [-119.3414561, 48.5841151],
            [-117.4698444, 47.9200549],
            [-115.6157802, 47.2252759],
            [-113.7798225, 46.4990921],
            [-111.9625248, 45.7408103],
            [-110.1644351, 44.9497329],
            [-108.3860955, 44.125162],
            [-106.6280423, 43.2664029],
            [-104.8908054, 42.3727687],
            [-103.1749087, 41.4435851],
            [-101.4808695, 40.4781958],
            [-99.8091986, 39.4759682],
            [-98.1604, 38.4363]
          ]
        ]
      }
    }
  ]
}
