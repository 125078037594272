import { createRef, Component, Fragment } from "react";
import { Link } from "react-router-dom";

import Sunburst from "../components/Sunburst";
import { InfoButton } from "../components/InfoButton";
import Dropdown from "../components/Dropdown";
import useMediaQuery from "../useMediaQuery";
import policies from "../json/policies.json";

import { interpolateRgb, quantize } from "d3-interpolate";
import { scaleOrdinal } from "@visx/scale";

import App from "./App";
import ResponsiveSidebar from "../components/ResponsiveSidebar";

const colors = {
  Decarbonization: "#5b9bd5",
  "Climate Mitigation": "#5b9bd5",
  "Climate Resilience": "#70ad47",
  "Market Opportunities": "#44546a",
  "Circular Economy": "#4471c4",
  Gender: "#70ad47",
  "Citizen Engagement": "#44546a",
  Innovation: "#5b9bd5",
  "Strong Governance and Adequate Regulatory Framework": "#4471c4",
};

const jsonData = policies;

// Helper function to sort children by country name
function sortChildrenByCountryName(parent) {
  if (parent && parent.children) {
    parent.children.sort((a, b) => a.name.localeCompare(b.name));
  }
}

// Traverse the JSON and sort children at depth level 3 i.e pillars
function traverseAndSort(json) {
  if (json.children) {
    for (const child of json.children) {
      if (child.children && child.children.length > 0 && child._depth === 3) {
        sortChildrenByCountryName(child); // Sort children at depth level 3
      }
      traverseAndSort(child); // Recursively traverse the children
    }
  }
}

// Traverse the JSON and sort children at depth level 3 i.e pillars
function traverseAndSortCCT(json) {
  if (json.children) {
    for (const child of json.children) {
      if (child.children && child.children.length > 0 && child._depth === 2) {
        sortChildrenByCountryName(child); // Sort children at depth level 2
      }
      traverseAndSort(child); // Recursively traverse the children
    }
  }
}

// Start traversing and sorting from the root
traverseAndSort(jsonData.pillars);
traverseAndSortCCT(jsonData.themes);

const colorMaps = Object.fromEntries(
  Object.entries(colors).map(([k, v]) => [
    k,
    scaleOrdinal({
      domain: [...Array(5).keys()],
      range: quantize(interpolateRgb.gamma(2.2)(v, "white"), 6),
    }),
  ])
);

const color = (node) => {
  const category =
    (node.data.path && node.data.path[0]) ||
    (node.parent && node.parent.data.path && node.parent.data.path[0]);
  const depth = node.data._depth == undefined ? 4 : node.data._depth - 1;
  return colorMaps[category](depth) || "#aaa";
};

const Sources = ({ node }) => {
  const { source1, source2, source3, source4, source5 } = node;
  const sources = [source1, source2, source3, source4, source5].map(e => e?.trim()).filter(
    (e) => e
  );

  if (!sources.length) {
    return "";
  }

  return (
    <Fragment>
      <h3>Sources</h3>
      <ul className="sources">
        {sources.map((s, idx) => (
          <li key={`src-${idx}`}>
            <a href={s}>{s}</a>
          </li>
        ))}
      </ul>
    </Fragment>
  );
};

const PolicyDetail = ({ node, alt = "" }) => {
  const { policy, abbr, name, children, _i } = node;

  if (!policy) {
    return alt;
  }

  return <div dangerouslySetInnerHTML={{ __html: policy }} />;
};

class Policies extends Component {
  constructor() {
    super();
    this.sidebarRef = createRef();
    this.dropdownRef = createRef();
  }
  render() {
    const { isSmall } = this.props;
    const { currentNode = {} } = this.state || {};
    const policyData = jsonData[this.props.section];

    const hasNode = currentNode.name && currentNode.name;
    const parentTitle = (hasNode && currentNode.parent.name) || "";

    const sidebarSubtitle = parentTitle ? currentNode.name : "";
    const sidebarTitle = sidebarSubtitle
      ? parentTitle
      : hasNode
      ? currentNode.name
      : {
          pillars: "Policies and Regulations",
          themes: "Cross-Cutting Themes",
        }[this.props.section];

    const mainDescription = (
      <Fragment>
        <p>
          Countries are committing to policies or targets that contribute to
          decarbonizing the global mineral supply chains and facilitate their
          steady and secure supply, while integrating an ESG approach to
          managing the mineral-technology supply chains. This page showcases the
          range of policy instruments that are available to governments that can
          in turn help industries and consumers lower emissions and energy usage
          in different mineral-technology supply chains for a truly green energy
          transition.
        </p>
        <p>
          The policy sunburst contains information on key policy areas and
          examples of how these are being introduced in countries across
          different supply chains. The tool is composed of four categories:
        </p>
        <p>
          <img
            src={
              new URL(
                "/app/assets/graphics/content/PolicySample.png",
                import.meta.url
              )
            }
            alt="Policy Action Areas, Policy Categories, Subcatetories, Country Policies"
          />
          The Policy Action Areas were selected based on the four pillars of the
          <Link to="/about"> Climate-Smart Mining Framework</Link> of the World
          Bank's Climate-Smart Mining Initiative.
        </p>
        <p>
          First, click on a Policy Action Area to refine your search into a
          specific theme and sub-theme. With each click, an explanation of the
          category and what it includes will appear on the right. You will then
          reach the country policies pertaining to the theme that you have
          refined at the end of the sunburst where you can see details of the
          policies that countries have introduced and links to further
          information.
        </p>
      </Fragment>
    );

    const themesDescription = (
      <>
        <p>
          Cross-cutting themes refer to an overarching concept or issue that
          affects multiple aspects of the critical mineral supply chains. It is
          a fundamental element that must be considered across various
          dimensions of the minerals’ supply chains to achieve sustainable
          outcomes.
        </p>
        <p>
          Cross-cutting themes recognize the interconnectedness and
          interdependence of different components within the mining sector, as
          well as their impact on broader social, economic, and environmental
          contexts. These themes address the need for an integrated and holistic
          approach to mining practices, ensuring that sustainability
          considerations are integrated throughout the entire mining lifecycle.
        </p>
        <p>
          Under the
          <Link to="/about"> Climate-Smart Mining Framework</Link>, the
          cross-cutting themes are the following:
        </p>
        <ol>
          <li>
            <p>
              <h3 style={{ marginBottom: 0 }}>Citizen Engagement:</h3>
              This theme emphasizes the importance of involving and
              collaborating with various stakeholders, such as local
              communities, indigenous groups, and non-governmental
              organizations. Effective engagement ensures that diverse
              perspectives are considered, leading to better decision-making and
              the adoption of sustainable practices.
            </p>
          </li>
          <li>
            <p>
              <h3 style={{ marginBottom: 0 }}>Gender:</h3>
              This theme recognizes the importance of addressing gender
              inequalities and promoting gender equity throughout the mining
              sector. It acknowledges that mining activities can have
              differential impacts on women and men and aims to ensure that both
              genders have equal access to benefits, opportunities, and
              decision-making processes. This involves promoting equal
              employment opportunities, fair wages, and safe working conditions
              for women in mining. Gender-sensitive policies and practices also
              focus on enhancing the participation and representation of women
              in leadership roles within the industry, as well as supporting
              women-owned businesses and entrepreneurship in mining-related
              sectors. By integrating gender considerations into sustainable
              mining frameworks, we can create more inclusive and equitable
              mining practices that contribute to the social, economic, and
              environmental well-being of communities and stakeholders involved.
            </p>
          </li>
          <li>
            <p>
              <h3 style={{ marginBottom: 0 }}>Strong Governance and Adequate Regulatory Framework:</h3>
              Good governance and ethical behavior are essential for sustainable
              mining. This theme encompasses transparent decision-making,
              regulatory compliance, anti-corruption measures, and adherence to
              international standards. It promotes accountability, integrity,
              and responsible business practices within the mining industry. An
              adequate regulatory framework provides a legal and governance
              structure that promotes responsible mining practices, protects the
              environment, respects human rights, engages local communities, and
              contributes to the long-term sustainability of the mining sector.
            </p>
          </li>
          <li>
            <p>
              <h3 style={{ marginBottom: 0 }}>Innovation:</h3>
              Technology and innovation play a vital role in achieving
              sustainable mining outcomes. This theme highlights the integration
              of advanced technologies, such as automation, robotics, data
              analytics, and renewable energy solutions, to improve efficiency,
              reduce environmental impacts, and enhance safety in mining
              operations.
            </p>
          </li>
        </ol>
        <p>
          With a click on each Cross-cutting Theme, an explanation of the
          category and what it includes will appear on the right. You will then
          reach the country policies pertaining to the theme that you have
          chosen where you can see details of the policies that countries have
          introduced and links to further information.
        </p>
      </>
    );

    return (
      <App pageTitle="Policies and Regulations">
        <section className="inpage">
          <div className="inpage__policy">
            <div className="chart">
              <h1
                style={{
                  width: "max-content",
                  paddingRight: "2rem",
                  position: "relative",
                }}
              >
                {isSmall ? <Dropdown
                  className="dropdown top-supply-chains-route-dropdown"
                  triggerClassName="top-supply-chains-route-dropdown-trigger dropdown__active"
                  triggerActiveClassName="button--active"
                  triggerText={{
                    pillars: "Pillars",
                    themes: "Cross-Cutting Themes"
                  }[this.props.section]}
                  triggerTitle='Category'
                  direction='down'
                  alignment='left'
                  ref={this.dropdownRef}
                >
                  <ul className='drop__menu drop__menu--select'>
                    <li><Link to='/policies/pillars' className='drop__menu-item' activeClassName='drop__menu-item--active' onClick={() => this.dropdownRef.current.close()}>Pillars</Link></li>
                    <li><Link to='/policies/themes' className='drop__menu-item' activeClassName='drop__menu-item--active' onClick={() => this.dropdownRef.current.close()}>Cross-Cutting Themes</Link></li>
                  </ul>

                </Dropdown> : "Policies and Regulations"}
                <InfoButton description="Policies are non-exhaustive and were last updated in June 2023." />
              </h1>
              <Sunburst
                key={this.props.section}
                onNodeChange={(node) => {
                  this.setState({ currentNode: node });
                  if (node && !node.children) {
                    this.sidebarRef.current();
                  }
                }}
                data={policyData}
                color={color}
                margin={{ left: 10, right: 20, top: 0, bottom: 0 }}
              />
              {isSmall || <>
                <Link
                  className="section-toggle"
                  to="/policies/pillars"
                  style={{filter: this.props.section ==  "pillars" ? '' : 'grayscale(100%)'}}
                >
                  Pillars
                </Link>
                <Link
                  className="section-toggle"
                  style={{ marginTop: '3em', filter: this.props.section == "themes" ? '' : 'grayscale(100%)'}}
                  to="/policies/themes"
                >
                  Cross-Cutting Themes
                </Link>
              </>}
            </div>
            <ResponsiveSidebar openRef={this.sidebarRef}>
              <div className="data responsive-sidebar">
                <h1>{sidebarTitle}</h1>
                <h2>{sidebarSubtitle}</h2>
                <div className="sidebar-content">
                  <div>
                    <PolicyDetail
                      node={currentNode}
                      alt={
                        {
                          pillars: mainDescription,
                          themes: themesDescription,
                        }[this.props.section]
                      }
                    />
                  </div>
                  <Sources node={currentNode} />
                </div>
              </div>
            </ResponsiveSidebar>
          </div>
        </section>
      </App>
    );
  }
}
const withIsSmall = Component => function Wrapper(props) {
  const isSmall = useMediaQuery('(max-width: 767px)');
  return <Component {...props} isSmall={isSmall} />
}
export default withIsSmall(Policies);
