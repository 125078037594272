const _temp0 = require("./ARG.html");
const _temp1 = require("./AUS.html");
const _temp2 = require("./AUT.html");
const _temp3 = require("./BOL.html");
const _temp4 = require("./BRA.html");
const _temp5 = require("./CAN.html");
const _temp6 = require("./CHL.html");
const _temp7 = require("./CHN.html");
const _temp8 = require("./CZE.html");
const _temp9 = require("./DEU.html");
const _temp10 = require("./ESP.html");
const _temp11 = require("./EU.html");
const _temp12 = require("./FIN.html");
const _temp13 = require("./GHA.html");
const _temp14 = require("./IND.html");
const _temp15 = require("./JPN.html");
const _temp16 = require("./KAZ.html");
const _temp17 = require("./KOR.html");
const _temp18 = require("./LKA.html");
const _temp19 = require("./MDG.html");
const _temp20 = require("./MEX.html");
const _temp21 = require("./MLI.html");
const _temp22 = require("./MOZ.html");
const _temp23 = require("./NAM.html");
const _temp24 = require("./NOR.html");
const _temp25 = require("./PAK.html");
const _temp26 = require("./PER.html");
const _temp27 = require("./PRK.html");
const _temp28 = require("./PRT.html");
const _temp29 = require("./ROU.html");
const _temp30 = require("./RUS.html");
const _temp31 = require("./SRB.html");
const _temp32 = require("./TUR.html");
const _temp33 = require("./TZA.html");
const _temp34 = require("./UKR.html");
const _temp35 = require("./USA.html");
const _temp36 = require("./UZB.html");
const _temp37 = require("./VNM.html");
const _temp38 = require("./ZWE.html");
module.exports = {
  "ARG": _temp0,
  "AUS": _temp1,
  "AUT": _temp2,
  "BOL": _temp3,
  "BRA": _temp4,
  "CAN": _temp5,
  "CHL": _temp6,
  "CHN": _temp7,
  "CZE": _temp8,
  "DEU": _temp9,
  "ESP": _temp10,
  "EU": _temp11,
  "FIN": _temp12,
  "GHA": _temp13,
  "IND": _temp14,
  "JPN": _temp15,
  "KAZ": _temp16,
  "KOR": _temp17,
  "LKA": _temp18,
  "MDG": _temp19,
  "MEX": _temp20,
  "MLI": _temp21,
  "MOZ": _temp22,
  "NAM": _temp23,
  "NOR": _temp24,
  "PAK": _temp25,
  "PER": _temp26,
  "PRK": _temp27,
  "PRT": _temp28,
  "ROU": _temp29,
  "RUS": _temp30,
  "SRB": _temp31,
  "TUR": _temp32,
  "TZA": _temp33,
  "UKR": _temp34,
  "USA": _temp35,
  "UZB": _temp36,
  "VNM": _temp37,
  "ZWE": _temp38
}