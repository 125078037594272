import { useState, useEffect, useCallback } from "react";

const useMediaQuery = (query) => {
  const [matches, setMatches] = useState(false);
  const cb = useCallback(
    () => setMatches(window.matchMedia(query).matches),
    [query, setMatches]
  );

  useEffect(() => {
    const match = window.matchMedia(query);
    cb();
    match.addEventListener("change", cb);
    return () => match.removeEventListener("change", cb);
  });
  return matches;
};

export default useMediaQuery;
