import { PropTypes as T } from "prop-types";
import { environment } from "../../../config";

import TextResult from "../../explore/TextResult";

const MetricsBar = (props) => {
  const {
    selectedChain = {},
    chainContext = {},
    contextTitle = "",
    lowestChain = {},
  } = props;
  const tCO2e = (
    <span>
      tCO<sub>2</sub>e
    </span>
  );
  console.log({ selectedChain });

  const lowest = (field) =>
    (lowestChain[field] && lowestChain[field].metrics[field]) || undefined;
  const lowestTitle = (field) =>
    (lowestChain[field] && lowestChain[field].tab_name) || "";

  return (
    <div className="chain__headline-metrics inpage__textresults">
      <TextResult
        value="Graphite for 1 EV"
        infoNote="KPI metrics are representative of the natural graphite components for one single EV via the chosen supply chain route. In other words, this represents the impact for the extraction and processing of the graphite needed, along with the process of integrating the graphite into a battery, and assembling the electric vehicle."
      />
      <TextResult
        title="Extraction/Processing Emissions"
        value={
          <>
            <p>
              <span className="textresults__value_units">Extraction:</span>{" "}
              {selectedChain.extraction_emissions}{" "}
              <span className="textresults__value_units">{tCO2e}</span>
            </p>
            <p>
              <span className="textresults__value_units">Processing:</span>{" "}
              {selectedChain.processing_emissions}{" "}
              <span className="textresults__value_units">{tCO2e}</span>
            </p>
          </>
        }
        contextValue={chainContext.battery_type}
        contextTitle={contextTitle}
        infoNote="These numbers show the GHG emissions of extracting and processing the natural graphite needed for one EV for extracting and processing in China."
      />
      <TextResult
        title="GHG Emissions"
        value={selectedChain.total_emissions}
        units={tCO2e}
        lowest={lowest("total_emissions")}
        lowTitle={lowestTitle("total_emissions")}
        contextValue={chainContext.total_emissions}
        contextTitle={contextTitle}
        infoNote="GHG (CO2, CH4, N20) associated with extracting & processing natural graphite (using data from China), along with combining it with other materials into a li-ion battery and assembling the EV. On average extraction and processing are approximately 8% and 29% of emissions along the supply chain, respectively. Shipping accounts for less than 1% of emissions."
      />
      <TextResult
        title="Energy Usage"
        value={selectedChain.total_energy_per_vehicle}
        units="GJ"
        lowest={lowest("total_energy_per_vehicle")}
        lowTitle={lowestTitle("total_energy_per_vehicle")}
        contextValue={chainContext.total_energy}
        contextTitle={contextTitle}
        infoNote="This is the energy used to extract and process natural graphite, along with combining it with other materials into a li-ion battery and assembling the EV. It includes electricity and other fuels. "
      />
      <TextResult
        title="Shipping Emissions"
        value={selectedChain.shipping_supply_chain}
        units={tCO2e}
        lowest={lowest("shipping_supply_chain")}
        lowTitle={lowestTitle("shipping_supply_chain")}
        contextValue={chainContext.shipping_emissions}
        contextTitle={contextTitle}
        infoNote="GHG emissions associated with shipping natural graphite and manufactured products (battery & EV) between supply chain locations. This includes shipping emissions between countries, and not road transportation within the same country."
      />
      <TextResult
        title="Equivalent ICE Emission"
        units="Km"
        infoNote="1 Ton Total GHG Emissions CO2 is equivalent to driving an average gasoline car 7047km"
        value={selectedChain.equivalent_ice_emissions}
        contextValue={chainContext.equivalent_ice_emissions || undefined}
        contextTitle={contextTitle}
      />
    </div>
  );
};

if (environment !== "production") {
  MetricsBar.propTypes = {
    selectedChain: T.object,
    chainContext: T.object,
  };
}

export default MetricsBar;
