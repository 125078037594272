import { Component } from "react";
import { PropTypes as T } from "prop-types";
import { NavLink } from "react-router-dom";

import { environment } from "../config";

export default class NavGlobalMenu extends Component {
  renderHeaderMenu() {
    return (
      <div className="global-menu__container">
        <NavLink
          end
          to="/"
          title="Visit the home page"
          className="global-menu__link"
        >
          {this.props.isExpanded ? null : (
            <span>
              <img
                className="large-down narrow-logo"
                src={
                  new URL(
                    "/app/assets/graphics/meta/cme-favicon-white-32x32.png",
                    import.meta.url
                  )
                }
              />
              <img
                className="xlarge-up full-logo"
                width="200"
                height="34"
                src={
                  new URL(
                    "/app/assets/graphics/content/logos/logo-cme-white.png",
                    import.meta.url
                  )
                }
                srcSet={`${new URL(
                  "/app/assets/graphics/content/logos/logo-cme-white@2.png",
                  import.meta.url
                )} 2x`}
              />
            </span>
          )}
        </NavLink>
        <ul className="global-menu">
          {this.props.isExpanded && (
            <NavLink
              end
              to="/"
              title="Visit the home page"
              className="global-menu__link"
            >
              <span>
                <img
                  width="200"
                  height="34"
                  src={
                    new URL(
                      "/app/assets/graphics/content/logos/logo-cme-white.png",
                      import.meta.url
                    )
                  }
                  srcSet={`${new URL(
                    "/app/assets/graphics/content/logos/logo-cme-white@2.png",
                    import.meta.url
                  )} 2x`}
                />
              </span>
            </NavLink>
          )}
          <li>
            <NavLink
              to="/data-snapshot"
              title="Data Snapshot"
              className={({ isActive }) =>
                `global-menu__link ${
                  isActive ? "global-menu__link--active" : ""
                }`
              }
            >
              <span>Data Snapshot</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/top-supply-chains"
              title="Top Supply Chains"
              className={({ isActive }) =>
                `global-menu__link ${
                  isActive ? "global-menu__link--active" : ""
                }`
              }
            >
              <span>Top Supply Chains</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/build-supply-chain"
              title="Build Supply Chains"
              className={({ isActive }) =>
                `global-menu__link ${
                  isActive ? "global-menu__link--active" : ""
                }`
              }
            >
              <span>Build your Own Supply Chain</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/policies"
              title="Policies and Regulations"
              className={({ isActive }) =>
                `global-menu__link ${
                  isActive ? "global-menu__link--active" : ""
                }`
              }
            >
              <span>Policies and Regulations</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/countries"
              title="Country-specific detailed data"
              className={({ isActive }) =>
                `global-menu__link ${
                  isActive ? "global-menu__link--active" : ""
                }`
              }
            >
              <span>Countries</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/about"
              title="Learn about this platform"
              className={({ isActive }) =>
                `global-menu__link ${
                  isActive ? "global-menu__link--active" : ""
                }`
              }
            >
              <span>About</span>
            </NavLink>
          </li>
          {this.props.isExpanded && (
            <li>
              <a
                href="https://worldbank.org"
                rel="noreferrer"
                title="The World Bank Group"
                className="global-menu__link-icon-image"
                target="_blank"
              >
                <img
                  src={
                    new URL(
                      "/app/assets/graphics/content/logos/logo-wb--white.png",
                      import.meta.url
                    )
                  }
                />
              </a>
            </li>
          )}
        </ul>
        {this.props.isExpanded ? null : (
          <ul className="global-menu extras">
            <li>
              <a
                href="https://worldbank.org"
                rel="noreferrer"
                title="The World Bank Group"
                className="global-menu__link-icon-image"
                target="_blank"
              >
                <img
                  className="large-down"
                  src={
                    new URL(
                      "/app/assets/graphics/content/logos/icon-wbg-white.png",
                      import.meta.url
                    )
                  }
                />
                <img
                  className="xlarge-up"
                  src={
                    new URL(
                      "/app/assets/graphics/content/logos/logo-wb--white.png",
                      import.meta.url
                    )
                  }
                />
              </a>
            </li>
          </ul>
        )}
        {(this.props.isSmall && (
          <h1 className="page__hamburger">
            <a
              href="#"
              onClick={this.props.hamburgerOnClick}
              title="Show Parameters"
            >
              <span>Show Parameters </span>
            </a>
          </h1>
        )) ||
          ""}
      </div>
    );
  }

  renderFooterMenu() {
    return <ul />;
  }

  render() {
    const { forHeader, forFooter } = this.props;
    if (forHeader) return this.renderHeaderMenu();
    if (forFooter) return this.renderFooterMenu();
    return null;
  }
}

if (environment !== "production") {
  NavGlobalMenu.propTypes = {
    forHeader: T.bool,
    forFooter: T.bool,
  };
}
