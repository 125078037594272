import { Component, createRef } from "react";
import { PropTypes as T } from "prop-types";
import { environment } from "../config";

import { FakeRadio } from "./FakeRadio";
import { InfoButton } from "./InfoButton";
import Dropdown from "./Dropdown";
import useMediaQuery from "../useMediaQuery";

class RouteSelector extends Component {
  constructor() {
    super();
    this.dropdownRef = createRef();
  }
  renderTabs() {
    const { activeTab, tabList, onChange } = this.props;

    return tabList.map((tab, index) => {
      const selected = activeTab === index;
      const extraClasses = selected
        ? "nav__link--active route__tab--active"
        : "";
      return (
        <div className="nav__tab route__tab" role="presentation" key={index}>
          <a
            className={`route__link ${extraClasses}`}
            onClick={(event) => {
              event.preventDefault();
              onChange && onChange(index);
            }}
            title={tab.title}
          >
            <FakeRadio selected={selected} />
            <span>{tab.tab_name}</span>
          </a>
        </div>
      );
    });
  }

  renderDropdown() {
    const { activeTab, tabList, onChange } = this.props;
    return (
      <Dropdown
        ref={this.dropdownRef}
        className="dropdown top-supply-chains-route-dropdown"
        triggerClassName="top-supply-chains-route-dropdown-trigger dropdown__active"
        triggerActiveClassName="button--active"
        triggerText={tabList[activeTab].tab_name}
        triggerTitle={tabList[activeTab].title}
        direction="down"
        alignment="left"
      >
        <ul className="drop__menu">
          {tabList.map((tab, index) => (
            <li
              key={`route-${index}`}
              className={`drop__menu-item ${
                index === activeTab ? "active" : ""
              }`}
              onClick={() => {
                this.dropdownRef.current && this.dropdownRef.current.close();
                onChange && onChange(index);
              }}
              title={tab.title}
            >
              <FakeRadio selected={activeTab === index} />
              {tab.tab_name}
            </li>
          ))}
        </ul>
      </Dropdown>
    );
  }

  render() {
    const { isSmall, tabList, activeTab, className, tooltip } = this.props;

    const renderedTab =
      tabList[activeTab].contents && tabList[activeTab].contents();
    return (
      <div className={`econtrols ${(className && className) || ""}`}>
        <div className="route-flex">
          <InfoButton
            description={tooltip}
          />
          {isSmall ? this.renderDropdown() : this.renderTabs()}
        </div>
        {renderedTab}
      </div>
    );
  }
}

if (environment !== "production") {
  RouteSelector.propTypes = {
    tabList: T.array,
    tabContents: T.array,
    onChange: T.func,
    activeTab: T.number,
  };
}

const RouteSelectorWithSize = (props) => {
  const isSmall = useMediaQuery("(max-width: 767px)");
  return <RouteSelector {...props} isSmall={isSmall} />;
};

export default RouteSelectorWithSize;
