import { Component, createRef } from "react";
import { PropTypes as T } from "prop-types";
import { useNavigate } from "react-router-dom";

import { environment } from "../config";

import App from "./App";
import { SupplyChainMap } from "../components/SupplyChainMap";

import Tabs from "../components/Tabs";
import RouteSelector from "../components/RouteSelector";

import LithiumOverviewTab from "../components/chains/lithium/ChainOverviewTab";
import LithiumCountryFocusTab from "../components/chains/lithium/ChainCountryFocusTab";
import LithiumMetricsBar from "../components/chains/lithium/MetricsBar";
import GraphiteOverviewTab from "../components/chains/graphite/ChainOverviewTab";
import GraphiteCountryFocusTab from "../components/chains/graphite/ChainCountryFocusTab";
import GraphiteMetricsBar from "../components/chains/graphite/MetricsBar";

import Dropdown from "../components/Dropdown";
import ResponsiveSidebar from "../components/ResponsiveSidebar";
import useMediaQuery from "../useMediaQuery";

import { bounds as initialBounds } from "../layers";

import { topSupplyChains as lithiumTopSupplyChains } from "../json/lithium/chains";
import { topSupplyChains as graphiteTopSupplyChains } from "../json/graphite/chains";

const supplyChains = {
  lithium: lithiumTopSupplyChains,
  graphite: graphiteTopSupplyChains,
};

import { data as countriesLithium } from "../json/lithium/country_focus.json";
import countriesGraphiteCsv from "bundle-text:/data/graphite_country_focus.csv";
import { parse } from "csv-parse/lib/sync";
const countriesGraphite = Object.fromEntries(
  parse(countriesGraphiteCsv).filter(([country]) => country !== "country")
);

class TopSupplyChains extends Component {
  constructor(props) {
    super(props);

    this.handleSelectedLocation = this.handleSelectedLocation.bind(this);
    this.handleSelectedCountry = this.handleSelectedCountry.bind(this);
    this.handleSelectedChain = this.handleSelectedChain.bind(this);
    this.handleOnHover = this.handleOnHover.bind(this);

    const compatClass =
      (/iPhone/.test(window.navigator.platform) && "ios") ||
      (/Win/.test(window.navigator.platform) && "win") ||
      "";

    this.state = {
      compatClass: compatClass,
      activeTab: 0,
      selectedChain: 0,
      selectedCountry: undefined,
      highlightCountry: undefined,
      highlightStage: undefined,
      tooltipPosition: undefined,
    };

    this.elementDropdownRef = createRef();
  }

  lowestChain() {
    const topSupplyChains = supplyChains[this.props.element];
    return Object.fromEntries(
      ["total_emissions", "total_energy", "shipping_emissions"].map((field) => [
        field,
        topSupplyChains.reduce((a, b) =>
          a.metrics[field] < b.metrics[field] ? a : b
        ),
      ])
    );
  }

  handleSelectedLocation(location, features) {
    // location is a lat/lon, features will be a filtered/unfiltered list of the features under the cursor

    const selectedIcon = features.filter((f) => f.layer.type == "circle").pop();
    if (selectedIcon) {
      let layerComponents = selectedIcon.layer.id.split("-");
      const highlightCountry = layerComponents.pop();
      const highlightStage = layerComponents.pop();

      this.setState({ activeTab: 0, highlightCountry, highlightStage });

      return;
    }

    const selectedCountry = features
      .filter((f) => f.layer.id.startsWith("selection-layer"))
      .pop();
    if (selectedCountry) {
      if (this.handleSelectedCountry(selectedCountry.properties.ISO_A3)) {
        return;
      }
    }
  }

  handleOnHover(features, latLng) {
    const { highlightStage } = this.state;
    const selectedIcon = features.filter((f) => f.layer.type == "circle").pop();
    if (selectedIcon) {
      let layerComponents = selectedIcon.layer.id.split("-");
      layerComponents.pop(); // highlighted country
      const newHighlightStage = layerComponents.pop();
      console.log({ selectedIcon, features });
      highlightStage != newHighlightStage &&
        this.setState({
          highlightStage: newHighlightStage,
        });
      this.setState({
        tooltipPosition: latLng,
      });
      return;
    }
    if (highlightStage) {
      this.setState({ highlightStage: undefined, tooltipPosition: undefined });
    }
  }

  handleSelectedCountry(iso3) {
    // Check for the country in the current chain
    // Trigger the tab change to 1, set the current selected country
    const countries = {
      lithium: countriesLithium,
      graphite: countriesGraphite,
    }[this.props.element];
    if (!(iso3 in countries)) {
      return;
    }
    const { selectedCountry, activeTab } = this.state;
    const { isSmall } = this.props;
    const countryTab = isSmall ? 2 : 1;

    if (selectedCountry == iso3 && activeTab == countryTab) {
      // handled
      return true;
    }

    this.setState({
      selectedCountry: iso3,
      activeTab: countryTab,
    });
    return true;
  }

  handleSelectedChain(index) {
    const { selectedChain } = this.state;
    if (index == selectedChain) {
      return;
    }
    this.setState({ selectedChain: index });
  }

  render() {
    const {
      compatClass,
      selectedCountry,
      activeTab,
      selectedChain,
      highlightStage,
      tooltipPosition,
    } = this.state;
    const { isSmall } = this.props;
    const topSupplyChains = supplyChains[this.props.element];

    const { OverviewTab, CountryFocusTab, MetricsBar } = {
      lithium: {
        OverviewTab: LithiumOverviewTab,
        CountryFocusTab: LithiumCountryFocusTab,
        MetricsBar: LithiumMetricsBar,
      },
      graphite: {
        OverviewTab: GraphiteOverviewTab,
        CountryFocusTab: GraphiteCountryFocusTab,
        MetricsBar: GraphiteMetricsBar,
      },
    }[this.props.element];

    const tabs = [
      ...(isSmall
        ? [
            {
              title: "Indicators",
              tab_name: "Indicators",
              contents: () => (
                <div className="headline_metrics body__footer">
                  <div className={"metricsbar-title"}>
                    {topSupplyChains[selectedChain].tab_name} Metrics
                  </div>
                  <MetricsBar
                    selectedChain={topSupplyChains[selectedChain].metrics}
                    lowestChain={this.lowestChain()}
                  />
                </div>
              ),
            },
          ]
        : []),
      {
        title: "Supply Chain Overview",
        tab_name: "Overview",
        contents: () => (
          <OverviewTab
            chain={topSupplyChains[selectedChain].chain}
            highlightStage={highlightStage}
          />
        ),
      },
      {
        title: "Country Focus",
        tab_name: "Country Focus",
        contents: () => (
          <CountryFocusTab
            iso3={country}
            onChange={this.handleSelectedCountry}
          />
        ),
      },
    ];
    const countryTab = tabs.length - 1;
    const country =
      selectedCountry != undefined
        ? selectedCountry
        : topSupplyChains[0].chain.extraction;

    const changeElement = (element) => () => {
      this.props.navigate(`/top-supply-chains/${element}`);
      this.elementDropdownRef.current.close();
      this.setState({ selectedChain: 0 });
    };

    const graphiteBatteryManufacturers = ["CHN", "USA", "JPN", "KOR"];

    const chains = {
      lithium: [topSupplyChains[selectedChain].chain],
      graphite: graphiteBatteryManufacturers
        .filter(
          (c) => !graphiteBatteryManufacturers.includes(country) || c == country
        )
        .map((battery_assembly) => ({
          ...topSupplyChains[selectedChain].chain,
          battery_assembly,
        })),
    }[this.props.element];

    const routeSelectorTooltip = {
      lithium: `Click here to choose between six of the most common supply
        chains for lithium to EVs. You can see how the routes change in the map,
        and how emissions and energy change in the boxes below. You can also click on
        icons and countries to see more detailed information on the right.`,
      graphite: `Click here to choose between four of the most common supply chains for natural
        graphite to EVs. You can see how the routes change in the map, and how emissions
        and energy change in the boxes below. You can also click on the icons and
        countries to see more detailed information on the right.`,
    }[this.props.element];

    return (
      <App pageTitle="Top Supply Chains" className={compatClass}>
        <section className="inpage inpage--single inpage--right-panel inpage--map">
          <div className="inpage__body">
            <div className="body__header route-tabs-header">
              <div className="route-header">
                <h4>
                  Top
                  <Dropdown
                    className="dropdown top-supply-chains-element-dropdown"
                    triggerClassName="top-supply-chains-element-dropdown-trigger dropdown__active"
                    triggerActiveClassName="button--active"
                    triggerText={this.props.element}
                    triggerTitle="Mineral"
                    direction="down"
                    alignment="left"
                    ref={this.elementDropdownRef}
                  >
                    <ul className="drop__menu">
                      <li
                        className="drop__menu-item"
                        onClick={changeElement("lithium")}
                      >
                        Lithium
                      </li>
                      <li
                        className="drop__menu-item"
                        onClick={changeElement("graphite")}
                      >
                        Graphite
                      </li>
                    </ul>
                  </Dropdown>
                  Supply Chains for{" "}
                  <span className="primary-color">Electric Vehicles</span>
                </h4>
              </div>
              <RouteSelector
                className="route__selector"
                tabList={topSupplyChains}
                activeTab={selectedChain}
                onChange={this.handleSelectedChain}
                tooltip={routeSelectorTooltip}
              />
            </div>
            <SupplyChainMap
              chains={chains}
              initialBounds={initialBounds}
              handleSelectedLocation={this.handleSelectedLocation}
              onHover={this.handleOnHover}
              selectedCountry={activeTab == countryTab ? country : undefined}
              selectedColor="blue"
              highlightHovered
              element={this.props.element}
              tooltip={
                tooltipPosition && {
                  latLng: tooltipPosition,
                  contents:
                    {
                      extraction: "Extraction",
                      battery_assembly: "Battery Assembly",
                      processing: "Processing",
                      vehicle_assembly: "Vehicle Assembly",
                    }[highlightStage] ?? highlightStage,
                }
              }
            />

            {!isSmall && (
              <div className="headline_metrics body__footer">
                <div className={"metricsbar-title"}>
                  {topSupplyChains[selectedChain].tab_name} Metrics
                </div>
                <MetricsBar
                  selectedChain={topSupplyChains[selectedChain].metrics}
                  lowestChain={this.lowestChain()}
                />
              </div>
            )}
          </div>
          <ResponsiveSidebar className="top-supply-tabs">
            <Tabs
              className="inpage__w inpage__height"
              tabBodyClassName="sidebar-tab-body-wrapper"
              tabList={tabs}
              activeTab={activeTab}
              onChange={(e) => {
                this.setState({ activeTab: e });
              }}
            />
          </ResponsiveSidebar>
        </section>
      </App>
    );
  }
}

if (environment !== "production") {
  TopSupplyChains.propTypes = {
    match: T.object,
    history: T.object,
  };
}

export function DetailPanel(props) {
  const { onClose, children } = props;
  if (!children) {
    return "";
  }
  return (
    <div className="inpage__detail-panel">
      <button className="close-button" onClick={onClose}>
        x
      </button>
      {children}
    </div>
  );
}

const withNavigate = (Component) =>
  function Wrapped(props) {
    const navigate = useNavigate();
    return <Component {...props} navigate={navigate} />;
  };

const withIsSmall = (Component) =>
  function Wrapped(props) {
    const isSmall = useMediaQuery("(max-width: 767px)");
    return <Component {...props} isSmall={isSmall} />;
  };

export default withNavigate(withIsSmall(TopSupplyChains));
