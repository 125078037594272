import { NavLink } from "react-router-dom";

export const NavAboutMenu = ({ pages }) => (
  <ul className="side-nav nav">
    <li>
      <NavLink
        to="/about"
        className={({ isActive }) =>
          `side-nav__link ${isActive ? "side-nav__link--active" : ""}`
        }
      >
        <span>Introduction</span>
      </NavLink>
    </li>
    {pages.map((e) => (
      <li key={e.path}>
        <NavLink
          to={`/about/${e.path}`}
          className={({ isActive }) =>
            `side-nav__link ${isActive ? "side-nav__link--active" : ""}`
          }
        >
          <span>{e.title}</span>
        </NavLink>
      </li>
    ))}
  </ul>
);
