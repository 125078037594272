import { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { InView } from "react-intersection-observer";
import useMediaQuery from "../useMediaQuery";

import App from "./App";

const fileName = (imgUrl) => imgUrl.substring(imgUrl.lastIndexOf("/") + 1);

const HeroImg = ({ imgUrl, show = true, zIndex = 1 }) => {
  const isSmall = useMediaQuery("(max-width: 767px)");
  return (
    <div
      key={fileName(imgUrl.pathname)}
      className={`home__heroimg ${isSmall || show ? "show" : "hide"}`}
      style={{
        backgroundImage: `url(${imgUrl})`,
        zIndex,
      }}
    />
  );
};

const AnimatedHeroImg = ({
  imgUrl,
  show = true,
  zIndex = 1,
  id = undefined,
}) => {
  const isSmall = useMediaQuery("(max-width: 767px)");
  const keyName = fileName(imgUrl[0].pathname).replace(/[-_. ]/g, "");
  // Return 0% n% 100%, for 0,1,2
  const pctage = (i) => `${((i / (imgUrl.length - 1)) * 100).toFixed(0)}%`;

  const keyframes = imgUrl.map(
    (url, ix) => `${pctage(ix)} {background-image: url(${url}); }`
  );

  return (
    <Fragment>
      <style
        dangerouslySetInnerHTML={{
          __html: `
    @keyframes ${keyName} {
       ${keyframes.join("\n")}
    }
    .home__animheroimg.${keyName} {
       animation-duration: 3s;
       animation-name: ${keyName};
       animation-iteration-count: infinite;
     }
`,
        }}
      />
      <div
        key={keyName}
        className={`home__heroimg home__animheroimg ${keyName} ${
          isSmall || show ? "show" : "hide"
        }`}
        style={{
          zIndex,
        }}
        id={id}
      />
    </Fragment>
  );
};

const fadeClass_ = (stage) => (_s) => stage != _s ? "fade" : "";

class Home extends Component {
  constructor() {
    super();
    this.state = {
      headerVisible: true,
      stage: (window.location.hash.match(/^#section_(\d+)/) ?? [])[1] || -1,
    };
    this.updateStage = this.updateStage.bind(this);
  }

  updateStage(idx, inView) {
    const { stage } = this.state;
    if (inView && stage < idx) {
      this.setState({ stage: stage + 1 });
    }
    if (!inView && stage == idx) {
      this.setState({ stage: stage - 1 });
    }
  }

  render() {
    const { element } = this.props;
    const compatClass =
      (/iPhone/.test(window.navigator.platform) && "ios") ||
      (/Win/.test(window.navigator.platform) && "win") ||
      "";

    const { stage } = this.state;
    const homeImageClass = stage >= 0 ? " fade" : "";
    const InfoComponent = {
      lithium: LithiumInfo,
      graphite: GraphiteInfo,
    }[element];
    return (
      <App
        className={compatClass}
        onHeaderInView={(inView) => this.setState({ headerVisible: inView })}
      >
        <article className="inpage inpage--home">
          <ul className="home-intro__attribution right">
            <li>
              <img
                alt="CME Logo"
                src={
                  new URL(
                    "/app/assets/graphics/content/logos/logo-cme-white@2.png",
                    import.meta.url
                  )
                }
              />
            </li>
            <li>
              <a
                href="https://www.worldbank.org/en/topic/extractiveindustries/brief/climate-smart-mining-minerals-for-climate-action"
                title="Visit CSM"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt="CSM Logo"
                  src={
                    new URL(
                      "/app/assets/graphics/content/logos/logo-csm-white@2.png",
                      import.meta.url
                    )
                  }
                />
                <span>CSM</span>
              </a>
            </li>
          </ul>
          <div className="inpage__body">
            <section className="home-intro">
              <div className="home-intro__lead">
                <div className="scroll-to-section">
                  <div>
                    <h2 className="home-intro__title">
                      <span className="highlight">Climate</span> Mineral{" "}
                      <span className="highlight"> Explorer</span>
                    </h2>
                    <h3 className={homeImageClass}>
                      A Climate-Smart Mining Dashboard
                    </h3>
                    <p className={homeImageClass + " homepage-description"}>
                      The Climate Mineral Explorer is an interactive tool that
                      provides an overview of greenhouse gas (GHG) emissions as
                      well as other Environmental Social, and Governance (ESG)
                      aspects of critical minerals supply chains, from mine to
                      clean energy technologies.
                    </p>
                    <HeroImg
                      imgUrl={
                        new URL(
                          "/app/assets/graphics/content/home-map-0.png",
                          import.meta.url
                        )
                      }
                      show={stage == -1}
                    />
                  </div>

                  <div>
                    <a
                      href="#section_0"
                      className={`scroll-to-sect-btn-home ${
                        stage == -1 ? "visible" : ""
                      }`}
                    >
                      <i className="collecticon-arrow-down" />
                    </a>
                  </div>
                </div>
                <ol>
                  <ChooseElement
                    stage={stage}
                    updateStage={this.updateStage}
                    element={element}
                  />
                  <InfoComponent stage={stage} updateStage={this.updateStage} />
                </ol>
              </div>

              <ul className="home-intro__attribution">
                <li>
                  <a
                    href="https://www.worldbank.org"
                    title="Visit WorldBank"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      alt="WBG Logo"
                      src={
                        new URL(
                          "/app/assets/graphics/content/logos/logo-wb--white.png",
                          import.meta.url
                        )
                      }
                    />
                    <span>WBG</span>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.worldbank.org/en/topic/extractiveindustries/brief/climate-smart-mining-minerals-for-climate-action"
                    title="Visit CSM"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      alt="CSM Logo"
                      src={
                        new URL(
                          "/app/assets/graphics/content/logos/logo-csm-white@2.png",
                          import.meta.url
                        )
                      }
                    />
                    <span>CSM</span>
                  </a>
                </li>
              </ul>
            </section>
          </div>
        </article>
      </App>
    );
  }
}

const ChooseElement = ({ stage, updateStage, element }) => {
  const isSmall = useMediaQuery("(max-width: 767px)");
  const nextSection = () => {
    if (stage == 0) {
      updateStage(1, false);
      document.getElementById("section_1").scrollIntoView();
    }
  };
  const links = (inline) => (
    <div
      style={{
        zIndex: 10000,
        display: "flex",
        transition: "all 0.5s ease-in-out",
        width: "fit-content",
        height: "fit-content",
        top: 0,
        right: 0,
        position: inline ? "static" : "fixed",
        opacity: inline || stage >= 1 ? 1 : 0,
      }}
    >
      <Link
        to="/home/lithium#section_1"
        onClick={nextSection}
        style={{ height: "fit-content" }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: inline ? "2rem" : "0.5rem",
            alignItems: "center",
          }}
        >
          <img
            src={new URL("/app/assets/graphics/legend/Li.png", import.meta.url)}
            alt="Lithium"
            style={{
              maxWidth: inline ? (isSmall ? "20vw" : "10vw") : "max(5vw, 5rem)",
              maxHeight: inline
                ? isSmall
                  ? "7rem"
                  : "7rem"
                : "max(4rem, 4rem)",
            }}
          />
          {inline && "Lithium"}
        </div>
      </Link>
      <Link
        to="/home/graphite#section_1"
        onClick={nextSection}
        style={{ height: "fit-content" }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: inline ? "2rem" : "0.5rem",
            alignItems: "center",
          }}
        >
          <img
            src={new URL("/app/assets/graphics/legend/Gr.png", import.meta.url)}
            alt="Graphite"
            style={{
              maxWidth: inline ? (isSmall ? "20vw" : "10vw") : "max(5vw, 5rem)",
              maxHeight: inline
                ? isSmall
                  ? "7rem"
                  : "7rem"
                : "max(4rem, 4rem)",
            }}
          />
          {inline && "Graphite"}
        </div>
      </Link>
    </div>
  );
  return (
    <InView
      as="li"
      threshold={0.4}
      onChange={(inView) => updateStage(0, inView)}
      className={fadeClass_(stage)(0) + " scroll-to-section"}
      style={{ zIndex: 100 }}
    >
      <div
        className="homepage-description"
        id="section_0"
        style={{ zIndex: 1000 }}
      >
        <h2 className="home-intro__title">The Minerals in Our Explorer</h2>
        The Climate Mineral Explorer contains a growing list of minerals to
        explore. Currently two minerals are included: Lithium and Graphite.
        {links(true)}
        <p
          className={`${isSmall || "scroll-to-sect-btn"} ${
            stage == 0 ? "visible" : ""
          }`}
          style={{ left: 0, marginLeft: "auto", marginRight: "auto" }}
        >
          Click on one of the minerals to find out more
        </p>
        {links(false)}
        <HeroImg
          imgUrl={
            new URL(
              "/app/assets/graphics/content/home-map-0.png",
              import.meta.url
            )
          }
          show={stage == 0}
        />
      </div>
    </InView>
  );
};

const LithiumInfo = ({ stage, updateStage }) => {
  const fadeClass = fadeClass_(stage);
  const isSmall = useMediaQuery("(max-width: 767px)");
  return (
    <>
      <InView
        as="li"
        threshold={0.4}
        onChange={(inView) => updateStage(1, inView)}
        className={fadeClass(1) + " scroll-to-section"}
      >
        <div className="homepage-description" id="section_1">
          Lithium is a key component of electric vehicles (EVs) batteries and
          grid storage. But lithium’s production and transport has a carbon and
          environmental footprint that needs to be improved to ensure the
          mineral has a continued contribution to a low-carbon future.
          <HeroImg
            imgUrl={
              new URL(
                "/app/assets/graphics/content/Map 1 - Lithium.png",
                import.meta.url
              )
            }
            show={stage == 1}
          />
          <a
            href="#section_2"
            className={`scroll-to-sect-btn ${stage == 1 ? "visible" : ""}`}
          >
            <i className="collecticon-arrow-down" />
          </a>
        </div>
      </InView>
      <InView
        as="li"
        threshold={0.4}
        onChange={(inView) => updateStage(2, inView)}
        className={fadeClass(2) + " scroll-to-section"}
      >
        <div className="homepage-description" id="section_2">
          Lithium can be found on every continent in the world. Today, most
          lithium is produced in South America or Australia.
          <HeroImg
            imgUrl={
              new URL(
                "/app/assets/graphics/content/Map 2 - Lithium.png",
                import.meta.url
              )
            }
            show={stage == 2}
          />
          <a
            href="#section_3"
            className={`scroll-to-sect-btn ${stage == 2 ? "visible" : ""}`}
          >
            <i className="collecticon-arrow-down" />
          </a>
        </div>
      </InView>
      <InView
        as="li"
        threshold={0.4}
        onChange={(inView) => updateStage(3, inView)}
        className={fadeClass(3) + " scroll-to-section"}
      >
        <div className="homepage-description" id="section_3">
          Across the lithium supply chain, opportunities exist to further reduce
          greenhouse gas (GHG) emissions, reduce energy and water used in mines
          and across the value chain. This increases the decarbonization impact
          of deploying EVs at scale for a truly clean energy transition.
          <HeroImg
            imgUrl={
              new URL(
                "/app/assets/graphics/content/Map 3 - Lithium.png",
                import.meta.url
              )
            }
            show={stage == 3}
          />
          <a
            href="#section_4"
            className={`scroll-to-sect-btn ${stage == 3 ? "visible" : ""}`}
          >
            <i className="collecticon-arrow-down" />
          </a>
        </div>
      </InView>
      <InView
        as="li"
        threshold={0.4}
        onChange={(inView) => updateStage(4, inView)}
        className={fadeClass(4) + " scroll-to-section"}
      >
        <div className="homepage-description" id="section_4">
          This dashboard explores the GHG and energy footprints of lithium
          supply chains for one EV and enables users to map sustainable
          opportunities for decarbonization.
          <HeroImg
            imgUrl={
              new URL(
                "/app/assets/graphics/content/home-map-4.png",
                import.meta.url
              )
            }
            show={stage == 4}
          />
          <a
            href="#section_5"
            className={`scroll-to-sect-btn ${stage == 4 ? "visible" : ""}`}
          >
            <i className="collecticon-arrow-down" />
          </a>
        </div>
      </InView>
      <InView
        as="li"
        threshold={0.4}
        onChange={(inView) => updateStage(5, inView)}
        className={fadeClass(5) + " scroll-to-section"}
      >
        <AnimatedHeroImg
          imgUrl={[
            new URL(
              "/app/assets/graphics/content/home-no-routes.png",
              import.meta.url
            ),
            new URL(
              "/app/assets/graphics/content/home-map-5.png",
              import.meta.url
            ),
            new URL(
              "/app/assets/graphics/content/home-map-6.png",
              import.meta.url
            ),
            new URL(
              "/app/assets/graphics/content/home-map-7.png",
              import.meta.url
            ),
            new URL(
              "/app/assets/graphics/content/home-no-routes.png",
              import.meta.url
            ),
          ]}
          show={stage == 5}
          id={isSmall ? "section_5" : undefined}
        />
        <div
          className="homepage-description"
          id={isSmall ? undefined : "section_5"}
        >
          The{" "}
          <a
            className="link-with-line"
            href="https://www.worldbank.org/en/topic/extractiveindustries/brief/climate-smart-mining-minerals-for-climate-action"
            target="_blank"
            rel="noopener noreferrer"
          >
            Climate-Smart Mining Initiative
          </a>{" "}
          has developed this platform within the context of the{" "}
          <a
            className="link-with-line"
            href="https://www.worldbank.org/en/news/press-release/2021/06/22/world-bank-group-increases-support-for-climate-action-in-developing-countries"
            target="_blank"
            rel="noopener noreferrer"
          >
            World Bank Climate Action Plan 2021 – 2025
          </a>{" "}
          pledging support to decarbonize the transport sector and accelerate
          the clean energy transition.
          <div className="cta-wrapper">
            <Link
              to="/data-snapshot"
              title="Data Snapshot"
              className="ctab ctab--explore"
            >
              <span>Explore</span>
            </Link>
            <Link to="/about" title="About" className="ctab ctab--about">
              <span>About</span>
            </Link>
          </div>
        </div>
      </InView>
    </>
  );
};

const GraphiteInfo = ({ stage, updateStage }) => {
  const fadeClass = fadeClass_(stage);
  const isSmall = useMediaQuery("(max-width: 767px)");
  return (
    <>
      <InView
        as="li"
        threshold={0.4}
        onChange={(inView) => updateStage(1, inView)}
        className={fadeClass(1) + " scroll-to-section"}
      >
        <div className="homepage-description" id="section_1">
          Graphite is used in a variety of applications, including writing
          materials, lubricants, paint, refractory, nuclear reactors, graphene
          sheets and crucially lithium-ion batteries. But graphite’s production
          and transportation have carbon footprints that need to be understood
          and mitigated as far as possible to ensure that the mineral has a
          continued contribution to a low-carbon future.
          <HeroImg
            imgUrl={
              new URL(
                "/app/assets/graphics/content/Map 1 - Graphite.png",
                import.meta.url
              )
            }
            show={stage == 1}
          />
          <a
            href="#section_2"
            className={`scroll-to-sect-btn ${stage == 1 ? "visible" : ""}`}
          >
            <i className="collecticon-arrow-down" />
          </a>
        </div>
      </InView>
      <InView
        as="li"
        threshold={0.4}
        onChange={(inView) => updateStage(2, inView)}
        className={fadeClass(2) + " scroll-to-section"}
      >
        <div className="homepage-description" id="section_2">
          Graphite, is produced either from natural sources or synthetically and
          can be found on every continent in the world. Today, China is the
          world's largest natural graphite producer with a production of 850,000
          metric tons. Other countries that produce natural graphite include
          Madagascar, Mozambique, and Brazil with smaller levels of production
          in South Korea, Russia, Canada, Norway, and India.
          <HeroImg
            imgUrl={
              new URL(
                "/app/assets/graphics/content/Map 2 - Graphite.png",
                import.meta.url
              )
            }
            show={stage == 2}
          />
          <a
            href="#section_3"
            className={`scroll-to-sect-btn ${stage == 2 ? "visible" : ""}`}
          >
            <i className="collecticon-arrow-down" />
          </a>
        </div>
      </InView>
      <InView
        as="li"
        threshold={0.4}
        onChange={(inView) => updateStage(3, inView)}
        className={fadeClass(3) + " scroll-to-section"}
      >
        <div className="homepage-description" id="section_3">
          Across the natural graphite supply chain, opportunities exist to
          further reduce energy use and greenhouse gas (GHG) emissions. This
          increases the decarbonization impact of deploying graphite products at
          scale for a truly clean energy transition.
          <HeroImg
            imgUrl={
              new URL(
                "/app/assets/graphics/content/Map 3 - Graphite.png",
                import.meta.url
              )
            }
            show={stage == 3}
          />
          <a
            href="#section_4"
            className={`scroll-to-sect-btn ${stage == 3 ? "visible" : ""}`}
          >
            <i className="collecticon-arrow-down" />
          </a>
        </div>
      </InView>
      <InView
        as="li"
        threshold={0.4}
        onChange={(inView) => updateStage(4, inView)}
        className={fadeClass(4) + " scroll-to-section"}
      >
        <div className="homepage-description" id="section_4">
          This dashboard explores the GHG and energy footprints of natural
          graphite supply chains and enables users to map sustainable
          opportunities for decarbonization.
          <HeroImg
            imgUrl={
              new URL(
                "/app/assets/graphics/content/Map 4 - Graphite.png",
                import.meta.url
              )
            }
            show={stage == 4}
          />
          <a
            href="#section_5"
            className={`scroll-to-sect-btn ${stage == 4 ? "visible" : ""}`}
          >
            <i className="collecticon-arrow-down" />
          </a>
        </div>
      </InView>
      <InView
        as="li"
        threshold={0.4}
        onChange={(inView) => updateStage(5, inView)}
        className={fadeClass(5) + " scroll-to-section"}
      >
        <AnimatedHeroImg
          imgUrl={[
            new URL(
              "/app/assets/graphics/content/home-no-routes.png",
              import.meta.url
            ),
            new URL(
              "/app/assets/graphics/content/home-map-5.png",
              import.meta.url
            ),
            new URL(
              "/app/assets/graphics/content/home-map-6.png",
              import.meta.url
            ),
            new URL(
              "/app/assets/graphics/content/home-map-7.png",
              import.meta.url
            ),
            new URL(
              "/app/assets/graphics/content/home-no-routes.png",
              import.meta.url
            ),
          ]}
          show={stage == 5}
          id={isSmall ? "section_5" : undefined}
        />
        <div
          className="homepage-description"
          id={isSmall ? undefined : "section_5"}
        >
          The{" "}
          <a
            className="link-with-line"
            href="https://www.worldbank.org/en/topic/extractiveindustries/brief/climate-smart-mining-minerals-for-climate-action"
            target="_blank"
            rel="noopener noreferrer"
          >
            Climate-Smart Mining Initiative
          </a>{" "}
          has developed this platform within the context of the{" "}
          <a
            className="link-with-line"
            href="https://www.worldbank.org/en/news/press-release/2021/06/22/world-bank-group-increases-support-for-climate-action-in-developing-countries"
            target="_blank"
            rel="noopener noreferrer"
          >
            World Bank Climate Action Plan 2021 – 2025
          </a>{" "}
          pledging support to decarbonize the transport sector and accelerate
          the clean energy transition.
          <div className="cta-wrapper">
            <Link
              to="/data-snapshot"
              title="Data Snapshot"
              className="ctab ctab--explore"
            >
              <span>Explore</span>
            </Link>
            <Link to="/about" title="About" className="ctab ctab--about">
              <span>About</span>
            </Link>
          </div>
        </div>
      </InView>
    </>
  );
};

export default connect()(Home);
