import { Component } from "react";
import { PropTypes as T } from "prop-types";
import { environment } from "../../config";

import { Group } from "@visx/group";
import { AxisLeft } from "@visx/axis";
import { Text } from "@visx/text";

const tickComponent = ({ formattedValue, ...tickProps }) => (
  <text {...tickProps}>{formattedValue}</text>
);

const yTickProps = {
  textAnchor: "end",
  dx: "-0.25em",
  dy: "0.25em",
};

class YAxis extends Component {
  render() {
    const {
      xMin,
      yMin = 0,
      yScale,
      label,
      yTickLength = 4,
      numTicks = 3,
      textAnchor = "end",
      tickFormat,
      tickValues,
      textOffset,
    } = this.props;

    return (
      <Group>
        {(label && (
          <Text
            verticalAnchor="start"
            textAnchor={textAnchor}
            x={textOffset !== undefined ? textOffset : xMin}
            y={yMin}
            className="chart__yaxis_label"
            width={xMin}
          >
            {label}
          </Text>
        )) ||
          ""}
        <AxisLeft
          left={xMin}
          scale={yScale}
          tickComponent={tickComponent}
          className="y-axis"
          tickLabelProps={yTickProps}
          tickLength={yTickLength}
          numTicks={numTicks}
          tickFormat={tickFormat}
          tickValues={tickValues}
        />
      </Group>
    );
  }
}

if (environment !== "production") {
  YAxis.propTypes = {
    xMin: T.number,
    yMin: T.number,
    textOffset: T.number,
    yScale: T.func,
    label: T.string,
    yTickLength: T.number,
    numTicks: T.number,
    textAnchor: T.string,
    tickFormat: T.func,
    tickValues: T.array,
  };
}

export default YAxis;
