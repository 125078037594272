export const FakeRadio = ({
  selected,
  className = "fakeradio__marker",
  size = 12,
}) => (
  <svg width={size + 2} height={size} className={className}>
    <g>
      <circle
        cx={size / 2}
        cy={size / 2}
        r={size / 2 - 0.5}
        strokeWidth={1}
        fillOpacity={0}
        className="darker-primary-color"
      />
      {(selected && (
        <circle
          cx={size / 2}
          cy={size / 2}
          r={size / 2 - 2.5}
          stroke={0}
          className="primary-color"
        />
      )) ||
        ""}
    </g>
  </svg>
);
