import { Component } from "react";
import { PropTypes as T } from "prop-types";
import { environment } from "../config";

class Tabs extends Component {
  renderTabs() {
    const { activeTab, tabList, onChange } = this.props;

    return tabList.map((tab, index) => {
      return (
        <li className="nav__tab" role="presentation" key={index}>
          <a
            className={`nav__link ${
              activeTab === index ? "nav__link--active" : ""
            }`}
            onClick={(event) => {
              event.preventDefault();
              onChange && onChange(index);
            }}
            title={tab.title}
          >
            <span className={"tab-name"}>{tab.tab_name}</span>
          </a>
        </li>
      );
    });
  }

  render() {
    const { tabList, activeTab, className, tabBodyClassName } = this.props;

    const renderedTab =
      tabList[activeTab].contents && tabList[activeTab].contents();
    return (
      <div className={`econtrols ${(className && className) || ""}`}>
        <nav className="nav">
          <ul className="nav__tablist" role="tablist">
            {this.renderTabs()}
          </ul>
        </nav>
        <div style={{ flexGrow: 2 }} className={tabBodyClassName}>
          {renderedTab}
        </div>
      </div>
    );
  }
}

if (environment !== "production") {
  Tabs.propTypes = {
    tabList: T.array,
    onChange: T.func,
    activeTab: T.number,
  };
}

export default Tabs;
