import defaults from "./defaults";
import local from "./local";
import production from "./production";
import staging from "./staging";

export default {
  defaults,
  local,
  production,
  staging,
};
