import { PropTypes as T } from "prop-types";
import { environment } from "../../../config";

import TextResult from "../../explore/TextResult";

const MetricsBar = (props) => {
  const {
    selectedChain = {},
    chainContext = {},
    contextTitle = "",
    lowestChain = {},
  } = props;
  const tCO2e = (
    <span>
      tCO<sub>2</sub>e
    </span>
  );

  const lowest = (field) =>
    (lowestChain[field] && lowestChain[field].metrics[field]) || undefined;
  const lowestTitle = (field) =>
    (lowestChain[field] && lowestChain[field].tab_name) || "";

  return (
    <div className="chain__headline-metrics inpage__textresults">
      <TextResult
        value="Li for 1 EV"
        infoNote="KPI metrics are representative of the lithium components for one single EV via the chosen supply chain route. In other words, this represents the impact for the extraction and processing of the lithium needed, along with the process of integrating the lithium into a battery, and assembling the electric vehicle."
      />
      <TextResult
        title="Battery Type"
        value={selectedChain.battery_type}
        contextValue={chainContext.battery_type}
        contextTitle={contextTitle}
        infoNote="There are several different li-ion battery types. A battery type has been associated with each supply chain based on how common it is and whether it uses lithium carbonate or hydroxide"
      />
      <TextResult
        title="GHG Emissions"
        value={selectedChain.total_emissions}
        units={tCO2e}
        lowest={lowest("total_emissions")}
        lowTitle={lowestTitle("total_emissions")}
        contextValue={chainContext.total_emissions}
        contextTitle={contextTitle}
        infoNote="GHG (CO<sub>2</sub>, CH<sub>4</sub> and N<sub>2</sub>O) associated with extracting & processing lithium, along with combining it with other materials into a li-ion battery and assembling the EV. On average extraction and processing is approximately 2% of emissions, battery manufacturing is 38% and vehicle assembly 53%, with shipping 2%."
      />
      <TextResult
        title="Energy Usage"
        value={selectedChain.total_energy}
        units="GJ"
        lowest={lowest("total_energy")}
        lowTitle={lowestTitle("total_energy")}
        contextValue={chainContext.total_energy}
        contextTitle={contextTitle}
        infoNote="This is the energy used to extract & process lithium, along with combining it with other materials into a li-ion battery and assembling the EV. It includes electricity and other fuels. "
      />
      <TextResult
        title="Shipping Emissions"
        value={selectedChain.shipping_emissions}
        units={tCO2e}
        lowest={lowest("shipping_emissions")}
        lowTitle={lowestTitle("shipping_emissions")}
        contextValue={chainContext.shipping_emissions}
        contextTitle={contextTitle}
        infoNote="GHG emissions associated with shipping lithium and manufactured products (battery & EV) between supply chain locations. This includes shipping emissions between countries, and not road transportation within the same country."
      />
      <TextResult
        title="Equivalent ICE Emission"
        units="Km"
        infoNote="1 Ton Total GHG Emissions CO2 is equivalent to driving an average gasoline car 7047km"
        value={selectedChain.total_emissions * 7047}
        contextValue={chainContext.total_emissions * 7047 || undefined}
        contextTitle={contextTitle}
      />
    </div>
  );
};

if (environment !== "production") {
  MetricsBar.propTypes = {
    selectedChain: T.object,
    chainContext: T.object,
  };
}

export default MetricsBar;
