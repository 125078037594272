import { Component } from "react";
import { PropTypes as T } from "prop-types";
import { Group } from "@visx/group";
import { scaleLinear, scaleOrdinal } from "@visx/scale";
import { ParentSize } from "@visx/responsive";
import { LegendOrdinal } from "@visx/legend";
import { withTooltip, TooltipWithBounds, defaultStyles } from "@visx/tooltip";
import { BarStackHorizontal } from "@visx/shape";
import { environment } from "../../config";
import { localPoint } from "@visx/event";
import Figure from "../explore/Figure";

const keys = [
  "extraction_emissions",
  "processing_emissions",
  "battery_assembly_emissions",
  "vehicle_assembly_emissions",
  "shipping_emissions",
];
const labels = [
  "Extraction",
  "Processing",
  "Battery Manufacturing",
  "Vehicle Assembly",
  "Shipping",
];

const labelMap = Object.fromEntries(keys.map((k, idx) => [k, labels[idx]]));
const colors = ["#77a0db", "#a6b5df", "#01c8bc", "#7fe5db", "#00a66b"];

/**
 * The TwoUpEmissionsChart component
 */
class TwoUpEmissionsChart extends Component {
  constructor(props) {
    super(props);
    this.renderChart = this.renderChart.bind(this);
    this.handleMouseOver = this.handleMouseOver.bind(this);
  }

  handleMouseOver(event, datum) {
    const coords = localPoint(event.target.ownerSVGElement, event);
    this.props.showTooltip({
      tooltipLeft: coords.x,
      tooltipTop: coords.y,
      tooltipData: datum,
    });
  }

  renderChart(parsedData, parsedKeys, parentWidth) {
    const _formatter = Intl.NumberFormat("en-US", {
      maximumSignificantDigits: 2,
    });
    const fmt = (x) => _formatter.format(x);

    // Chart properties
    const height = 100; //parentHeight - 30; // figcaption
    const width = parentWidth;

    const barHeight = height / 4; // this is just a tuning option
    const margin = {
      top: 20,
      bottom: 0,
      left: 0,
      right: 0,
    };
    const xMin = margin.left;
    const xMax = width - margin.right;
    const yMin = margin.top;
    const yMax = height - margin.bottom;

    const tooltipStyles = {
      ...defaultStyles,
      minWidth: 80,
    };

    const { tooltipData, tooltipLeft, tooltipTop, tooltipOpen, hideTooltip } =
      this.props;

    const { flLegend = true } = this.props;

    const maxVal = Math.max(
      ...parsedData.map((e) => e.total_emissions + (e.shipping_emissions || 0))
    );

    // Define scales
    const xScale = scaleLinear({
      range: [xMin, xMax],
      domain: [0, maxVal],
    });

    const yScale = scaleLinear({
      range: [yMin, yMax],
      domain: [0, parsedData.length - 0.5], // this is backwards, top first.
    });

    // Code name for the keys
    const colorScale = scaleOrdinal({
      domain: parsedKeys,
      range: colors,
    });

    // Full name for the keys
    const legendScale = scaleOrdinal({
      domain: labels,
      range: colors,
    });

    return (
      <figure className="popchart__chart">
        <div className="popchart__chart_item">
          <svg width={width} height={height}>
            <Group>
              <BarStackHorizontal
                data={parsedData}
                yScale={yScale}
                xScale={xScale}
                y={(d) => d.idx}
                keys={parsedKeys}
                height={height}
                color={colorScale}
              >
                {(barStacks) =>
                  barStacks.map((barStack) =>
                    barStack.bars
                      .filter((bar) => bar.bar.data[bar.key] !== undefined)
                      .map((bar) => {
                        const data = bar.bar.data;
                        const y = yScale(data.idx);
                        return (
                          <g key={`bsh-${barStack.key}-${data.idx}`}>
                            <rect
                              x={bar.x}
                              y={y}
                              width={bar.width}
                              height={barHeight}
                              fill={bar.color}
                              onMouseOver={(e) =>
                                this.handleMouseOver(e, {
                                  title: labelMap[bar.key],
                                  color: bar.color,
                                  value: data[bar.key],
                                })
                              }
                              onMouseOut={hideTooltip}
                            />
                            {bar.width > 22 && (
                              <text
                                x={bar.x}
                                y={y}
                                dx={2}
                                dy={barHeight / 2}
                                className="annotation__bar-label"
                                style={{ fill: "white" }}
                              >
                                {fmt(bar.bar.data[bar.key])}
                              </text>
                            )}
                          </g>
                        );
                      })
                  )
                }
              </BarStackHorizontal>
              {parsedData.map((e) => (
                <text
                  key={`series-label-${e.idx}`}
                  x={margin.left}
                  y={yScale(e.idx)}
                  dx={2}
                  dy={-5}
                  className="annotation__series-label"
                  style={{ fill: "#4e4e4e" }}
                  onMouseOut={hideTooltip}
                  onMouseOver={(e) =>
                    this.handleMouseOver(e, {
                      title: "Click to compare",
                      color: "black",
                    })
                  }
                >
                  {e.title || "Your Supply Chain"}
                </text>
              ))}
              {/* totals */}
              {parsedData.map((e, idx) => {
                const total = parsedKeys
                  .map((k) => e[k])
                  .filter((e) => e)
                  .reduce((a, b) => a + b, 0);
                const x = xScale(total);
                const atEnd = xMax - x > 22;
                return (
                  <text
                    key={`text-total-${idx}`}
                    x={x}
                    y={yScale(e.idx)}
                    dy={barHeight / 2}
                    dx={atEnd ? 2 : -2}
                    textAnchor={atEnd ? "start" : "end"}
                    className="annotation__series-label"
                    style={{ fill: "#4e4e4e", pointerEvents: "none" }}
                  >
                    {fmt(total)}
                  </text>
                );
              })}
            </Group>
          </svg>
          {(flLegend && (
            <LegendOrdinal
              scale={legendScale}
              direction="row"
              labelMargin="0 6px 0 0"
              className="legend__twocol"
            />
          )) ||
            ""}
          {tooltipOpen && (
            <TooltipWithBounds
              key={Math.random()}
              top={tooltipTop}
              left={tooltipLeft}
              style={tooltipStyles}
            >
              <div className={"tooltip-wrapper"}>
                <div style={{ color: tooltipData.color, marginBottom: "8px" }}>
                  <strong>{tooltipData.title}</strong>
                </div>
                {tooltipData.value !== undefined ? (
                  <div>
                    <strong>
                      {fmt(tooltipData.value)} tCO<sub>2</sub>e
                    </strong>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </TooltipWithBounds>
          )}
        </div>
      </figure>
    );
  }

  render() {
    const {
      title,
      description,
      baseChain = {},
      decarbonizedChain = {},
      selector,
      decarbonizedProfile = "",
    } = this.props;

    //    { Coal: { gen:, gen_pct: }, ...}
    // Object.entries(...) -> [[ Coal, {gen: gen_pct: }], ...]

    if (!decarbonizedChain.metrics || !baseChain.metrics) {
      return null;
    }
    const parsedData = [
      { ...baseChain.metrics, title: "Baseline", idx: 0 },
      {
        ...decarbonizedChain.metrics,
        title: `Decarbonized${
          decarbonizedProfile ? ":" : ""
        } ${decarbonizedProfile}`,
        idx: 1,
      },
    ];

    const parsedKeys = keys.map((e) => (selector ? `${e}_${selector}` : e));

    return (
      <ParentSize>
        {({ width: parentWidth, height: parentHeight }) =>
          (parentWidth && (
            <Figure
              title={title}
              className="chart__pop"
              description={description}
            >
              {this.renderChart(
                parsedData,
                parsedKeys,
                parentWidth,
                parentHeight - 60
              )}
            </Figure>
          )) ||
          ""
        }
      </ParentSize>
    );
  }
}

export const TwoUpEmissionsStackedBar = withTooltip(TwoUpEmissionsChart);

if (environment !== "production") {
  TwoUpEmissionsChart.propTypes = {
    title: T.oneOfType([T.object, T.string]),
    description: T.string,
    baseChain: T.object,
    decarbonizedChain: T.object,
    selector: T.string,
    flLegend: T.bool,
  };
  TwoUpEmissionsStackedBar.propTypes = {
    title: T.oneOfType([T.object, T.string]),
    description: T.string,
    baseChain: T.object,
    decarbonizedChain: T.object,
    selector: T.string,
    flLegend: T.bool,
  };
}
