import { PropTypes as T } from "prop-types";
import { environment } from "../../../config";

import { SingleBar } from "../../SingleBar";
import market_share_csv from "bundle-text:/data/market_share.csv";
import { parse } from "csv-parse/lib/sync";
const market_share = parse(market_share_csv, {
  columns: true,
  cast: true,
});

export const MarketShare = ({ iso3 }) => {
  const share_data = market_share.filter((e) => e.country == iso3).pop();

  if (
    !share_data ||
    !(
      share_data.graphite_extraction ||
      share_data.graphite_processing ||
      share_data.battery_assembly ||
      share_data.vehicle_assembly
    )
  ) {
    return (
      <div className="marketshare__container">
        No market share data for this country
      </div>
    );
  }

  return (
    <div className="marketshare__container">
      <h4>Extraction</h4>
      <SingleBar value_frac={share_data.graphite_extraction || 0} />
      <h4>Processing</h4>
      <SingleBar value_frac={share_data.graphite_processing || 0} />
      <h4>Battery Manufacturing</h4>
      <SingleBar value_frac={share_data.battery_assembly || 0} />
      <h4>EV Assembly</h4>
      <SingleBar value_frac={share_data.vehicle_assembly || 0} />
    </div>
  );
};

if (environment !== "production") {
  MarketShare.propTypes = {
    iso3: T.string,
  };
}
