import { PropTypes as T } from "prop-types";
import { Link } from "react-router-dom";
import { environment } from "../../../config";

import { countryForIso } from "../../../iso3";
import { CountryDropdown } from "../CountryDropdown";
import { CountryStackedBar } from "../../CountryYearlyStackedBar";
import { SingleBar } from "../../SingleBar";
import { CountryFlag } from "../../CountryFlag";

export const DataCountryFocusTab = ({
  iso3 = "AUS",
  onChange,
  data,
  units,
  title,
  description,
  selectedField,
}) => {
  const countryMap = Object.fromEntries(
    data
      .filter((e) => e[selectedField])
      .map((e) => [e.country, countryForIso(e.country)])
  );
  const filteredData = data.filter((e) => e.country == iso3);

  let colors = {};
  colors[iso3] = "green";

  return (
    <div className="tab__focus scrollfixer">
      <div className="country_header">
        <CountryDropdown
          countryData={countryMap}
          selectedCountry={iso3}
          onChange={onChange}
        />
        <CountryFlag iso3={iso3} />
      </div>
      <div key={selectedField} style={{ height: "30vh", width: "100%" }}>
        <CountryStackedBar
          title={title}
          data={filteredData}
          selectedField={selectedField}
          units={units}
          colors={colors}
          showLegend={false}
        />
      </div>
      {description}
      <Link to={`/countries/${iso3}`}>More Information</Link>
      <GlobalContext data={data} iso3={iso3} selectedField={selectedField} />
    </div>
  );
};

export const GlobalContext = ({ iso3, data, selectedField }) => {
  const currentYear = Math.max(
    ...data.filter((e) => e[selectedField]).map((e) => e.year)
  );
  const currentYearData = data.filter((e) => e.year == currentYear);
  const { ev_sales: ev_sales_total, ev_stock: ev_stock_total } =
    currentYearData.reduce(
      (
        { ev_sales: acc_sales, ev_stock: acc_stock },
        { ev_sales, ev_stock }
      ) => ({
        ev_sales: acc_sales + (ev_sales || 0),
        ev_stock: acc_stock + (ev_stock || 0),
      }),
      { ev_sales: 0, ev_stock: 0 }
    );
  const { ev_sales: ev_sales_local = 0, ev_stock: ev_stock_local = 0 } =
    currentYearData.filter((e) => e.country == iso3)[0] || {};

  return (
    <div style={{ marginTop: "1rem" }}>
      <h2>Context ({currentYear})</h2>
      <h3>EV Percentage of Vehicle Sales:</h3>
      <SingleBar
        value_pct={(ev_sales_local / ev_sales_total) * 100}
        places={2}
      />
      <h3>EV Percentage of Vehicle Stock:</h3>
      <SingleBar
        value_pct={(ev_stock_local / ev_stock_total) * 100}
        places={2}
      />
    </div>
  );
};

if (environment !== "production") {
  DataCountryFocusTab.propTypes = {
    iso3: T.string,
    onChange: T.func,
  };
}
