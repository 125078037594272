"use strict";

export default {
  appTitle: "Climate Smart Mining",
  appShortTitle: "CSM",
  appDescription: "Lithium Supply Chains",
  baseUrl: "http://localhost:9001",
  tileUrl: "",
  dataServiceUrl: "http://localhost:8000",
  // cme style with wb-adm0-fill layer
  basemapStyleLink: "mapbox://styles/derilinx/clfs8eank001s01rp04lzrow2/draft",
  mapboxAccessToken:
    "pk.eyJ1IjoiZGVyaWxpbngiLCJhIjoiY2szeTlzbWo2MDV6eDNlcDMxM3dzZXBieiJ9.zPf1iiFilYYwyx6ETNj_8w",
};
