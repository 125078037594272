import { Component } from "react";
import { PropTypes as T } from "prop-types";
import { environment } from "../../config";

import {
  chainWithEmissionProfile,
  heatFactors,
  elecFactors,
} from "../../json/lithium/chains";

import { connect } from "react-redux";

import { createUiDispatcher } from "../../redux/actions";
import { FakeRadio } from "../FakeRadio";
import { InfoButton } from "../InfoButton";

class DecarbonizationOptions extends Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
    this.renderGroup = this.renderGroup.bind(this);
    this.renderOption = this.renderOption.bind(this);
    this.onUpdate = this.onUpdate.bind(this);

    let decarbonizationState = this.props.decarbonizationState || {}; // from redux
    if (!decarbonizationState.elecProfile) {
      decarbonizationState = {
        elecProfile: "Current",
        heatProfile: "Current",
      };
    }

    this.state = {
      decarbonizationState,
    };
    this.props.setState(decarbonizationState);
    this.onUpdate(decarbonizationState);
  }

  onClick(field, value) {
    const { decarbonizationState } = this.state;
    let newFilterState = { ...decarbonizationState };

    newFilterState[field] = value;

    this.onUpdate(newFilterState);
    this.setState({ decarbonizationState: newFilterState });
    this.props.setState(newFilterState);
  }

  onUpdate(decarbonizationState) {
    const { handleDecarbonizedChain, chain } = this.props;
    const { elecProfile, heatProfile } = decarbonizationState;
    handleDecarbonizedChain &&
      handleDecarbonizedChain(
        chainWithEmissionProfile(chain, elecProfile, heatProfile)
      );
  }

  renderOption(field, value, label, selected) {
    const extraClass = (selected && "selected") || "";
    return (
      <a
        key={`opt-${value}`}
        className={`choose__link ${extraClass}`}
        onClick={() => this.onClick(field, value)}
      >
        <FakeRadio selected={selected} />
        {label}
      </a>
    );
  }

  renderGroup(title, descrip, field, options, currentValue) {
    return (
      (
        <div className="filter__group">
          <div className="filter__header byosc_dec_targets">
            <div>{title}</div>
            <InfoButton description={descrip} />
          </div>
          <div className="filter__body">
            <div className={"filter__ul"}>
              {options.map((v) =>
                this.renderOption(field, v, v, v == currentValue)
              )}
            </div>
          </div>
        </div>
      ) || ""
    );
  }

  render() {
    const { decarbonizationState } = this.state;
    const { elecProfile, heatProfile } = decarbonizationState;

    const elec_mix_desc =
      "You can choose from various electricity scenarios. These are from the IEA and represent projected future electricity mixes in either 2030 or 2050. SP is the Stated Policy scenario and represent what countries have committed to do. SDS is the Sustainable Development Scenario and represents a more ambitious carbon reduction scenario, following the Paris Agreement to achieve well below 2°C temperature rise";
    const heat_mix_desc =
      "You can choose from two different options for providing heat energy across the supply chain as alternatives to fossil fuels. Heat can be provided by burning biofuels or by burning low-carbon hydrogen, such as that produced using renewable electricity.";

    return (
      <div className="filter__panel">
        <div className="filter__panel_header">
          <h3 className="chart-media__caption">
            Decarbonization of this route:
          </h3>
          2030 & 2050 targets
        </div>
        {this.renderGroup(
          "Electricity Mix",
          elec_mix_desc,
          "elecProfile",
          Object.keys(elecFactors["CHN"]),
          elecProfile
        )}
        {this.renderGroup(
          "Heat Mix",
          heat_mix_desc,
          "heatProfile",
          ["Current", ...Object.keys(heatFactors)],
          heatProfile
        )}
      </div>
    );
  }
}

if (environment !== "production") {
  DecarbonizationOptions.propTypes = {
    chain: T.object,
    handleDecarbonizedChain: T.func,
  };
}

function mapStateToProps(state) {
  return {
    decarbonizationState: state.decarbonizationOptions,
  };
}

export default connect(
  mapStateToProps,
  createUiDispatcher("decarbonizationOptions")
)(DecarbonizationOptions);
