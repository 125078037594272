import { PropTypes as T } from "prop-types";

import { environment } from "../../config";

export const Figure = (props) => {
  const { title, description, className, subtitle } = props;

  return (
    <figure className={` ${className || ""}`} style={{ position: "relative" }}>
      {description && (
        <div
          className="form__inner-actions"
          style={{ position: "absolute", top: -4, right: 0 }}
        >
          <button
            type="button"
            className="eci-info"
            data-tooltip-html={description}
            data-tooltip-id="infotip-global"
            data-event="click"
          >
            <span>Info</span>
          </button>
        </div>
      )}
      <h3 className="chart-media__caption">{title}</h3>
      {(subtitle && <div className="chart-media__subtitle">{subtitle}</div>) ||
        ""}
      {props.children}
    </figure>
  );
};

if (environment !== "production") {
  Figure.propTypes = {
    title: T.oneOfType([T.object, T.string]),
    description: T.string,
    subtitle: T.string,
    className: T.string,
  };
}

export default Figure;
