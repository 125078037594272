import { Component } from "react";
import { PropTypes as T } from "prop-types";
import { Group } from "@visx/group";
import { scaleLinear, scaleOrdinal } from "@visx/scale";
import { ParentSize } from "@visx/responsive";
import { LegendOrdinal } from "@visx/legend";
import { withTooltip, TooltipWithBounds, defaultStyles } from "@visx/tooltip";
import { BarStackHorizontal } from "@visx/shape";
import { environment } from "../../config";
import { localPoint } from "@visx/event";
import Figure from "../explore/Figure";

const keys = [
  "extraction_emissions",
  "processing_emissions",
  "battery_assembly_emissions",
  "vehicle_assembly_emissions",
  "shipping_emissions",
];
const labels = [
  "Extraction",
  "Processing",
  "Battery Manufacturing",
  "Vehicle Assembly",
  "Shipping",
];

const labelMap = Object.fromEntries(keys.map((k, idx) => [k, labels[idx]]));
const colors = ["#77a0db", "#a6b5df", "#01c8bc", "#7fe5db", "#00a66b"];

/**
 * The EmissionsChart component
 */
class EmissionsChart extends Component {
  constructor(props) {
    super(props);
    this.renderChart = this.renderChart.bind(this);
    this.handleMouseOver = this.handleMouseOver.bind(this);
    this.handleSelectedChain = this.handleSelectedChain.bind(this);
  }

  handleSelectedChain(chain) {
    const { onSetBenchmarkChain } = this.props;
    onSetBenchmarkChain && onSetBenchmarkChain(chain);
  }

  handleMouseOver(event, datum) {
    const coords = localPoint(event.target.ownerSVGElement, event);
    this.props.showTooltip({
      tooltipLeft: coords.x,
      tooltipTop: coords.y,
      tooltipData: datum,
    });
  }

  renderChart(parsedData, parentWidth) {
    const _formatter = Intl.NumberFormat("en-US", {
      maximumSignificantDigits: 2,
    });
    const fmt = (x) => _formatter.format(x);

    // Chart properties
    const {
      margin = {
        top: 30,
        bottom: 0,
        left: 5,
        right: 5,
      },
      chains,
      highlightEntry = 1,
      legend = false,
      onSetBenchmarkChain,
    } = this.props;

    const clickToFocus = !!onSetBenchmarkChain;

    const height = chains.length * 45 + margin.top + margin.bottom; //parentHeight - 30; // figcaption
    const width = parentWidth;

    const barHeight = 25;

    const xMin = margin.left;
    const xMax = width - margin.right;
    const yMin = margin.top;
    const yMax = height - margin.bottom;

    const tooltipStyles = {
      ...defaultStyles,
      minWidth: 80,
    };

    const { tooltipData, tooltipLeft, tooltipTop, tooltipOpen, hideTooltip } =
      this.props;

    const highlightIdx = parsedData
      .filter((e) => highlightEntry == e.chain.id)
      .map((e) => e.idx)
      .pop();

    const maxVal = Math.max(...parsedData.map((e) => e.total));

    // Define scales
    const xScale = scaleLinear({
      range: [xMin, xMax],
      domain: [0, maxVal],
    });

    const yScale = scaleLinear({
      range: [yMin, yMax],
      domain: [0, parsedData.length - 0.5], // this is backwards, top first.
    });

    // Code name for the keys
    const colorScale = scaleOrdinal({
      domain: keys,
      range: colors,
    });

    // Full name for the keys
    const legendScale = scaleOrdinal({
      domain: labels,
      range: colors,
    });

    const fmtTitle = (e) => {
      if (!(e.chain && e.chain.tab_name)) {
        return undefined;
      }
      const tab_name = e.chain.tab_name.split(":").pop().trim();
      return e.chain && `${tab_name}`;
    };

    return (
      <figure className="popchart__chart">
        <div className="popchart__chart_item">
          <svg width={width} height={height}>
            <Group>
              {(highlightIdx != undefined && (
                <rect
                  x={0}
                  y={yScale(highlightIdx) - barHeight / 1.5}
                  fill="#eee"
                  width={width}
                  height={barHeight * 2}
                />
              )) ||
                ""}
              <BarStackHorizontal
                data={parsedData}
                yScale={yScale}
                xScale={xScale}
                y={(d) => d.idx}
                keys={keys}
                height={height}
                color={colorScale}
              >
                {(barStacks) =>
                  barStacks.map((barStack) =>
                    barStack.bars
                      .filter((bar) => bar.bar.data[bar.key] !== undefined)
                      .map((bar) => {
                        const data = bar.bar.data;
                        const y = yScale(data.idx);
                        return (
                          <g key={`bsh-${barStack.key}-${data.idx}`}>
                            <rect
                              x={bar.x}
                              y={y}
                              width={bar.width}
                              height={barHeight}
                              fill={bar.color}
                              onClick={() =>
                                clickToFocus &&
                                this.handleSelectedChain(data.chain)
                              }
                              onMouseOver={(e) =>
                                this.handleMouseOver(e, {
                                  title: labelMap[bar.key],
                                  color: bar.color,
                                  value: data[bar.key],
                                })
                              }
                              onMouseOut={hideTooltip}
                            />
                            {bar.width > 22 && bar.x < xMax - 50 && (
                              <text
                                key={`bar-label-${barStack.key}-${data.idx}`}
                                x={bar.x}
                                y={y}
                                dx={2}
                                dy={barHeight / 2}
                                className="annotation__bar-label"
                                style={{ fill: "white" }}
                              >
                                {fmt(bar.bar.data[bar.key])}
                              </text>
                            )}
                          </g>
                        );
                      })
                  )
                }
              </BarStackHorizontal>
              {/* series labels */}
              {parsedData.map((e) => (
                <text
                  key={`series-label-${e.idx}`}
                  x={margin.left}
                  y={yScale(e.idx)}
                  dx={2}
                  dy={-5}
                  className="annotation__series-label"
                  style={{ fill: "#4e4e4e" }}
                  onClick={() => this.handleSelectedChain(e.chain)}
                >
                  {fmtTitle(e) || "Your Supply Chain"}
                </text>
              ))}
              {/* totals */}
              {parsedData.map((e) => {
                /* const x=xScale(e.total); */
                /* const atEnd = xMax - x > 22; */
                return (
                  <text
                    key={`total-label-${e.idx}`}
                    x={xMax}
                    y={yScale(e.idx)}
                    dy={barHeight / 2}
                    /* dx={ atEnd ? 2 : -2} */
                    /* textAnchor={ atEnd ? 'start' : 'end' } */
                    dx={-2}
                    textAnchor="end"
                    className="annotation__series-label"
                    style={{ fill: "#4e4e4e", pointerEvents: "none" }}
                  >
                    {fmt(e.total)}
                  </text>
                );
              })}
            </Group>
          </svg>
          {(legend && (
            <LegendOrdinal
              scale={legendScale}
              direction="row"
              labelMargin="0 6px 0 0"
              className="legend__twocol"
            />
          )) ||
            ""}
          {tooltipOpen && (
            <TooltipWithBounds
              key={Math.random()}
              top={tooltipTop}
              left={tooltipLeft}
              style={tooltipStyles}
            >
              <div className={"tooltip-wrapper"}>
                <div style={{ color: tooltipData.color }}>
                  <strong>{tooltipData.title}</strong>
                </div>
                {tooltipData.value !== undefined ? (
                  <div style={{ marginTop: "8px" }}>
                    <strong>
                      {fmt(tooltipData.value)} tCO<sub>2</sub>e
                    </strong>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </TooltipWithBounds>
          )}
        </div>
      </figure>
    );
  }

  render() {
    const { title, description, chains, subtitle, className = "" } = this.props;

    //    { Coal: { gen:, gen_pct: }, ...}
    // Object.entries(...) -> [[ Coal, {gen: gen_pct: }], ...]

    if (!chains) {
      return null;
    }
    const parsedData = chains
      .filter((e) => e.metrics)
      .map((chain, idx) => {
        const data = Object.fromEntries(
          keys
            .filter((k) => chain.metrics[k]) // non-nan
            .map((k) => [k, chain.metrics[k]])
        ); // g-> kg
        return {
          ...data,
          total: Object.values(data).reduce((a, b) => a + b),
          title: chain.title,
          idx,
          chain,
        };
      });

    // parsedData = [ { Coal: %, oil: % , ...}]

    return (
      <ParentSize>
        {({ width: parentWidth, height: parentHeight }) =>
          (parentWidth && (
            <Figure
              title={title}
              className={`chart__pop ${className}`}
              description={description}
            >
              {this.renderChart(parsedData, parentWidth, parentHeight - 60)}
              {subtitle}
            </Figure>
          )) ||
          ""
        }
      </ParentSize>
    );
  }
}

export const EmissionsStackedBar = withTooltip(EmissionsChart);

if (environment !== "production") {
  EmissionsChart.propTypes = {
    title: T.oneOfType([T.object, T.string]),
    description: T.string,
    subtitle: T.string,
    chains: T.array,
    onSetBenchmarkChain: T.func,
  };
  EmissionsStackedBar.propTypes = {
    title: T.oneOfType([T.object, T.string]),
    description: T.string,
    subtitle: T.string,
    chains: T.array,
    onSetBenchmarkChain: T.func,
  };
}
