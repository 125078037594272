import { environment } from "../../../config";

import { countryForIso } from "../../../iso3";
import { StageMarker } from "../StageMarker";

const Panel = ({ stage, highlightStage, title, _fmt, children }) => {
  const highlightClass = stage == highlightStage ? "highlight" : "";
  return (
    <div className={`overview__panel ${highlightClass}`}>
      <StageMarker stage={stage} className="header_img_marker" />
      <h3 className="side_icon">{title}</h3>
      {children}
    </div>
  );
};

const OverviewTab = ({ chain, highlightStage }) => {
  return (
    <div className="tab__sidebar tab__overview">
      <h2>How the graphite supply chain works</h2>
      <Panel
        highlightStage={highlightStage}
        stage="extraction"
        title="Graphite Extraction"
        key="p-extraction"
      >
        <p>Graphite is produced from natural sources or synthetically:</p>

        <ul>
          <li>
            <p>
              <span className="bbold">Natural graphite</span> occurs in
              different forms such as flakes or lumps. Flake graphite is the
              type that is most desired for use in li-ion batteries.
            </p>
          </li>
          <li>
            <p>
              <span className="bbold">Synthetic graphite</span> is produced from
              either petroleum coke or coal tar pitch.
            </p>
          </li>
        </ul>

        <p>
          The largest natural graphite producer is CHN who supply most of the
          market. BRA, MOZ and MAD also supply smaller shares. Synthetic
          graphite is mostly produced in CHN, USA and JAP. Synthetic graphite is
          more energy and emissions intensive than natural graphite but offers
          greater stability and greater operating reliability and consistency.
        </p>
      </Panel>
      <Panel
        highlightStage={highlightStage}
        key="p-processing"
        stage="processing"
        title="Graphite Processing"
      >
        <p>
          To be used in li-ion batteries both natural and synthetic graphite
          needs to be further processed. This processing consists of
          spheroidization involving milling, processing, and coating with pitch
          or asphalt to create spheroidal graphite. This is then converted into
          anode suitable material through applying graphite paste formed from
          spheroidal graphite to copper foil. This can then be used in the
          anodes of li-ion batteries. The largest processor of natural and
          artificial graphite is CHN who together with JPN account for over 95%
          of global market sales.
        </p>
      </Panel>
      <Panel
        highlightStage={highlightStage}
        key="p-battery_assembly"
        stage="battery_assembly"
        title="Battery Manufacturing"
      >
        <p>
          Battery manufacturing includes making the cathode, anode, and
          electrolyte, as well as assembling the components to form a battery
          cell. Battery cells are then incorporated into battery packs composed
          of modules and protection systems that form the final battery. Anodes
          for li-ion batteries are mostly made up of anode graphite with smaller
          levels of alloy material such as aluminum, tin, or magnesium. Battery
          manufacturing today mainly occurs in Asia, especially in CHN, KOR and
          JPN, with growing production starting to occur in other regions, such
          as the EU and USA.
        </p>
      </Panel>
      <Panel
        highlightStage={highlightStage}
        key="p-vehicle_assembly"
        stage="vehicle_assembly"
        title="Vehicle Assembly"
      >
        <p>
          Vehicle assembly involves a range of different processes beyond the
          battery that are energy intensive to bring together different minerals
          found in an EV (e.g., chassis). The largest assembly locations are
          currently in CHN, EU and the USA. After vehicle assembly is completed,
          a critical aspect of EVs is the source of energy used to power these
          vehicles. This aspect is not currently captured in the dashboard.
        </p>
      </Panel>
      <Panel
        highlightStage={highlightStage}
        key="p-shipping"
        stage="shipping"
        title="Shipping"
      >
        <p>
          Shipping is a key component of graphite supply chains for EVs.
          Minerals need to be trucked around mine sites, moved to ports, and
          shipped between countries. Transporting millions of tons of minerals
          and EVs across the world has a large carbon footprint in total but is
          only a small percentage of the emissions of any one EV.
        </p>
      </Panel>
    </div>
  );
};

if (environment !== "production") {
  OverviewTab.propTypes = {};
}

export default OverviewTab;
