import { useState, useCallback, useRef, useEffect } from "react";
import useMediaQuery from "../useMediaQuery";

const ResponsiveSidebar = (props) => {
  const isSmall = useMediaQuery("(max-width: 767px)");
  return isSmall ? (
    <SmallScreenPopup {...props} />
  ) : (
    <LargeScreenSidebar {...props} />
  );
};

const LargeScreenSidebar = ({ openRef, children, className }) => {
  if (openRef) {
    openRef.current = () => {};
  }
  return <div className={`inpage__rightpanel ${className}`}>{children}</div>;
};

const isValidSwipeTarget = (el, isDown) => {
  if (!el) return true;
  if (
    el.onclick ||
    el.onblur ||
    el.onmouseenter ||
    el.onmousemove ||
    el.onmouseleave ||
    el.href
  )
    return false;
  if (
    el.className &&
    el.className.includes &&
    (el.className.includes("screen-bottom-drawer-root") ||
      el.className.includes("screen-bottom-drawer-body") ||
      el.className.includes("screen-bottom-drawer-trigger"))
  )
    return true;
  const scrollable = el.clientHeight < el.scrollHeight;
  const atTop = el.scrollTop == 0;
  // Non-trivial to support scrolling up from fully bottom-scrolled target, but not relevant right now
  // Swiping down when at top should be fine
  const swipeable = !scrollable || (!!isDown && atTop);
  return swipeable && isValidSwipeTarget(el.parentElement, isDown);
};

const useTouchHandlers = (actions) => {
  const ref = useRef(null);
  const actionsRef = useRef(null);
  actionsRef.current = actions;
  const touchStart = useRef(null);
  const touchEnd = useRef(null);
  const onTouchStart = useCallback(
    (e) => {
      touchEnd.current = null;
      touchStart.current = e.targetTouches[0].clientY;
      if (!isValidSwipeTarget(e.target, false)) return;
      e.stopPropagation();
      e.preventDefault();
    },
    [touchStart, touchEnd]
  );
  const onTouchMove = useCallback(
    (e) => {
      const y = e.targetTouches[0].clientY;
      if (
        !isValidSwipeTarget(
          e.target,
          !!actionsRef.current.down && y > touchStart.current
        )
      )
        return;
      touchEnd.current = y;
      e.stopPropagation();
      e.preventDefault();
    },
    [touchStart, touchEnd]
  );
  const onTouchEnd = useCallback(
    (e) => {
      if (!touchStart.current) return;
      if (
        !isValidSwipeTarget(
          e.target,
          !!actionsRef.current.down &&
            (!touchEnd.current || touchEnd.current > touchStart.current)
        )
      )
        return;
      e.stopPropagation();
      e.preventDefault();
      if (!touchEnd.current) {
        actionsRef.current.click && actionsRef.current.click();
        return;
      }
      const d = touchEnd.current - touchStart.current;
      touchStart.current = touchEnd.current = null;
      if (Math.abs(d) < 50) return;
      if (d < 0) {
        actionsRef.current.up && actionsRef.current.up();
      } else {
        actionsRef.current.down && actionsRef.current.down();
      }
    },
    [touchStart, touchEnd, actionsRef]
  );

  useEffect(() => {
    if (ref.current === null) return;
    const opts = { passive: false };
    ref.current.addEventListener("touchstart", onTouchStart, opts);
    ref.current.addEventListener("touchmove", onTouchMove, opts);
    ref.current.addEventListener("touchend", onTouchEnd, opts);
    return () => {
      if (!ref.current) return;
      ref.current.removeEventListener("touchstart", onTouchStart);
      ref.current.removeEventListener("touchmove", onTouchMove);
      ref.current.removeEventListener("touchend", onTouchEnd);
    };
  });

  return ref;
};

const useDisabledRootScrolling = () =>
  useEffect(() => {
    let added = false;
    if (!document.body.className.includes("no-scroll")) {
      document.body.className += " no-scroll";
      added = true;
    }
    return () => {
      if (added) {
        document.body.className = document.body.className.replace(
          " no-scroll",
          ""
        );
      }
    };
  });

const SmallScreenPopup = ({ openRef, children, className }) => {
  useDisabledRootScrolling(); // Don't ever use this on a truely scrollable page.
  const [open, setOpen] = useState(false);
  if (openRef) {
    openRef.current = () => setOpen(true);
  }

  const dismissElRef = useTouchHandlers({
    down: () => setOpen(false),
    click: () => setOpen(false),
  });

  const bodyElRef = useTouchHandlers({
    down: () => setOpen(false),
    up: () => setOpen(true),
  });

  return (
    <>
      <div
        ref={dismissElRef}
        className={`screen-bottom-drawer-root ${open ? "open" : "closed"}`}
      />
      <div
        ref={bodyElRef}
        className={`screen-bottom-drawer-body ${className} ${
          open ? "open" : "closed"
        }`}
      >
        <div
          style={{ display: "flex", width: "100%", justifyContent: "center" }}
        >
          <svg style={{ height: "20px", width: "15vw" }}>
            <line
              x1="5%"
              y1="15"
              x2="95%"
              y2="15"
              stroke="#777777"
              strokeLinecap="round"
              strokeWidth="6px"
            />
          </svg>
        </div>
        {children}
      </div>
    </>
  );
};
export default ResponsiveSidebar;
