{
  "data": {
    "2020": {
      "Global": {
        "ev_sales_share": 4.612,
        "ev_stock_share": 0.94
      }
    }
  }
}
