import { Component } from "react";
import { PropTypes as T } from "prop-types";
import { Group } from "@visx/group";
import { scaleLinear, scaleOrdinal } from "@visx/scale";
import { ParentSize } from "@visx/responsive";
import { LegendOrdinal } from "@visx/legend";
import { withTooltip, TooltipWithBounds, defaultStyles } from "@visx/tooltip";
import { BarStackHorizontal } from "@visx/shape";
import { environment } from "../../config";
import { localPoint } from "@visx/event";
import Figure from "../explore/Figure";

import profilesCsv from "bundle-text:/data/electricity_mix.csv";
import { parse } from "csv-parse/lib/sync";

const ensure = (obj, key, ...keys) =>
  keys.length > 0
    ? ensure(obj[key] || (obj[key] = {}), ...keys)
    : obj[key] || (obj[key] = {});

// Object of { [profile]: { [country]: { [source]: { gen, gen_pct } } } }, instead of [{ profile, country, source, gen, gen_pct }]
const profiles = {};
parse(profilesCsv, {
  columns: true,
  cast: true,
}).forEach(
  ({ profile, country, source, gen, gen_pct }) =>
    (ensure(profiles, profile, country)[source] = { gen, gen_pct })
);

/**
 * The ElecChart component
 */
class ElecChart extends Component {
  constructor(props) {
    super(props);
    this.renderChart = this.renderChart.bind(this);
    this.handleMouseOver = this.handleMouseOver.bind(this);
  }

  handleMouseOver(event, datum) {
    const coords = localPoint(event.target.ownerSVGElement, event);
    this.props.showTooltip({
      tooltipLeft: coords.x,
      tooltipTop: coords.y,
      tooltipData: datum,
    });
  }

  renderChart(parsedData, parentWidth, parentHeight) {
    // UNDONE need to shape the json here or before now for the barStack
    // undone -- need to order these.
    // const keys = Object.keys(parsedData[0]);
    const keys = [
      "Coal",
      "Oil",
      "Natural Gas",
      "Other",
      "Nuclear",
      "Hydro",
      "Tide",
      "Solar Thermal",
      "Solar PV",
      "Biofuels",
      "Waste",
      "Geothermal",
      "Wind",
    ];

    // Chart properties
    const height = parentHeight - 10; // figcaption
    const width = parentWidth;

    const barChunk = height / 20; // this is just a tuning option
    const margin = {
      top: barChunk * 2,
      bottom: 25,
      left: 0,
      right: 0,
    };
    const xMin = margin.left;
    const xMax = width - margin.right;
    const yMin = margin.top;
    const yMax = height - margin.bottom;

    const tooltipStyles = {
      ...defaultStyles,
      minWidth: 80,
    };

    const { tooltipData, tooltipLeft, tooltipTop, tooltipOpen, hideTooltip } =
      this.props;

    const greys = ["ff", "cc", "aa", "88", "55"].map((o) => `#2e344b${o}`); // desc order of opacity
    const blues = ["ff", "99"].map((o) => `#8baecb${o}`);
    const oranges = ["ff", "99"].map((o) => `#FFA500${o}`);
    const greens = ["55", "99", "dd", "ff"].map((o) => `#52c828${o}`); //asc order of opacity
    const colors = [...greys, ...blues, ...oranges, ...greens];

    // Define scales
    const xScale = scaleLinear({
      range: [xMin, xMax],
      domain: [0, 1],
    });

    const yScale = scaleLinear({
      range: [yMin, yMax],
      domain: [0, 2],
    });

    // Code name for the keys
    const colorScale = scaleOrdinal({
      domain: keys,
      range: colors,
    });

    // Full name for the keys
    const legendScale = scaleOrdinal({
      domain: keys.filter((k) => parsedData[0][k]),
      range: colors.filter((k, idx) => parsedData[0][keys[idx]]),
    });

    return (
      <figure className="popchart__chart">
        <div className="popchart__chart_item">
          <svg width={width} height={height + 10}>
            <Group>
              <BarStackHorizontal
                data={parsedData}
                yScale={yScale}
                xScale={xScale}
                y={() => 1}
                keys={keys}
                height={height}
                color={colorScale}
              >
                {(barStacks) =>
                  barStacks.map((barStack) =>
                    barStack.bars
                      .filter((bar) => bar.bar.data[bar.key] !== undefined)
                      .map((bar) => {
                        const data = bar.bar.data;
                        return (
                          <g key={`bsh-${barStack.key}-${data[0]}-}`}>
                            <rect
                              x={bar.x}
                              y={1}
                              width={bar.width}
                              height={height * 1.4}
                              fill={bar.color}
                              onMouseOver={(e) =>
                                this.handleMouseOver(e, {
                                  title: bar.key,
                                  color: bar.color,
                                  value: data[bar.key],
                                })
                              }
                              onMouseOut={hideTooltip}
                            />
                            {bar.width > 22 && (
                              <text
                                x={bar.x}
                                y={height}
                                dx={2}
                                className="annotation__bar-label"
                                style={{ fill: "white" }}
                              >
                                {(100 * bar.bar.data[bar.key]).toFixed(1)}%
                              </text>
                            )}
                          </g>
                        );
                      })
                  )
                }
              </BarStackHorizontal>
            </Group>
          </svg>
          <LegendOrdinal
            scale={legendScale}
            direction="row"
            labelMargin="0 6px 0 0"
            className="legend__twocol"
          />
          {tooltipOpen && (
            <TooltipWithBounds
              key={Math.random()}
              top={tooltipTop}
              left={tooltipLeft}
              style={tooltipStyles}
            >
              <div className={"tooltip-wrapper"}>
                <div style={{ color: tooltipData.color, marginBottom: "8px" }}>
                  <strong>{tooltipData.title}</strong>
                </div>
                <div>
                  <strong>{(100 * tooltipData.value).toFixed(1)}%</strong>
                </div>
              </div>
            </TooltipWithBounds>
          )}
        </div>
      </figure>
    );
  }

  render() {
    const { title, description, iso3 } = this.props;

    const countryData = profiles["2020"][iso3];
    if (!countryData) {
      return `No electricity mix data is available for country ${iso3}`;
    }

    //    { Coal: { gen:, gen_pct: }, ...}
    // Object.entries(...) -> [[ Coal, {gen: gen_pct: }], ...]

    const parsedData = [
      Object.fromEntries(
        Object.entries(countryData).map(([k, v]) => [k, v.gen_pct])
      ),
    ];

    // parsedData = [ { Coal: %, oil: % , ...}]

    return (
      <ParentSize>
        {({ width: parentWidth, height: parentHeight }) =>
          (parentWidth && (
            <Figure
              title={title}
              className="chart__pop"
              description={description}
            >
              {this.renderChart(
                parsedData,
                parentWidth,
                parentHeight - 60,
                iso3
              )}
            </Figure>
          )) ||
          ""
        }
      </ParentSize>
    );
  }
}

export const ElectricityMixStackedBar = withTooltip(ElecChart);

if (environment !== "production") {
  ElecChart.propTypes = {
    title: T.string,
    description: T.string,
    iso3: T.string,
  };
  ElectricityMixStackedBar.propTypes = {
    results: T.object,
    title: T.string,
    description: T.string,
    iso3: T.string,
  };
}
