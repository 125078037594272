import { useState, useEffect } from "react";
import countryBlurbs from "../../../html/countries/*.html";

export const countries = Object.keys(countryBlurbs);

const General = ({ country }) => {
  const url = countryBlurbs[country];
  const [html, setHtml] = useState(null);
  useEffect(() => {
    setHtml(null);
    if (url) {
      fetch(url)
        .then((resp) => resp.text())
        .then((html) => setHtml(html));
    }
  }, [url, setHtml]);

  return (
    <>
      <h1 id="general">General Information</h1>
      {/* SAFETY: We're only loading urls controlled by us, 
      so if responses aren't safe neither is this js file. */}
      {url ? (
        <div dangerouslySetInnerHTML={{ __html: html }} />
      ) : (
        "No further information is available"
      )}
    </>
  );
};

export default General;
