import React from "react";
import { Link } from "react-router-dom";

import App from "./App";

export default class UhOh extends React.Component {
  render() {
    return (
      <App pageTitle="Page not found">
        <article className="inpage inpage--single inpage--404">
          <header className="inpage__header">
            <div className="inpage__subheader">
              <div className="inpage__headline">
                <h1 className="inpage__title">Oh dear!</h1>
              </div>
            </div>
          </header>
          <div className="inpage__body">
            <div className="prose">
              <p>The page you requested was not found. Please check the url.</p>
              <p>
                <Link className="button-prose-cta" to="/" title="View">
                  <span>Visit the homepage</span>
                </Link>
              </p>
            </div>
          </div>
        </article>
      </App>
    );
  }
}
