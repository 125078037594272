/* eslint-disable no-process-env */
import defaultsDeep from "lodash.defaultsdeep";
/*
 * App configuration.
 *
 * Uses settings in config/production.js, with any properties set by
 * config/staging.js or config/local.js overriding them depending upon the
 * environment.
 *
 * This file should not be modified.  Instead, modify one of:
 *
 *  - config/production.js
 *      Production settings (base).
 *  - config/staging.js
 *      Overrides to production if ENV is staging.
 *  - config/local.js
 *      Overrides if local.js exists.
 *      This last file is gitignored, so you can safely change it without
 *      polluting the repo.
 */
import configurations from "./config/";

var config = configurations.defaults || {};

if (process.env.DS_ENV === "staging") {
  config = defaultsDeep(configurations.staging, config);
} else if (process.env.DS_ENV === "production") {
  config = defaultsDeep(configurations.production, config);
} else {
  config = defaultsDeep(configurations.local || {}, config);
}

config.mapboxAccessToken = process.env.MB_TOKEN || config.mapboxAccessToken;
config.basemapStyleLink =
  process.env.BASEMAP_STYLE_LINK || config.basemapStyleLink;
config.dataServiceUrl = process.env.API || config.dataServiceUrl;

export default config;
export const {
  appTitle,
  appShortTitle,
  appDescription,
  baseUrl,
  tileUrl,
  dataServiceUrl,
  basemapStyleLink,
  mapboxAccessToken,
  environment,
} = config;
