import { Component, Fragment } from "react";
import { PropTypes as T } from "prop-types";
import { environment } from "../../config";

import { countryForIso } from "../../iso3";

import shippingInformationCsv from "bundle-text:/data/shipping_information.csv";
import { parse } from "csv-parse/lib/sync";
const shippingInformation = parse(shippingInformationCsv, {
  columns: true,
  cast: true,
});

import {
  allSupplyChains,
  supplyChainParameters,
} from "../../json/lithium/chains";

import { connect } from "react-redux";

import { createUiDispatcher } from "../../redux/actions";

import Collapsible from "react-collapsible";
import { StageMarker } from "./StageMarker";
import { FakeRadio } from "../FakeRadio";

const _getter = (path) => {
  if (path.indexOf(".")) {
    const [a, b] = path.split(".");
    return (elt) => (elt[a] && elt[a][b]) || undefined;
  }
  return (elt) => elt[path];
};

const addAccessors = (elt) => {
  return {
    ...elt,
    getter: _getter(elt.path),
    fmt: elt.fmt == undefined ? (x) => countryForIso(x) : elt.fmt,
  };
};

const parameters = supplyChainParameters.map(addAccessors);
const parameterMap = Object.fromEntries(parameters.map((e) => [e.path, e]));

class CreateSupplyChain extends Component {
  constructor(props) {
    super(props);

    this.filterOptions = this.filterOptions.bind(this);

    this.onChange = this.onChange.bind(this);
    this.filterOptions = this.filterOptions.bind(this);
    this.notify = this.notify.bind(this);
    this.renderGroup = this.renderGroup.bind(this);
    this.renderOption = this.renderOption.bind(this);

    const filterState = this.props.filterState || {}; // from redux
    this.state = {
      filterState,
    };
    this.notify(filterState);
  }

  /* What options do we want to show:

    1) If the user hasn't selected anything, we let them choose anything.
    2) For the item that they're selecting, we let them choose anything that doesn't conflict with the other items. i.e., we need to filter on all of the options but that one.
    3) Default values shouldn't affect this, but there are strong dependencies between the extraction and processing countries.

  */
  filterOptions(field) {
    // takes a field, returns an object of value: name pairs of the permissible values for the field.

    const { filterState } = this.state;
    const { getter, fmt } = parameterMap[field];

    // Assumming one final shipping information dataset
    if (parameterMap[field].dataset == "shippingInformation") {
      return Object.fromEntries(
        shippingInformation.map((e) => [e.to, fmt(e.to)])
      );
    }

    // Reducing filterstate with a starting value of allSupplyChains.
    // if filterstate is empty, we're going to get allSupplyChains back
    const choices = Object.fromEntries(
      this.findChains(filterState, field)
        .map(getter)
        .map((e) => [e, fmt(e)])
    );
    return choices;
  }

  findChains(filterState, field = undefined) {
    // Reducer, takes all supply chains and filters for all of the fields that are specified, except the one we're looking at.
    const filterer = (prev, [k, v]) => {
      if (k == field) {
        return prev;
      }
      const params = parameterMap[k];
      if (params.dataset) {
        return prev;
      }
      return prev.filter((e) => params.getter(e) == v);
    };

    // Reducing filterstate with a starting value of allSupplyChains.
    // if filterstate is empty, we're going to get allSupplyChains back
    return Object.entries(filterState)
      .filter(([, v]) => v !== undefined)
      .reduce(filterer, allSupplyChains);
  }

  currentValue(field, filterState) {
    const { getter } = parameterMap[field];
    const { defaultChain } = this.props;
    return filterState[field] || getter(defaultChain);
  }

  defaultFilterState(filterState) {
    const { defaultChain } = this.props;
    return Object.fromEntries(
      parameters
        .filter((param) => filterState[param.path] == undefined)
        .map((param) => [param.path, param.getter(defaultChain)])
    );
  }

  // Returns the matching chains for the current chosen state merged with default chain
  currentChains(filterState) {
    const mergedFilterState = Object.fromEntries(
      parameters.map((e) => [e.path, this.currentValue(e.path, filterState)])
    );

    return this.findChains(mergedFilterState);
  }

  notify(filterState, field, flAdding) {
    const { handleSelectedChain = () => false, defaultChain } = this.props;

    var updatedParams = {};
    var currentChain = this.currentChains(filterState).pop();

    if (!currentChain && flAdding) {
      // this can happen if the one of the chosen options doesn't have any chains in common with the default chain.
      // e.g, the default chain is processing in China, and the user selects Argentina as the extraction, who's only processing is in the US.
      // In that case, we'll never be able to set this value.

      // this should always work, because we don't offer single click options that should fail against the currently chosen items.
      const allPossibleChains = this.findChains(filterState);

      // items that are set at the default
      const defaultParams = parameters.filter((e) => !filterState[e.path]);
      const freeOptions = defaultParams.map((e) => [
        e.path,
        Object.fromEntries(
          allPossibleChains
            .map((c) => e.getter(c))
            .filter((c) => c)
            .map((c) => [c, true])
        ),
      ]);
      updatedParams = Object.fromEntries(
        // look for anything that has options, and the defaultValue isn't in the list of options
        freeOptions
          .filter(
            ([path, options]) =>
              Object.keys(options).length &&
              !options[parameterMap[path].getter(defaultChain)]
          )
          //and return the first option
          .map(([path, options]) => [path, Object.keys(options).pop()])
      );
      currentChain = this.currentChains({
        ...filterState,
        ...updatedParams,
      }).pop();
    }

    if (!currentChain && !flAdding) {
      // this can happen if we're deleting an option where there's a connected item that is incompatible with the default chain
      // e.g, the default chain is processing in China, and the user deselects Argentina as the extraction,
      // US Processing locks it to staying with extraction in Argentia
      // In that case, we'll never be able to clear this value.

      // find all of the chains that are possible with the new set of defaults
      const allPossibleChains = this.findChains(
        this.defaultFilterState(filterState)
      );
      // items that aren't set at the default
      const nonDefaultParams = parameters.filter((e) => filterState[e.path]);
      const freeOptions = nonDefaultParams.map((e) => [
        e.path,
        Object.fromEntries(
          allPossibleChains
            .map((c) => e.getter(c))
            .filter((c) => c)
            .map((c) => [c, true])
        ),
      ]);
      updatedParams = Object.fromEntries(
        // look for anything that has options, and the filterState  isn't in the list of options
        freeOptions
          .filter(
            ([path, options]) =>
              Object.keys(options).length && !options[filterState[path]]
          )
          //and clear that item
          .map(([path]) => [path, undefined])
      );
      currentChain = this.currentChains({
        ...filterState,
        ...updatedParams,
      }).pop();
    }

    // UNDONE -- special casing the to -- need to handle better
    handleSelectedChain({
      ...currentChain,
      to: this.currentValue("to", filterState),
    });
    return updatedParams;
  }

  onChange(e) {
    this.onClick(e.target.name, e.target.value);
  }

  onClick(field, value) {
    const { filterState } = this.state;
    let newFilterState = { ...filterState };

    newFilterState[field] = value;

    newFilterState = {
      ...newFilterState,
      ...this.notify(newFilterState, field, !!value),
    };
    this.setState({ filterState: newFilterState });
    this.props.setState(newFilterState);
  }

  renderOption(field, value, label, selected, title) {
    const extraClass = (selected && "selected") || "";
    return (
      <a
        key={`opt-${value}`}
        className={`choose__link ${extraClass}`}
        onClick={() => this.onClick(field, selected ? undefined : value)}
        title={title}
      >
        <FakeRadio selected={selected} />
        {label}
      </a>
    );
  }

  renderGroup(param) {
    const { filterState } = this.state;
    const { title, path, fmt, stage, info = {} } = param;
    const options = this.filterOptions(path);
    const currentValue = this.currentValue(path, filterState);
    const fmtCurrentValue = currentValue ? fmt(currentValue) : "Choose...";
    const triggerHtml = (
      <Fragment>
        <div className="filter__header">
          {stage && <StageMarker stage={stage} className="header_img_marker" />}
          {title}
        </div>
        <div className="filters__currentval primary-color">
          <strong>{fmtCurrentValue}</strong>
        </div>
      </Fragment>
    );

    return (
      (
        <div key={`group-${path}`}>
          <Collapsible
            trigger={triggerHtml}
            open={false}
            openedClassName={"collapsible-border"}
          >
            <div className="filter__body">
              {Object.entries(options)
                .sort()
                .map(([k, v]) =>
                  this.renderOption(path, k, v, k === currentValue, info[v])
                )}
            </div>
          </Collapsible>
        </div>
      ) || ""
    );
  }

  render() {
    return (
      <div className="filter__panel">
        {parameters.map((param) => this.renderGroup(param))}
      </div>
    );
  }
}

if (environment !== "production") {
  CreateSupplyChain.propTypes = {
    defaultChain: T.object,
    handleSelectedChain: T.func,
  };
}

function mapStateToProps(state) {
  return {
    filterState: state.createSupplyChain,
  };
}

export default connect(
  mapStateToProps,
  createUiDispatcher("createSupplyChain")
)(CreateSupplyChain);
