import { Fragment } from "react";
import { PropTypes as T } from "prop-types";
import { Link } from "react-router-dom";
import { environment } from "../../../config";

import { countryForIso } from "../../../iso3";
import { CountryDropdown } from "../CountryDropdown";
import { CountryStackedBar } from "../../CountryYearlyStackedBar";
import { SingleBar } from "../../SingleBar";
import { CountryFlag } from "../../CountryFlag";

export const DataCountryFocusTab = ({
  iso3 = "ARG",
  onChange,
  data,
  units,
}) => {
  const countryMap = Object.fromEntries(
    data
      .filter((e) => e.lithium_resources || e.lithium_reserve)
      .map((e) => [e.country, countryForIso(e.country)])
  );
  const filteredData = data.filter((e) => e.country == iso3);

  let colors = {};
  colors[iso3] = "#fed46f";

  const production = data
    .map(({ country, lithium_production }) => [country, lithium_production])
    .reduce((d, [country, elem]) => {
      if (!d[country]) {
        d[country] = [];
      }
      d[country].push(elem);
      return d;
    }, {});
  const isProducer = (iso) =>
    (production[iso] || []).some((lithium) => lithium !== "" && lithium > 0);
  const activeIsProducer = isProducer(iso3);
  const millionsReserve = filteredData.some(
    ({ lithium_reserve }) => lithium_reserve > 1000
  );

  return (
    <div className="tab__focus scrollfixer">
      <div className="country_header">
        <CountryDropdown
          countryData={countryMap}
          selectedCountry={iso3}
          onChange={onChange}
          group={(iso) =>
            isProducer(iso) ? [0, "Producers"] : [1, "Non-Producers"]
          }
        />
        <CountryFlag iso3={iso3} />
      </div>
      {activeIsProducer ? (
        <Fragment>
          <div
            key="lithium_production"
            style={{ height: "20vh", width: "100%" }}
          >
            <CountryStackedBar
              title="Lithium Production"
              data={filteredData}
              selectedField="lithium_production"
              units={units}
              colors={colors}
              showLegend={false}
            />
          </div>
          <div key="lithium_reserves" style={{ height: "20vh", width: "100%" }}>
            <CountryStackedBar
              title="Lithium Reserves"
              data={
                millionsReserve
                  ? filteredData.map(({ lithium_reserve, ...point }) => ({
                      lithium_reserve: lithium_reserve / 1000,
                      ...point,
                    }))
                  : filteredData
              }
              selectedField="lithium_reserve"
              units={millionsReserve ? "million tons" : units}
              colors={colors}
              showLegend={false}
            />
          </div>
        </Fragment>
      ) : (
        <div>
          No production or reserve data is available. There is either no
          production or production may be occurring but at a small volume.
        </div>
      )}
      <Link to={`/countries/${iso3}`}>More Information</Link>
      <GlobalContext data={data} iso3={iso3} isProducer={activeIsProducer} />
    </div>
  );
};

const _sumField = (data, field) =>
  data
    .filter((e) => e[field])
    .map((e) => e[field])
    .reduce((a, b) => a + b);

const _formatter = Intl.NumberFormat("en-US");
const fmt = (x) => _formatter.format(x.toFixed(0));

export const GlobalContext = ({ iso3, data, isProducer }) => {
  const currentYear = Math.max(...data.map((e) => e.year));
  const currentYearData = data.filter((e) => e.year == currentYear);
  const {
    lithium_reserve = 0,
    lithium_resources = 0,
    lithium_production = 0,
    lithium_type = "",
  } = currentYearData.filter((e) => e.country == iso3)[0] || {};

  const totalReserves = _sumField(currentYearData, "lithium_reserve");
  const totalProduction = _sumField(currentYearData, "lithium_production");

  return (
    <div style={{ marginTop: "1rem" }}>
      <h2>Global Context ({currentYear})</h2>
      {isProducer && (
        <Fragment>
          <h3>{countryForIso(iso3)} Percentage of Global Production:</h3>
          <SingleBar
            value_frac={lithium_production / totalProduction}
            places={2}
          />
          <h3>{countryForIso(iso3)} Percentage of Global Reserves:</h3>
          <SingleBar value_frac={lithium_reserve / totalReserves} places={2} />

          <h3>{countryForIso(iso3)} Production as a percentage of Reserves:</h3>
          <SingleBar
            value_frac={lithium_production / lithium_reserve}
            places={2}
          />
        </Fragment>
      )}

      <p style={{ marginTop: "1rem", fontSize: "1.125rem" }}>
        {lithium_type ? (
          <Fragment>
            Lithium Type: {lithium_type}
            <br />
          </Fragment>
        ) : (
          ""
        )}
        {isProducer && (
          <Fragment>
            Production: {fmt(lithium_production || 0)} (thousand tons)
            <br />
            Reserves: {fmt(lithium_reserve || 0)} (thousand tons)
            <br />
          </Fragment>
        )}
        Resources: {fmt(lithium_resources || 0)} (thousand tons)
      </p>
    </div>
  );
};

if (environment !== "production") {
  DataCountryFocusTab.propTypes = {
    iso3: T.string,
    onChange: T.func,
  };
}
