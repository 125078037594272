import { PropTypes as T } from "prop-types";
import { environment } from "../../config";

import { InfoButton } from "../InfoButton";

function approx(a, b) {
  return Math.abs(a - b) < a / 100;
}

export const TextResult = (props) => {
  const {
    title,
    value,
    units,
    valueClass = "",
    constraint,
    infoNote = "",
    sigDigits = 3,
    lowest,
    lowTitle,
    contextTitle = "",
    contextValue,
  } = props;
  let { constraintWording = "" } = props;

  const _formatter = Intl.NumberFormat("en-US", {
    maximumSignificantDigits: sigDigits,
  });
  // prevent crazy underflow near 0
  const fmt = (x) => _formatter.format(x.toFixed(sigDigits + 2));

  const isConstraint = constraint && approx(value, constraint);
  const constraintClass = (isConstraint && "textresults__constrained") || "";

  if (isConstraint) {
    constraintWording = `This value is limited by the input of ${constraint} ${units}`;
  } else if (constraint) {
    constraintWording = `This value range up to ${constraint}${units}`;
  }

  return (
    <div className="textresults__container">
      <InfoButton description={infoNote} />
      <div>
        <div className="textresults__title">{title}</div>
        <div
          className={"textresults__value " + valueClass}
          title={constraintWording}
        >
          <div className={"textresults__value_number " + constraintClass}>
            {Number(value) ? fmt(value) : value}{" "}
            <span className={"textresults__value_units"}>{units}</span>
          </div>
        </div>
      </div>
      {contextValue !== undefined ? (
        <div className="textresults__context" title={contextTitle}>
          <div className="textresults__value_number">
            {Number(contextValue) ? fmt(contextValue) : contextValue}{" "}
            <span className="textresults__value_units">{units}</span>
          </div>
        </div>
      ) : (
        ""
      )}
      {lowest ? (
        <div className="textresults__footer">
          <div>
            <small>
              Lowest: {Number(lowest) ? fmt(lowest) : lowest} {units}
            </small>
          </div>
          <div>
            <small>{lowTitle}</small>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

if (environment !== "production") {
  TextResult.propTypes = {
    title: T.string,
    value: T.oneOfType([T.number, T.string]),
    units: T.oneOfType([T.object, T.string]),
    valueClass: T.string,
    constraint: T.number,
    infoNote: T.string,
    contextTitle: T.string,
  };
}

export default TextResult;
