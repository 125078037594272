import { PropTypes as T } from "prop-types";
import { Link } from "react-router-dom";
import { environment } from "../../../config";

import { ElectricityMixStackedBar } from "../ElectricityMixStackedBar";
import { MarketShare } from "./MarketShare";
import { CountryDropdown } from "../CountryDropdown";
import { CountryFlag } from "../../CountryFlag";

import { data as summary } from "../../../json/lithium/country_focus.json";

const CountryFocusTab = ({ iso3, onChange }) => {
  return (
    <div className="tab__sidebar tab__focus scrollfixer">
      <div className="country_header">
        <CountryDropdown
          countryData={summary}
          selectedCountry={iso3}
          onChange={onChange}
        />
        <CountryFlag iso3={iso3} />
      </div>
      {summary[iso3]}
      <br />
      <Link to={`/countries/${iso3}`}>More Information</Link>
      <hr />
      <h3>Market Share:</h3>
      <MarketShare iso3={iso3} />
      <hr />
      <div style={{ height: "8em" }}>
        <ElectricityMixStackedBar
          title="Electricity Mix"
          description="A country’s electricity mix plays a role in the emissions profile for all stages of the lithium supply chain."
          iso3={iso3}
        />
      </div>
    </div>
  );
};

if (environment !== "production") {
  CountryFocusTab.propTypes = {
    iso3: T.string,
    onChange: T.func,
  };
}

export default CountryFocusTab;
