import { Component, Fragment, useRef } from "react";

import App from "./App";

import { ChloroplethMap } from "../components/map/ChloroplethMap";
import { DataSnapshotTabs } from "../components/DataSnapshotTabs";

// Lithium
import { DataOverviewTab as LithiumOverviewTab } from "../components/chains/lithium/DataOverviewTab";
import { DataCountryFocusTab as LithiumCountryFocusTab } from "../components/chains/lithium/DataCountryFocusTab";
// EV
import { DataOverviewTab as EVOverviewTab } from "../components/chains/ev/DataOverviewTab";
import { DataCountryFocusTab as EVCountryFocusTab } from "../components/chains/ev/DataCountryFocusTab";
// Graphite
import { DataOverviewTab as GraphiteOverviewTab } from "../components/chains/graphite/DataOverviewTab";
import { DataCountryFocusTab as GraphiteCountryFocusTab } from "../components/chains/graphite/DataCountryFocusTab";

import { FakeRadio } from "../components/FakeRadio";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Dropdown from "../components/Dropdown";
import ResponsiveSidebar from "../components/ResponsiveSidebar";

import { bounds } from "../layers";
import { countryForIso } from "../iso3";

import { interpolateRgb } from "d3-interpolate";
import {
  schemeSet1,
  schemeSet2,
  schemeSet3,
  schemeTableau10,
  schemePastel1,
  schemePastel2,
} from "d3-scale-chromatic";
import { scaleLinear } from "@visx/scale";

import lithiumSupplyDemandCsv from "bundle-text:/data/lithium_supply_demand.csv";
import graphiteSupplyDemandCsv from "bundle-text:/data/graphite_supply_demand.csv";
import evSupplyDemandCsv from "bundle-text:/data/ev_supply_demand.csv";
import { parse as csvParse } from "csv-parse/lib/sync";
const parse = (csv) =>
  csvParse(csv, {
    columns: true,
    cast: true,
  });

const data = {
  lithium: parse(lithiumSupplyDemandCsv),
  graphite: parse(graphiteSupplyDemandCsv),
  ev: parse(evSupplyDemandCsv),
};

const _line = (label, value, unit) => {
  if (!value) {
    return "";
  }
  return (
    <div className="map_detail__value">
      {label}: {value} {unit}
    </div>
  );
};

const PageDropdown = ({ pages }) => {
  const navigate = useNavigate();
  const dropdownRef = useRef();
  const { subject } = useParams();
  const setSubject = (subject) => () => {
    navigate(`/data-snapshot/${subject}`);
    dropdownRef.current.close();
  };

  return (
    <Dropdown
      ref={dropdownRef}
      className="dropdown data-snapshot-subject-dropdown"
      triggerClassName="data-snapshot-subject-dropdown-trigger dropdown__active"
      triggerActiveClassName="button--active"
      triggerText={
        Object.values(pages)
          .flat(1)
          .find(({ url }) => url == subject).name
      }
      direction="down"
      alignment="left"
    >
      <ul className="drop__menu">
        {Object.entries(pages).map(([category, subjects]) => (
          <>
            <li className="drop__menu-item disabled" key={category}>
              {category}
            </li>
            {subjects.map(({ name, url }) => (
              <li
                key={url}
                className="drop__menu-item"
                onClick={setSubject(url)}
              >
                {name}
              </li>
            ))}
          </>
        ))}
      </ul>
    </Dropdown>
  );
};

const LithiumHoverDetail = ({ data }) => {
  const {
    country,
    year,
    lithium_reserve = 0,
    lithium_production = 0,
    lithium_resources = 0,
    lithium_type,
  } = data;
  return (
    <div className="map_detail__container">
      <div className="map_detail__title">
        {countryForIso(country)} ({year})
      </div>
      {_line("Lithium Type", lithium_type, "")}
      {_line("Resources", lithium_resources, "thousand tons")}
      {_line("Reserve", lithium_reserve, "thousand tons")}
      {_line("Production", lithium_production, "thousand tons")}
    </div>
  );
};

const GraphiteHoverDetail = ({
  data: {
    country,
    year,
    graphite_reserve,
    graphite_production,
    graphite_resources,
  },
}) => (
  <div className="map_detail__container">
    <div className="map_detail__title">
      {countryForIso(country)} ({year})
    </div>
    {_line("Resources", graphite_resources, "thousand tons")}
    {_line("Reserve", graphite_reserve, "thousand tons")}
    {_line("Production", graphite_production, "thousand tons")}
  </div>
);

const EVHoverDetail = ({ data }) => {
  const { country, year, ev_demand = 0 } = data;
  return (
    <div className="map_detail__container">
      <div className="map_detail__title">
        {countryForIso(country)} ({year}) EV Sales
      </div>
      {ev_demand > 1000
        ? `${(ev_demand / 1000).toFixed(1)} million vehicles`
        : `${ev_demand.toFixed(1)} thousand vehicles`}
    </div>
  );
};

const mapDataFilter = (data, field, valueFunc = (x) => x) =>
  Object.fromEntries(
    data
      .filter((e) => e[field])
      .sort((a, b) => a.year - b.year)
      .map((e) => [e.country, valueFunc(e[field])])
  );

// Ok, so the order is producers, reserves, resources, then evdemand
let _countries = Object.fromEntries(
  data.lithium
    .filter((e) => e.lithium_production)
    .map((e) => e.country)
    .map((e) => [e, e])
);
let countries = Object.keys(_countries);

// mess with the colors
countries.unshift(countries.pop());
countries.unshift(countries.pop());

const _addCountry = (country) => {
  if (!_countries[country]) {
    _countries[country] = country;
    countries.push(country);
  }
};

[
  [data.lithium, "lithium_production"],
  [data.lithium, "lithium_reserve"],
  [data.lithium, "lithium_resources"],
  [data.ev, "ev_demand"],
  [data.ev, "ev_sales"],
  [data.ev, "ev_stock"],
  [data.graphite, "graphite_production"],
  [data.graphite, "graphite_reserve"],
  [data.graphite, "graphite_resources"],
].forEach(([data, field]) =>
  data.filter((e) => e[field]).forEach((e) => _addCountry(e.country))
);

const colorRamp = [
  ...schemeTableau10,
  ...schemeSet1,
  ...schemeSet2,
  ...schemeSet3,
  ...schemePastel1,
  ...schemePastel2,
];

const colors = Object.fromEntries(
  countries.map((e, idx) => [e, colorRamp[idx]])
);

const colorRange = (baseColor, baseDensity = 0.3) => [
  interpolateRgb.gamma(2.2)("white", baseColor)(baseDensity),
  baseColor,
];

const posterizeLithium = (v) => {
  if (v < 1000) {
    return 1;
  }
  if (v < 5000) {
    return 2;
  }
  return 3;
};

const posterizeGraphite = (v) => {
  if (v < 100) {
    return 1;
  }
  if (v < 500) {
    return 2;
  }
  return 3;
};

const posterizeEVs = (v) => {
  if (v < 50) {
    return 1;
  }
  if (v < 200) {
    return 2;
  }
  return 3;
};

const maxYear = (data) => Math.max(...data.map(({ year }) => year));

class DataSnapshot extends Component {
  constructor(props) {
    super(props);

    this.handleSelectedCountry = this.handleSelectedCountry.bind(this);

    this.maxYears = {
      lithium: maxYear(data.lithium),
      ev: maxYear(data.ev),
      graphite: maxYear(data.graphite),
    };

    this.pages = {
      lithium: {
        section: "Mineral",
        title: "Lithium",
        data: data.lithium,
        mapData: mapDataFilter(
          data.lithium,
          "lithium_resources",
          posterizeLithium
        ),
        units: " thousand tons",
        graph: "lithium_resources",
        OverviewTab: LithiumOverviewTab,
        CountryFocusTab: LithiumCountryFocusTab,
        colorRange: colorRange("#76b7b2"),
        description:
          "Resources are deposits that are potentially valuable, and could lead to economic extraction and are as reported by USGS.",
        labels: ["< 1 Mt", "> 1 Mt, < 5 Mt", "> 5Mt"],
        legendTitle: `${this.maxYears.lithium} Lithium Reserves`,
        hoverData: Object.fromEntries(
          data.lithium
            .filter((e) => e.year == this.maxYears.lithium)
            .map((e) => [e.country, e])
        ),
        HoverDetail: LithiumHoverDetail,
      },
      graphite: {
        section: "Mineral",
        title: "Graphite",
        data: data.graphite,
        mapData: mapDataFilter(
          data.graphite,
          "graphite_production",
          posterizeGraphite
        ),
        units: " thousand tons",
        graph: "graphite_production",
        OverviewTab: GraphiteOverviewTab,
        CountryFocusTab: GraphiteCountryFocusTab,
        colorRange: colorRange("#333333"),
        description:
          "Resources are deposits that are potentially valuable, and could lead to economic extraction and are as reported by USGS.", // TODO: graphite (unused)
        labels: ["< 100kt", "> 100kt, < 500kt", "> 500kt"], // TODO: get numbers (posterizeGraphite must change with this)
        legendTitle: `${this.maxYears.graphite} Graphite Production`,
        hoverData: Object.fromEntries(
          data.graphite
            .filter((e) => e.year == this.maxYears.graphite)
            .map((e) => [e.country, e])
        ),
        HoverDetail: GraphiteHoverDetail,
      },
      "ev-demand": {
        section: "Technology",
        title: "Electric Vehicle Purchases",
        data: data.ev,
        mapData: mapDataFilter(data.ev, "ev_demand", posterizeEVs),
        units: " thousand vehicles",
        graph: "ev_demand",
        OverviewTab: EVOverviewTab,
        CountryFocusTab: EVCountryFocusTab,
        colorRange: colorRange("#00a66b"),
        description:
          "This graph represents the reported purchases of all electric vehicles (EV) by country as reported by IEA; it includes battery EVs and plug-in hybrids",
        labels: ["< 50 000", "> 50 000, < 200 000", "> 200 000"],
        legendTitle: `${this.maxYears.ev} Electric Vehicle Purchases`,
        hoverData: Object.fromEntries(
          data.ev
            .filter((e) => e.year == this.maxYears.ev)
            .map((e) => [e.country, e])
        ),
        HoverDetail: EVHoverDetail,
      },
    };

    this.state = {
      details: undefined,
      selectedCountry: undefined,
      //      units: (<span>tC0<sub>2</sub>e</span>),
    };

    this.onClick = this.onClick.bind(this);
  }

  handleSelectedCountry(iso3) {
    const { selectedCountry } = this.state;
    if (iso3 !== selectedCountry) {
      this.setState({ selectedCountry: iso3 });
    }
  }

  onClick(location, features) {
    const layer = features.filter((e) => e.layer.id == "chloropleth").pop();
    if (!layer) {
      const { details } = this.state;
      if (details) {
        this.setState({ details: undefined });
      }
      return;
    }
    const { subject } = this.props;
    const { HoverDetail, hoverData } = this.pages[subject];
    const iso3 = layer.properties.ISO_A3;
    this.handleSelectedCountry(iso3);
    this.setState({
      details: hoverData[iso3] && <HoverDetail data={hoverData[iso3]} />,
    });
  }

  render() {
    const { details, selectedCountry } = this.state;
    const { subject } = this.props;
    const {
      title,
      data,
      mapData,
      labels,
      legendTitle,
      units,
      graph,
      colorRange,
      extraSeries,
      extraSeriesLabel,
      description,
      OverviewTab,
      CountryFocusTab,
    } = this.pages[subject];
    const colorMap = scaleLinear({
      domain: [0, Math.max(...Object.values(mapData))],
      range: colorRange,
    });
    const legend = labels.length && (
      <div className="data-snapshot-legend" style={{}}>
        <div style={{ marginLeft: ".5em" }}>{legendTitle}</div>
        {labels.map((label, i) => (
          <div
            key={i}
            style={{
              display: "inline-block",
              paddingLeft: "0.5em",
            }}
          >
            <div
              style={{
                backgroundColor: "white",
                width: "3em",
                display: "inline-block",
              }}
            >
              <div
                style={{
                  color: colorMap(i + 1),
                  backgroundColor: colorMap(i + 1),
                  opacity: ".75",
                  width: "3em",
                  height: "2em",
                  display: "inline-block",
                }}
              >
                {i}
              </div>
            </div>
            <span
              style={{
                paddingLeft: "0.5em",
              }}
            >
              {label}
            </span>
          </div>
        ))}
      </div>
    );

    return (
      <App pageTitle="Data Snapshot" className="data-snapshot-root">
        <section className="inpage inpage--single inpage--right-panel inpage--map inpage__datasnapshot">
          <div className="inpage__body">
            <header className="body__header route-tabs-header">
              <div className="route-header">
                <h4>Data Snapshot</h4>
              </div>
              <div className="route__tab" style={{ paddingTop: "1rem" }}>
                <PageDropdown
                  pages={Object.fromEntries(
                    [
                      ...new Set(
                        Object.values(this.pages).map(({ section }) => section)
                      ),
                    ].map((section) => [
                      section,
                      Object.entries(this.pages)
                        .filter(([, page]) => page.section === section)
                        .map(([path, page]) => ({
                          url: path,
                          name: page.title,
                        })),
                    ])
                  )}
                />
              </div>
            </header>

            <ChloroplethMap
              data={mapData}
              initialBounds={bounds}
              details={details}
              colorRange={colorRange}
              handleSelectedLocation={this.onClick}
              legend={legend}
            />
          </div>
          <ResponsiveSidebar className="top-supply-tabs tab__sidebar">
            <DataSnapshotTabs
              title={title}
              data={data}
              selectedField={graph}
              units={units}
              colors={colors}
              extraSeries={extraSeries}
              extraSeriesLabel={extraSeriesLabel}
              selectedCountry={selectedCountry}
              handleSelectedCountry={this.handleSelectedCountry}
              description={description}
              OverviewTab={OverviewTab}
              CountryFocusTab={CountryFocusTab}
            />
          </ResponsiveSidebar>
        </section>
      </App>
    );
  }
}

export default DataSnapshot;
