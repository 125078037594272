import { PropTypes as T } from "prop-types";
import { environment } from "../../config";

import { TwoUpEmissionsStackedBar } from "./TwoUpEmissionsStackedBar";
import { chainWithEmissionProfile } from "../../json/lithium/chains";

export const DecarbonizationCharts = ({
  baseChain,
  decarbonizedChain,
  className = "",
}) => {
  if (!baseChain.metrics) {
    return null;
  }
  const _baseChain = chainWithEmissionProfile(
    baseChain,
    "Current",
    "Electricity"
  );

  return (
    <div className={`decarbonization__charts ${className}`}>
      <TwoUpEmissionsStackedBar
        key="2up-elec"
        title={
          <span>
            Electricity Emissions (tCO<sub>2</sub>e)
          </span>
        }
        baseChain={_baseChain}
        decarbonizedChain={decarbonizedChain}
        selector="elec"
        flLegend={false}
        decarbonizedProfile={`${decarbonizedChain.metrics.elec_profile} Profile`}
      />
      <hr />
      <TwoUpEmissionsStackedBar
        key="2up-heat"
        title={
          <span>
            Heat Emissions (tCO<sub>2</sub>e)
          </span>
        }
        baseChain={_baseChain}
        decarbonizedChain={decarbonizedChain}
        selector="heat"
        flLegend={false}
        decarbonizedProfile={`${decarbonizedChain.metrics.heat_profile} Profile`}
      />
      <hr />
      <TwoUpEmissionsStackedBar
        key="2up-total"
        title={
          <span>
            Total Emissions (tCO<sub>2</sub>e)
          </span>
        }
        baseChain={_baseChain}
        decarbonizedChain={decarbonizedChain}
        selector=""
      />
    </div>
  );
};

if (environment !== "production") {
  DecarbonizationCharts.propTypes = {
    baseChain: T.object,
    decarbonizedChain: T.object,
  };
}
