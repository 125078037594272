import { combineReducers } from "redux";
import { baseAPIReducer } from "./utils";

import { actions, ui_actions } from "./actions";

function createApiReducer(name) {
  const initialState = {
    fetched: false,
    fetching: false,
    data: {},
    error: null,
  };
  return function reducer(state = initialState, action) {
    return baseAPIReducer(state, action, name);
  };
}

function createApiReducers(l) {
  return Object.fromEntries(
    l.map((e) => [e, createApiReducer(e.toUpperCase())])
  );
}

function createUiReducer(name) {
  const initialState = {};
  const SET = `SET_${name.toUpperCase()}`;
  const INVALIDATE = `INVALIDATE_${name.toUpperCase()}`;

  return function uiReducer(state = initialState, action) {
    switch (action.type) {
      case SET:
        state = action.data;
        return state;
      case INVALIDATE:
        return initialState;
      default:
        return state;
    }
  };
}

function createUiReducers(l) {
  return Object.fromEntries(l.map((e) => [e, createUiReducer(e)]));
}

export default combineReducers({
  ...createApiReducers(actions),
  ...createUiReducers(ui_actions),
});
