"use strict";

export default {
  environment: "production",
  siteUrl: "https://quality.staging.derilinx.com",
  tileUrl: "https://app-layers.wbwaterdata.org",
  dataServiceUrl: "https://quality-api.staging.derilinx.com",
  basemapStyleLink: "mapbox://styles/derilinx/ckvc8g9yj0nm714nzuopbqz7s",
  mapboxAccessToken:
    "pk.eyJ1IjoiZGVyaWxpbngiLCJhIjoiY2szeTlzbWo2MDV6eDNlcDMxM3dzZXBieiJ9.zPf1iiFilYYwyx6ETNj_8w",
};
