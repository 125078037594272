import { Fragment } from "react";
import { environment } from "../../../config";

import {
  CountryStackedBar,
  CountryLegend,
} from "../../CountryYearlyStackedBar";
import { SingleBar } from "../../SingleBar";

import { data as globalData } from "../../../json/lithium/ev_global.json";

export const DataOverviewTab = ({
  title,
  data,
  selectedField,
  description,
  units,
  colors,
}) => {
  return (
    <Fragment>
      <div key={selectedField} style={{ height: "30vh", width: "100%" }}>
        <CountryStackedBar
          title={title}
          data={data}
          selectedField={selectedField}
          units={units}
          colors={colors}
          showLegend={false}
        />
      </div>
      {description}
      <CountryLegend
        colors={colors}
        data={data}
        selectedField={selectedField}
      />
      <GlobalContext data={globalData} />
    </Fragment>
  );
};

// UNDONE what global context to we want here?

export const GlobalContext = ({ data }) => {
  const currentYear = Math.max(...Object.keys(data));
  const currentYearData = data[currentYear];

  const { ev_sales_share = 0, ev_stock_share = 0 } = currentYearData["Global"];

  return (
    <div style={{ marginTop: "1rem" }}>
      <h2>Global Context ({currentYear})</h2>
      <h3>EV Percentage of Vehicle Sales:</h3>
      <SingleBar value_pct={ev_sales_share} places={2} />
      <h3>EV Percentage of Vehicle Stock:</h3>
      <SingleBar value_pct={ev_stock_share} places={2} />
    </div>
  );
};

if (environment !== "production") {
  DataOverviewTab.propTypes = {};
}
