import { fetchDispatchFactory } from "./utils";
import static_pages from "../../html/*";

export const actions = ["staticPage"];

export const ui_actions = ["createSupplyChain", "decarbonizationOptions"];

function _recieve(type) {
  return function receive(data, error = null) {
    return {
      type: type,
      data,
      error,
      receivedAt: Date.now(),
    };
  };
}

function _request(type) {
  return function request() {
    return { type: type };
  };
}

// * Actions for StaticPage

export function fetchStaticPage(request) {
  return fetchDispatchFactory({
    statePath: ["staticPage"],
    url: static_pages[request],
    type: "md",
    options: {
      method: "GET",
    },
    requestFn: _request("REQUEST_STATICPAGE"),
    receiveFn: _recieve("RECEIVE_STATICPAGE"),
  });
}

/*
 * Stash ui data in the redux store
 */

export const createUiDispatcher = (name) => {
  const uname = name.toUpperCase();

  const set = (data) => {
    return {
      type: `SET_${uname}`,
      data,
    };
  };
  const invalidate = () => {
    return {
      type: `INVALIDATE_${uname}`,
    };
  };

  return (dispatch) => {
    return {
      setState: (data) => dispatch(set(data)),
      invalidateState: () => dispatch(invalidate()),
    };
  };
};
