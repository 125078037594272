import elecFactorsCsv from "bundle-text:/data/electricity_emissions_factors.csv";
import heatFactorsCsv from "bundle-text:/data/heat_emission_factors.csv";
import { data as currentHeatFactors } from "./current_heat_emissions_factors.json"; // Manual generation - no csv
import vehicleFactorsCsv from "bundle-text:/data/vehicle_assembly_factors.csv";
import chainsCsv from "bundle-text:/data/revised_supply_chain.csv";
import finalShippingCsv from "bundle-text:/data/shipping_information.csv";
import { parse as csvParse } from "csv-parse/lib/sync";

const parse = (csv, options = {}) =>
  csvParse(csv, { columns: true, cast: true, ...options });

const elecFactors = Object.fromEntries(
  parse(elecFactorsCsv).map(({ country, ...rest }) => [country, rest])
);

const heatFactors = Object.fromEntries(
  parse(heatFactorsCsv, { columns: false })
);

const vehicleFactors = parse(vehicleFactorsCsv)[0];

const chains = parse(chainsCsv, {
  // Handle dotted names like `metrics.score` -> { metrics: { score: ... } }
  // Only handles a single dot
  on_record(record) {
    const r = {};
    Object.entries(record).forEach(([k, v]) => {
      let [root, subkey] = k.split(".");
      r[root] = subkey ? { ...(r[root] || {}), [subkey]: v } : v;
    });
    return r;
  },
});

const finalShipping = parse(finalShippingCsv);

const stages = [
  "extraction",
  "processing",
  "battery_assembly",
  "vehicle_assembly",
];

const currentHeatProfileForChain = (chain, heat_profile) => {
  // UNDONE I shouldn't need "Electricity" in this, it should be removed from elsewhere.
  if (heat_profile == "Current" || heat_profile == "Electricity") {
    const assemblyFactor = currentHeatFactors
      .filter((e) => e.assembly == "*")
      .pop().assembly_heat_factor;
    let candidates = currentHeatFactors.filter(
      (e) => e.extraction == chain.chain.extraction
    );
    if (candidates.length > 1) {
      candidates = candidates.filter(
        (e) => e.battery == chain.metrics.battery_type
      );
    }
    if (candidates.length == 0) {
      candidates = [currentHeatFactors[0]]; // UNDONE -- this is wrong for us/argentina
    }
    if (candidates.length == 1) {
      return {
        extraction: candidates[0].extraction_heat_factor,
        processing: candidates[0].processing_heat_factor,
        vehicle_assembly: assemblyFactor,
      };
    }
  }
  return {
    extraction: heatFactors[heat_profile]["2030"],
    processing: heatFactors[heat_profile]["2030"],
    vehicle_assembly: heatFactors[heat_profile]["2030"],
  };
};

export const chainWithEmissionProfile = (
  chain,
  elec_profile,
  heat_profile,
  shippingDest
) => {
  if (!chain || !chain.chain) {
    return {};
  }
  let emissions = {};
  const heat_factor = currentHeatProfileForChain(chain, heat_profile);

  stages.forEach((stage) => {
    // elec factor depends on the country of the stage and the profile
    const elec_factor = elecFactors[chain.chain[stage]][elec_profile];

    emissions[`${stage}_emissions_heat`] =
      (heat_factor[stage] || 0) * (chain.energy[`${stage}_heat`] || 0);

    emissions[`${stage}_emissions_elec`] =
      elec_factor * (chain.energy[`${stage}_elec`] || 0);

    // UNDONE -- nested would be better here. then just omul /oadd
    emissions[`${stage}_emissions`] =
      (chain.energy[`${stage}_other`] || 0) +
      emissions[`${stage}_emissions_heat`] +
      emissions[`${stage}_emissions_elec`];
  });

  // Special case for vehicle assembly, since it's constant factors and therefore
  // factored out of the json so that we just keep one copy of it.
  const elecFactor = elecFactors[chain.chain.vehicle_assembly][elec_profile];

  emissions.vehicle_assembly_emissions_elec =
    vehicleFactors.vehicle_assembly_elec * elecFactor;
  emissions.vehicle_assembly_emissions_heat =
    vehicleFactors.vehicle_assembly_heat * heat_factor.vehicle_assembly;
  emissions.vehicle_assembly_emissions =
    emissions.vehicle_assembly_emissions_elec +
    emissions.vehicle_assembly_emissions_heat;

  if (shippingDest) {
    emissions.final_shipping =
      finalShipping
        .filter(
          (e) => e.to == shippingDest && e.from == chain.chain.vehicle_assembly
        )
        .map((e) => e.emissions)
        .pop() || 0;
    emissions.shipping_emissions =
      chain.metrics.shipping_to_processing +
      chain.metrics.shipping_to_battery +
      chain.metrics.shipping_to_vehicle +
      emissions.final_shipping;
  }

  emissions.total_emissions = Object.entries(emissions)
    .filter(([k]) => k.indexOf("emissions_") === -1)
    .filter(([k]) => k.indexOf("final_shipping") === -1)
    .map(([, v]) => v)
    .reduce((a, b) => a + b);

  emissions.heat_profile = heat_profile;
  emissions.elec_profile = elec_profile;

  return {
    ...chain,
    chain: {
      ...chain.chain,
      shipping: shippingDest,
    },
    metrics: { ...chain.metrics, ...emissions },
  };
};

// undone -- improve making the chains a map of id:chain, then values would be the list.
export const topSupplyChains = [
  {
    id: 172,
    title: "The Road Most Traveled",
    tab_name: "Top Route: AUS to CHN",
  },
  {
    id: 178,
    title: "Australian Rock Traveling NW",
    tab_name: "AUS to DEU",
  },
  {
    id: 44,
    title: "Chilean Brine Traveling Further NW",
    tab_name: "CHL to KOR",
  },
  {
    id: 251,
    title: "The Australian Trans-Pacific Route",
    tab_name: "AUS to USA",
  },
  {
    id: 384,
    title: "Chilean Brine Traveling West",
    tab_name: "CHL to JPN",
  },
  {
    id: 213,
    title: "Australian Rock Traveling North",
    tab_name: "AUS to KOR",
  },
].map((e) => {
  // not the most efficient, but happens once per load
  const _chain = chains.filter((c) => c.id == e.id).pop();
  return { ...chainWithEmissionProfile(_chain, "Current", "Current"), ...e };
});

export const allSupplyChains = chains;

export { elecFactors, heatFactors };

// fmt if it's not an iso country.
// info for title hovers
export const supplyChainParameters = [
  {
    path: "metrics.battery_type",
    title: "Battery Type",
    fmt: (x) => x,
    info: {
      LFP: "LFP batteries use lithium iron phosphate as the cathode material.",
      LMO: "LMO batteries use lithium and manganese oxide as the cathode material, with lithium carbonate generally used as the precursor. Most LMO batteries are used in conjunction with NMC to improve energy density and prolong its lifetime. ",
      NCA: "NCA batteries use lithium, nickel, cobalt and aluminum oxide as the cathode material, generally from lithium hydroxide. ",
      NMC111:
        "NMC batteries use lithium nickel, manganese, cobalt oxide as the cathode material, with lithium carbonate preferred as the precursor. NMC11 batteries using equal parts of nickel, manganese and cobalt. ",
      NMC622:
        "NMC622 batteries using 6-parts nickel to 2-parts manganese and cobalt.",
      NMC811:
        "NMC811 batteries using 8-parts nickel to 1-part manganese and cobalt.",
    },
  },
  {
    path: "chain.extraction",
    title: "Lithium Extraction",
    stage: "extraction",
  },
  {
    path: "chain.processing",
    title: "Lithium Processing",
    stage: "processing",
  },
  {
    path: "chain.battery_assembly",
    title: "Battery Manufacturing",
    stage: "battery_assembly",
  },
  {
    path: "chain.vehicle_assembly",
    title: "Vehicle Assembly",
    stage: "vehicle_assembly",
  },
  {
    path: "to",
    title: "Final Consumer Market",
    dataset: "shippingInformation",
    stage: "shipping",
  },
];

const log = (c, current, field, title) => {
  const good = Math.abs(c.metrics[field] - current.metrics[field]) < 0.005;

  if (!good) {
    console.log(
      `${title}: ${c.metrics[field]}, calc: ${current.metrics[field]}, id: ${c.id}, battery: ${c.metrics.battery_type}`
    );
  }
};

/* eslint-disable no-unused-vars */
const test = (c) => {
  const { total_emissions } = c.metrics;
  const current = chainWithEmissionProfile(c, "Current", "Current");
  log(c, current, "extraction_emissions", "Extraction");
  log(c, current, "processing_emissions", "Processing");
  log(c, current, "battery_assembly_emissions", "Battery");
  log(c, current, "vehicle_assembly_emissions", "Vehicle");
  //  log(c, current, 'total_emissions', 'Total');
};

//allSupplyChains.map(test);
