import { countryForIso, iso322 } from "../iso3";
// parcel resolver-glob *requires* relative paths, but fails silently when absolute paths are used.
import flags from "../../graphics/content/flags-4x3/*.svg";

export const CountryFlag = ({ iso3, className = "country_flag" }) => (
  <img
    src={flags[iso322[iso3]]}
    className={className}
    alt={`Flag of ${countryForIso(iso3)}`}
  />
);
