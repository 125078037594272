import greatCircle from "@turf/great-circle";
import { useMemo } from "react";
import ReMap from "./map/ReMap";
import countryGeoJson from "../json/wb-adm0-tweaked-centroids.json";
import specificRoutesGeoJson from "../json/specific-routes.json";

const specificRoutes = Object.fromEntries(
  specificRoutesGeoJson.features.map((e) => [
    `${e.properties.SRC}-${e.properties.DST}`,
    { feature: e, element: e.properties.element },
  ])
);

const countries = Object.fromEntries(
  countryGeoJson.features.map((e) => [
    e.properties.ISO_A3,
    e.geometry.coordinates,
  ])
);

const offset_gap = 22; // based on the icon size.
const offsets = [
  [0, 0],
  [-1, 0],
  [1, 0],
  [0, 1],
  [0, -1],
].map((e) => e.map((f) => f * offset_gap));

const key_order = [
  "extraction",
  "processing",
  "battery_assembly",
  "vehicle_assembly",
  "shipping",
];

const colorsByElement = {
  lithium: ["#00a66b", "#7fe5db", "blue", "red"],
  graphite: ["#333333", "#333333", "#333333", "#333333"],
};
const country_expr = ["get", "ISO_A3"];

const selectionLayer = {
  id: "selection-layer",
  key: `layer-selection-layer`,
  label: "selection-layer",
  type: "existing",
  existingLayer: "wb-adm0-fill",
  beforeId: "wb-adm0-fill",
  sourceType: "fill",
  "source-layer": "WB_GAD_ADM0-1dma96",
  source: "composite",
  paint: {
    "fill-opacity": 0,
  },
};

const geojsonBase = {
  type: "FeatureCollection",
  name: "wb-adm0-tweaked-centroids",
  crs: {
    type: "name",
    properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" },
  },
  features: [],
};

export const SupplyChainMap = (props) => {
  const {
    highlightHovered = false,
    selectedCountry = "",
    selectedColor = "blue",
    element = "lithium",
  } = props;
  const colors = colorsByElement[element];

  const chains = useMemo(
    () => props.chains ?? [],
    [JSON.stringify(props.chains ?? [])]
  );

  const selectedCountryHighlightLayer = useMemo(
    () =>
      selectedCountry
        ? [
            {
              id: "selected-country",
              key: `layer-selected-country`,
              label: "selected-country",
              type: "existing",
              existingLayer: "wb-adm0-fill",
              beforeId: "wb-adm0-fill",
              sourceType: "fill",
              "source-layer": "WB_GAD_ADM0-1dma96",
              source: "composite",
              filter: ["in", ["get", "ISO_A3"], ["literal", selectedCountry]],
              paint: {
                "fill-color": selectedColor,
                "fill-opacity": [
                  "case",
                  ["==", ["get", "ISO_A3"], selectedCountry],
                  0.75,
                  0,
                ],
              },
            },
            ...(highlightHovered
              ? [
                  {
                    id: `selected-country-highlight`,
                    key: `layer-selected-country-highlight`,
                    label: "selected-country-highligh",
                    type: "existing",
                    existingLayer: "wb-adm0-fill",
                    beforeId: "wb-adm0-circle",
                    sourceType: "line",
                    "source-layer": "WB_GAD_ADM0-1dma96",
                    source: "composite",
                    filter: [
                      "in",
                      ["get", "ISO_A3"],
                      ["literal", selectedCountry],
                    ],
                    paint: {
                      "line-width": 4,
                      "line-color": selectedColor,
                      "line-opacity": [
                        "case",
                        [
                          "all",
                          ["==", ["get", "ISO_A3"], selectedCountry],
                          ["coalesce", ["feature-state", "hovered"], false],
                        ],
                        1,
                        0,
                      ],
                    },
                  },
                ]
              : []),
          ]
        : [],
    [selectedCountry, selectedColor, highlightHovered]
  );

  const fillExternalLayers = useMemo(
    () =>
      chains.map((e, idx) => {
        const country_in_chain = [
          "in",
          country_expr,
          [
            "literal",
            Object.values(e)
              .filter((e) => e)
              // Don't overhighlight shared elements of supply chains
              // If a fill layer already exists before this layer for the country, don't highlight it in this one
              .filter((country) =>
                chains
                  .slice(0, idx)
                  .every((chain) => !Object.values(chain).includes(country))
              ),
          ],
        ];

        const style = {
          paint: {
            "fill-color": colors[idx],
            "fill-opacity": 0.75,
          },
        };
        return {
          id: `supply-chain-${idx}`,
          key: `layer-supply-chain-${idx}`,
          label: "SupplyChain",
          type: "existing",
          existingLayer: "wb-adm0-fill",
          beforeId: "wb-adm0-fill",

          // stuff we could get dynamically after the map's been loaded
          // But we need one refresh first, so preload
          sourceType: "fill",
          "source-layer": "WB_GAD_ADM0-1dma96",
          source: "composite",

          filter: country_in_chain,
          ...style,
        };
      }),
    [chains]
  );

  const outlineExternalLayers = useMemo(
    () =>
      chains.map((e, idx) => {
        const country_in_chain = [
          "in",
          country_expr,
          [
            "literal",
            Object.values(e)
              .filter((e) => e)
              // Don't overhighlight shared elements of supply chains
              // If a fill layer already exists before this layer for the country, don't highlight it in this one
              .filter((country) =>
                chains
                  .slice(0, idx)
                  .every((chain) => !Object.values(chain).includes(country))
              ),
          ],
        ];
        const style = {
          paint: {
            "line-color": colors[idx],
            "line-opacity": [
              "case",
              ["coalesce", ["feature-state", "hovered"], false],
              1,
              0,
            ],
            "line-width": 4,
          },
        };
        return {
          id: `supply-chain-${idx}-outline`,
          key: `layer-supply-chain-${idx}-outline`,
          label: "SupplyChain",
          type: "existing",
          existingLayer: "wb-adm0-fill",
          beforeId: "wb-adm0-circle",

          // stuff we could get dynamically after the map's been loaded
          // But we need one refresh first, so preload
          sourceType: "line",
          "source-layer": "WB_GAD_ADM0-1dma96",
          source: "composite",

          filter: country_in_chain,
          ...style,
        };
      }),
    [chains]
  );

  // common case is that we're going something like
  // AUS -> CHN -> CHN -> KOR
  // There are two icons, and two circles, at least one at an offset, and different icons.
  // There are at least two icon layers here, one for each China Entry.
  // There are at least two circle layers here, one for no-offset, and one for offset. (Note that other chains may affect this as well).
  // Basic case is: Each icon and each circle is a separate layer. Anything else is madness.

  const iconExternalLayers = useMemo(() => {
    let country_counts = {};
    let country_stages = {};

    const inc = (country, forStage) => {
      country_stages[country] ??= [];
      if (country_stages[country].includes(forStage)) {
        return;
      }
      country_stages[country].push(forStage);
      if (!country_counts[country]) {
        country_counts[country] = 1;
      } else {
        country_counts[country] += 1;
      }
      return country_counts[country];
    };
    // Annotate the array of {stage: iso3} with offsets
    const raw_offsets = chains.map((e) =>
      Object.fromEntries(
        Object.entries(e)
          .map(([stage, iso3]) => {
            const seen = inc(iso3, stage);
            if (!seen) return;
            return [stage, [iso3, offsets[seen - 1]]];
          })
          .filter((e) => e)
      )
    );
    const layers = raw_offsets
      .map((e, idx) => {
        const bg_style = {
          paint: {
            "circle-stroke-width": 3,
            "circle-stroke-color": colors[idx],
            "circle-color": "white",
            "circle-radius": 10,
          },
        };

        const circle_layers = Object.entries(e)
          .filter(([, [iso3]]) => iso3)
          .map(([stage, [iso3, offset]]) => {
            const _style = {
              paint: {
                ...bg_style.paint,
                "circle-translate": offset,
              },
            };
            return {
              id: `supply-chain-circle-${idx}-${stage}-${iso3}`,
              key: `layer-supply-circle-${idx}-${stage}-${iso3}`,
              label: "Supply Chain",
              type: "existing",
              existingLayer: "wb-adm0-circle",
              beforeId: "wb-adm0-circle",
              filter: ["==", country_expr, iso3],
              ..._style,

              source: "composite",
              "source-layer": "wb-adm0-tweaked-centroids-7y3g4q",
              sourceType: "circle",
            };
          });

        const icon_style = {
          layout: {},
          paint: {
            "icon-color": colors[idx],
          },
        };

        const icon_layers = Object.entries(e)
          .filter(([, [iso3]]) => iso3)
          .map(([stage, [iso3, offset]]) => {
            const _style = {
              layout: {
                "icon-offset": offset,
                "icon-image": stage,
                "icon-allow-overlap": true,
              },
              paint: {
                ...icon_style.paint,
              },
            };
            return {
              id: `supply-chain-icon-${idx}-${stage}-${iso3}`,
              key: `layer-supply-icon-${idx}-${stage}-${iso3}`,
              label: "Supply Chain",
              type: "existing",
              existingLayer: "wb-adm0-icon",
              beforeId: "wb-adm0-icon",
              filter: ["==", country_expr, iso3],
              ..._style,
              source: "composite",
              "source-layer": "wb-adm0-tweaked-centroids-7y3g4q",
              sourceType: "symbol",
            };
          });

        return [...circle_layers, ...icon_layers];
      })
      .flat(1);
    return layers;
  }, [chains]);

  const stageLines = new Set();
  const stageEntries = key_order.map(stage => chains.map(c => c[stage]));
  const chainsCommonStagePrefix = stageEntries.findLastIndex((stage) => stage.every(v => v && v === stage[0]));

  const lineExternalLayers = useMemo(
    () =>
      chains
        .map((e, idx) => {
          const style = {
            beforeId: `country-label-lg`,
            type: "line",
            paint: {
              "line-color": colors[idx],
              "line-opacity": 1,
              "line-width": 4,
              "line-dasharray": ["case", ["get", "isSplit"], ["literal", [3, 1]], ["literal", [1]]],
            },
            layout: {
              "line-cap": "butt",
            },
          };
          const styleLight = {
            ...style,
            paint: {
              "line-color": "white",
              "line-opacity": 0.5,
              "line-width": 8,
              "line-blur": 2,
            },
          };

          const stages = key_order.map((stage) => e[stage]).filter((e) => e);

          // great circle -- turf returns features.
          const data = {
            ...geojsonBase,
            features: stages
              .map((c, idx) => {
                const c2 = stages[idx + 1];
                if (c2 == undefined) {
                  return [];
                }
                if (stageLines.has(`${c}-${c2}`)) {
                  return [];
                }
                stageLines.add(`${c}-${c2}`);
                const specificRoute = specificRoutes[`${c}-${c2}`];

                const route = specificRoute &&
                  (!specificRoute.element || specificRoute.element == element)
                  ? specificRoute.feature
                  : greatCircle(countries[c], countries[c2], {
                      npoints: 30,
                      offset: 100,
                    });
                return {
                  ...route,
                  properties: { isSplit: chainsCommonStagePrefix <= idx && chainsCommonStagePrefix >= 0 }
                };
              })
              .filter((e) => e != undefined),
          };

          return [
            {
              id: `supply-chain-line-${idx}`,
              key: `layer-supply-chain-line-${idx}`,
              label: "Supply Chain",
              type: "geojson",
              data: data,
              style: styleLight,
            },
            {
              id: `supply-chain-line-${idx}-2`,
              key: `layer-supply-chain-line-${idx}-2`,
              label: "Supply Chain",
              type: "geojson",
              data: data,
              style,
            },
            {
              id: `supply-chain-line-${idx}-3-arrow`,
              key: `layer-supply-chain-line-${idx}-3-arrow`,
              label: "Supply Chain Arrow",
              type: "geojson",
              data: data,
              style: {
                type: "symbol",
                layout: {
                  "icon-image": `map-arrow-${element}`,
                  "symbol-placement": "line-center",
                },
                paint: {
                  "icon-color": colors[idx],
                },
              },
            },
          ];
        })
        .flat(1),
    [chains]
  );

  const externalLayers = [
    ...(chains.length
      ? [
          ...fillExternalLayers,
          ...(highlightHovered ? outlineExternalLayers : []),
          ...iconExternalLayers,
          ...lineExternalLayers,
        ]
      : []),
    ...selectedCountryHighlightLayer,
    selectionLayer,
  ];

  return (
    <ReMap
      {...props}
      externalLayers={externalLayers}
      layersState={externalLayers.map(() => true)}
    />
  );
};
