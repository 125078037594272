import "babel-polyfill";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import {
  Navigate,
  BrowserRouter,
  Route,
  Routes,
  useParams,
} from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

import config from "./config";

// Views
import Home from "./views/Home";
import TopSupplyChains from "./views/TopSupplyChains";
import BuildSupplyChain from "./views/BuildSupplyChain";

import Policies from "./views/Policies";
import DataSnapshot from "./views/DataSnapshot";
import { CountrySelect, CountryDetail } from "./views/CountryDetail";

import UhOh from "./views/UhOh";

import About from "./views/About";
import StaticPage from "./views/StaticPage";
import CompanyData from "./views/CompanyData";

// Store
import { store } from "./store";

const root = createRoot(document.getElementById("root"));

const PassRouteParams = ({ to: Component }) => {
  const params = useParams();
  return <Component {...params} />;
};

root.render(
  <HelmetProvider>
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home element="lithium"/>} />
          <Route path="/home/:element" element={<PassRouteParams to={Home} />} />
          <Route path="/index.html" element={<Navigate to="/" />} />
          <Route
            path="/top-supply-chains"
            element={<Navigate to="/top-supply-chains/lithium" />}
          />
          <Route
            path="/top-supply-chains/lithium"
            element={<TopSupplyChains element="lithium" />}
          />
          <Route
            path="/top-supply-chains/graphite"
            element={<TopSupplyChains element="graphite" />}
          />
          <Route
            path="/build-supply-chain"
            element={<Navigate to="/build-supply-chain/choose" />}
          />
          <Route
            path="/build-supply-chain/"
            element={<Navigate to="/build-supply-chain/choose" />}
          />
          <Route
            path="/build-supply-chain/:page"
            element={<PassRouteParams to={BuildSupplyChain} />}
          />
          <Route
            path="/data-snapshot/:subject"
            element={<PassRouteParams to={DataSnapshot} />}
          />
          <Route
            path="/data-snapshot"
            element={<Navigate to="/data-snapshot/lithium" />}
          />
          <Route
            path="/data-snapshot/"
            element={<Navigate to="/data-snapshot/lithium" />}
          />
          <Route path="/about/" element={<About />} />
          <Route
            path="/about/:page"
            element={<PassRouteParams to={StaticPage} />}
          />
          <Route
            path="/policies"
            element={<Navigate to="/policies/pillars" />}
          />
          <Route
            path="/policies/:section"
            element={<PassRouteParams to={Policies} />}
          />
          <Route path="/company-data" element={<CompanyData />} />
          <Route path="/countries/" element={<CountrySelect />} />
          <Route path="/countries/:country/" element={<CountryDetail />} />
          <Route path="*" element={<UhOh />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  </HelmetProvider>
);

/* eslint-disable no-console */
console.log.apply(console, config.consoleMessage);
console.log("Environment", config.environment);
/* eslint-enable no-console */
