{
  "data": {
    "AUS": "Australia is the largest lithium producer worldwide and holds the second largest reserves behind Chile. Australian lithium is found in the form of spodumene (hard rock), which can be processed into either lithium carbonate or lithium hydroxide.",
    "CHL": "Chile has the largest lithium reserves worldwide in the form of brine - a concentrate saline solution pumped from the ground. Chile also has in-country capacity to refine its own lithium from brine. Brine is largely processed as lithium carbonate but can also be converted to lithium hydroxide.",
    "CHN": "China is a market leader in lithium processing, li-ion battery (LIB) assembly and EV assembly. China dominates the lithium supply chain,and is expected to hold a near 70% market share in battery assembly by 2029. China is also the number one EV assembly and consumer worldwide.",
    "DEU": "Germany is a global leader in EV manufacturing and is the largest EV market in Europe in absolute terms. The country is also a host to several battery megafactories planning to be built in the next few years.",
    "JPN": "Japan is a key contributor to battery and EV assembly, and controls approximately 20% of the global market share for lithium refining for cell production.",
    "KOR": "South Korea is a leading LIB manufacturer hosting top-tier LIB producing companies and, similarly to Japan, accounts for 20% of lithium refining for cell production.",
    "USA": "The United States is involved in all stages of the supply chain, with some lithium reserves present in the country and growing capacity for lithium processing and battery assembly. It is the third largest EV manufacturer after China and Europe and the second largest EV consumer globally."
  }
}
