import { Component, createRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { PropTypes as T } from "prop-types";

import clonedeep from "lodash.clonedeep";

import { environment } from "../config";

import App from "./App";
import { SupplyChainMap } from "../components/SupplyChainMap";

import MetricsBar from "../components/chains/lithium/MetricsBar";
import { EmissionsStackedBar } from "../components/chains/EmissionsStackedBar";
import CreateSupplyChain from "../components/chains/CreateSupplyChain";
import DecarbonizationOptions from "../components/chains/DecarbonizationOptions";
import { DecarbonizationCharts } from "../components/chains/DecarbonizationCharts";
import useMediaQuery from "../useMediaQuery";
import ResponsiveSidebar from "../components/ResponsiveSidebar";
import Tabs from "../components/Tabs";
import Dropdown from "../components/Dropdown"
import { StageMarker } from "../components/chains/StageMarker";

import { bounds as initialBounds } from "../layers";

import {
  topSupplyChains as chains,
  chainWithEmissionProfile,
} from "../json/lithium/chains";
import { InfoButton } from "../components/InfoButton";

class BuildSupplyChains extends Component {
  constructor(props) {
    super(props);

    this.handleSelectedLocation = this.handleSelectedLocation.bind(this);
    this.handleSelectedChain = this.handleSelectedChain.bind(this);
    this.handleBenchmarkChain = this.handleBenchmarkChain.bind(this);
    this.handleOnHover = this.handleOnHover.bind(this);

    const compatClass =
      (/iPhone/.test(window.navigator.platform) && "ios") ||
      (/Win/.test(window.navigator.platform) && "win") ||
      "";

    this.baseBenchmarkRoutes = chains;

    this.state = {
      compatClass: compatClass,
      activeTab: 0,
      selectedChain: undefined,
      selectedChainNoShipping: undefined,
      benchmarkChain: {},
      decarbonizedChain: {},
      benchmarkRoutes: [...this.baseBenchmarkRoutes],
      highlightStage: undefined,
      tooltipPosition: undefined
    };
    this.tabPage = props.page;

    this.pageDropdownRef = createRef();
  }

  handleOnHover(features, latLng) {
    const { highlightStage } = this.state;
    const selectedIcon = features.filter((f) => f.layer.type == "circle").pop();
    if (selectedIcon) {
      let layerComponents = selectedIcon.layer.id.split("-");
      layerComponents.pop(); // highlighted country
      const newHighlightStage = layerComponents.pop();
      highlightStage != newHighlightStage &&
        this.setState({
          highlightStage: newHighlightStage,
        });
      this.setState({
        tooltipPosition: latLng
      })
      return;
    }
    if (highlightStage) {
      this.setState({ highlightStage: undefined, tooltipPosition: undefined });
    }
  }
  handleBenchmarkChain(chain) {
    const { benchmarkChain, selectedChain } = this.state;

    if (chain.title && chain.title != benchmarkChain.title) {
      this.updateBenchmarks(selectedChain, chain);
    }
  }

  updateBenchmarks(selectedChain, benchmarkChain) {
    this.setState({
      benchmarkChain: chainWithEmissionProfile(
        benchmarkChain,
        "Current",
        "Current",
        selectedChain.chain.shipping
      ),

      benchmarkRoutes: this.baseBenchmarkRoutes.map((e) =>
        chainWithEmissionProfile(
          e,
          "Current",
          "Current",
          selectedChain.chain.shipping
        )
      ),
    });
  }

  handleSelectedLocation(location, features) {
    // location is a lat/lon, features will be a filtered/unfiltered list of the features under the cursor

    const selectedCountry = features
      .filter((f) => f.layer.id == "wb-adm0-fill")
      .pop();

    if (selectedCountry) {
      this.handleSelectedCountry(selectedCountry.properties.ISO_A3);
    }
  }

  handleSelectedChain(chain) {
    const { benchmarkChain } = this.state;
    let newChain = clonedeep(chain);
    // munge a bit
    newChain.chain.shipping = newChain.to;
    this.setState({
      selectedChain: chainWithEmissionProfile(
        newChain,
        "Current",
        "Current",
        newChain.to
      ),
      selectedChainNoShipping: chainWithEmissionProfile(
        chain,
        "Current",
        "Current",
        undefined
      ),
    });
    this.updateBenchmarks(newChain, benchmarkChain);
  }

  render() {
    const {
      compatClass,
      selectedChain = chains[0],
      benchmarkChain,
      decarbonizedChain,
      selectedChainNoShipping = chains[0],
      benchmarkRoutes,
      tooltipPosition,
      highlightStage
    } = this.state;

    const { page, isSmall, navigate } = this.props;

    const displayChains = [
      selectedChain,
      page == "compare" ? benchmarkChain : {},
    ]
      .filter((e) => e.chain)
      .map((e) => e.chain);

    const mapComponent = (
      <SupplyChainMap
        chains={displayChains}
        initialBounds={initialBounds}
        handleSelectedLocation={this.handleSelectedLocation}
        key={page}
        onHover={this.handleOnHover}
        tooltip={tooltipPosition && {
          latLng: tooltipPosition,
          contents: {
            extraction: 'Extraction',
            battery_assembly: 'Battery Assembly',
            processing: 'Processing',
            vehicle_assembly: 'Vehicle Assembly'
          }[highlightStage]
        }}
      />
    );

    const savings =
      selectedChainNoShipping.metrics && decarbonizedChain.metrics
        ? selectedChainNoShipping.metrics.total_emissions -
          decarbonizedChain.metrics.total_emissions
        : 0;
    const savings_pct =
      selectedChain.metrics && decarbonizedChain.metrics
        ? (100 * savings) / selectedChain.metrics.total_emissions
        : 0;

    const extraClass = page == "choose" ? "twocol" : "";

    const routeSettings = ["choose", "compare"].includes(page) ? (
      <div className="shadowscroll__container">
        <CreateSupplyChain
          defaultChain={chains[0]}
          handleSelectedChain={this.handleSelectedChain}
        />
      </div>
    ) : (
      <DecarbonizationOptions
        chain={selectedChain}
        handleDecarbonizedChain={(e) => this.setState({ decarbonizedChain: e })}
      />
    );
    const navLinks = (
      <div className="step__container stage__links">
        <NavLink to="/build-supply-chain/choose" className="byosc-nav-link">
          1. Choose Your Route
          <InfoButton
            description="Here you can choose your own supply chain route - you can
            change the battery type and locations for extraction, processing , battery
            manufacturing and vehicle assembly via the dropdowns on the left. You can
            also add your final consumer market. You can see the changes to emissions and
            energy in the boxes at the bottom of the page."
          />
        </NavLink>
        <NavLink
          to="/build-supply-chain/decarbonize"
          className="byosc-nav-link"
        >
          2. Decarbonization Targets
          <InfoButton
            description="Here you can choose from different options for decarbonising the
            supply chain of lithium to electric vehicles. You can choose from scenarios for
            reducing emissions in electricity or the heat used in the production processes. "
          />
        </NavLink>
        <NavLink to="/build-supply-chain/compare">
          3. Compare Supply Chains
        </NavLink>
      </div>
    );
    const leftBar = (
      <div className="byosc-builder-collapse inpage__leftpanel">
        {navLinks}
        {routeSettings}
      </div>
    );

    const bottomBar = ["choose", "compare"].includes(page) && (
      <MetricsBar
        selectedChain={selectedChain.metrics}
        chainContext={page == "compare" ? benchmarkChain.metrics : undefined}
        contextTitle={benchmarkChain.title || ""}
      />
    );

    const rightBarDecarbonize = page === "decarbonize" && (
      <div className="inpage__rightpanel inpage__height econtrols">
        <div className="big-num-container tab__sidebar">
          <div>
            <InfoButton description="This number shows how much emissions could be saved by moving to the chosen electricity and heat scenarios." />
            <h3 className={"chart-media__caption"}>
              GHG Savings over Present Mix:
            </h3>
          </div>
          <p className={"big-num"}>{savings_pct.toFixed(0)}%</p>
          <p>
            {savings.toFixed(2)} tCO<sub>2</sub>e
          </p>
          <hr />
        </div>
        <div style={{ flexGrow: 2 }}>
          <DecarbonizationCharts
            baseChain={selectedChain}
            decarbonizedChain={
              decarbonizedChain.metrics ? decarbonizedChain : selectedChain
            }
            className="tab__sidebar"
          />
        </div>
      </div>
    );
    const rightBarCompare = page === "compare" && (
      <div className="inpage__rightpanel inpage__height econtrols">
        <div className="tab__sidebar">
          <EmissionsStackedBar
            title={
              <span>
                Benchmark your Route Emissions (tCO<sub>2</sub>e)
              </span>
            }
            legend={true}
            chains={[selectedChain]}
          />
          <hr />
          <div className="byosc_dec_targets">
            <InfoButton description="You can compare your chosen supply chain against the Top Supply Chains by clicking on any of them on the right. This will let you visualise the difference on the map and see the difference in energy and emissions below." />
            <p style={{ paddingRight: 20 }}>
              Click on one of the Top Supply Chains below to compare with Your
              Supply Chain.
            </p>
          </div>
          <EmissionsStackedBar
            subtitle="(2020 Electricity and Heat Source Mix)"
            chains={benchmarkRoutes}
            onSetBenchmarkChain={this.handleBenchmarkChain}
            highlightEntry={benchmarkChain.id}
          />
        </div>
      </div>
    );
    const changePage = (page) => () => {
      navigate(`/build-supply-chain/${page}`);
      this.pageDropdownRef.current.close();
    };
    return (
      <App pageTitle="Build Your Own Supply Chains" className={compatClass}>
        <section className={`inpage inpage--byosc ${extraClass}`}>
          {isSmall ? (<>
            <Dropdown
              className="dropdown byosc-section-dropdown"
              triggerClassName="byosc-section-dropdown-trigger dropdown__active"
              triggerActiveClassName="button--active"
              triggerText={{
                'choose': 'Choose your Route',
                'decarbonize': 'Decarbonization Targets',
                'compare': 'Compare Supply Chains'
              }[page]}
              triggerTitle="Element"
              direction="down"
              alignment="left"
              ref={this.pageDropdownRef}
            >
              <ul className="drop__menu">
                <li
                  className="drop__menu-item"
                  onClick={changePage("choose")}
                >
                  Choose Your Route
                </li>
                <li
                  className="drop__menu-item"
                  onClick={changePage("decarbonize")}
                >
                  Decarbonization Targets
                </li>
                <li
                  className="drop__menu-item"
                  onClick={changePage("compare")}
                >
                 Compare Supply Chains 
                </li>
              </ul>
            </Dropdown>
            <ul className="byosc-icon-legend">
              <li><StageMarker stage="extraction" className="header_img_marker"/>Extraction</li>
              <li><StageMarker stage="processing" className="header_img_marker"/>Processing</li>
              <li><StageMarker stage="battery_assembly" className="header_img_marker"/>Battery Assembly</li>
              <li><StageMarker stage="vehicle_assembly" className="header_img_marker"/>Vehicle Assembly</li>
              <li><StageMarker stage="shipping" className="header_img_marker"/>Shipping</li>
            </ul>
            <ResponsiveSidebar>
              <div className="byosc-sidebar-inner-tabs" style={{ paddingTop: '1rem' }}>
                <Tabs
                  tabList={[
                    {
                      title: "Settings",
                      tab_name: "Settings",
                      contents: () => <div className="byosc-sidebar-inner-tab">{routeSettings}</div>,
                      show: true,
                    },
                    {
                      title: "Metrics",
                      tab_name: "Metrics",
                      contents: () => <div className="byosc-sidebar-inner-tab">{bottomBar}</div>,
                      show: !!bottomBar,
                    },
                    {
                      title: "Decarbonize",
                      tab_name: "Decarbonize",
                      contents: () => <div className="byosc-sidebar-inner-tab">{rightBarDecarbonize}</div>,
                      show: !!rightBarDecarbonize,
                    },
                    {
                      title: "Compare",
                      tab_name: "Compare",
                      contents: () => <div className="byosc-sidebar-inner-tab">{rightBarCompare}</div>,
                      show: !!rightBarCompare,
                    },
                  ].filter((x) => x.show)}
                  onChange={(activeTab) => {
                    this.setState({ activeTab });
                    this.tabPage = page;
                  }}
                  activeTab={page == this.tabPage ? this.state.activeTab : 0}
                />
              </div>
            </ResponsiveSidebar>
          </>
          ) : (
            leftBar
          )}
          <div
            className={`inpage__center ${
              !isSmall && bottomBar && "has-bottom-bar"
            }`}
          >
            <div className="inpage__map">{mapComponent}</div>
            {!isSmall && bottomBar}
          </div>
          {!isSmall && rightBarDecarbonize}
          {!isSmall && rightBarCompare}
        </section>
      </App>
    );
  }
}

const withIsSmall = (Component) =>
  function Wrapper(props) {
    const isSmall = useMediaQuery("(max-width: 767px)");
    const navigate = useNavigate();
    return <Component {...props} isSmall={isSmall} navigate={navigate} />;
  };

if (environment !== "production") {
  BuildSupplyChains.propTypes = {
    match: T.object,
    history: T.object,
  };
}

export default withIsSmall(BuildSupplyChains);
