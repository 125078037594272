import { Fragment } from "react";
import { PropTypes as T } from "prop-types";
import { Link } from "react-router-dom";
import { environment } from "../../../config";

import { countryForIso } from "../../../iso3";
import { CountryDropdown } from "../CountryDropdown";
import { CountryStackedBar } from "../../CountryYearlyStackedBar";
import { SingleBar } from "../../SingleBar";
import { CountryFlag } from "../../CountryFlag";

export const DataCountryFocusTab = ({
  iso3 = "CHN",
  onChange,
  data,
  units,
}) => {
  const countryMap = Object.fromEntries(
    data
      .filter((e) => e.graphite_reserve)
      .map((e) => [e.country, countryForIso(e.country)])
  );
  const filteredData = data.filter((e) => e.country == iso3);

  let colors = {};
  colors[iso3] = "#fed46f";

  const production = data
    .map(({ country, graphite_production }) => [country, graphite_production])
    .reduce((d, [country, elem]) => {
      if (!d[country]) {
        d[country] = [];
      }
      d[country].push(elem);
      return d;
    }, {});

  const isProducer = (iso) =>
    production[iso].some((graphite) => graphite !== "" && graphite > 0);
  const activeIsProducer = isProducer(iso3);
  const millionsReserve = filteredData.some(
    ({ graphite_reserve }) => graphite_reserve > 1000
  );

  return (
    <div className="tab__focus scrollfixer">
      <div className="country_header">
        <CountryDropdown
          countryData={countryMap}
          selectedCountry={iso3}
          onChange={onChange}
          group={(iso) =>
            isProducer(iso) ? [0, "Producers"] : [1, "Non-Producers"]
          }
        />
        <CountryFlag iso3={iso3} />
      </div>
      {activeIsProducer ? (
        <Fragment>
          <div
            key="graphite_production"
            style={{ height: "20vh", width: "100%" }}
          >
            <CountryStackedBar
              title="Graphite Production"
              data={filteredData}
              selectedField="graphite_production"
              units={units}
              colors={colors}
              showLegend={false}
            />
          </div>
          <div
            key="graphite_reserves"
            style={{ height: "20vh", width: "100%" }}
          >
            <CountryStackedBar
              title="Graphite Reserves"
              data={
                millionsReserve
                  ? filteredData.map(({ graphite_reserve, ...point }) => ({
                      graphite_reserve: graphite_reserve / 1000,
                      ...point,
                    }))
                  : filteredData
              }
              selectedField="graphite_reserve"
              units={millionsReserve ? "million tons" : units}
              colors={colors}
              showLegend={false}
            />
          </div>
        </Fragment>
      ) : (
        <div>
          No production or reserve data is available. There is either no
          production or production may be occurring but at a small volume.
        </div>
      )}
      <Link to={`/countries/${iso3}`}>More Information</Link>
      <GlobalContext data={data} iso3={iso3} isProducer={activeIsProducer} />
    </div>
  );
};

const _sumField = (data, field) =>
  data
    .filter((e) => e[field])
    .map((e) => e[field])
    .reduce((a, b) => a + b);

const _formatter = Intl.NumberFormat("en-US");
const fmt = (x) => _formatter.format(x.toFixed(0));

export const GlobalContext = ({ iso3, data, isProducer }) => {
  console.log({ data });
  const currentYear = Math.max(...data.map((e) => e.year));
  const currentYearData = data.filter((e) => e.year == currentYear);
  const { graphite_reserve = 0, graphite_production = 0 } =
    currentYearData.filter((e) => e.country == iso3)[0] || {};

  const totalReserves = _sumField(currentYearData, "graphite_reserve");
  const totalProduction = _sumField(currentYearData, "graphite_production");

  return (
    <div style={{ marginTop: "1rem" }}>
      <h2>Global Context ({currentYear})</h2>
      {isProducer && (
        <Fragment>
          {graphite_production ? (
            <>
              <h3>{countryForIso(iso3)} Percentage of Global Production:</h3>
              <SingleBar
                value_frac={graphite_production / totalProduction}
                places={2}
              />
            </>
          ) : null}
          {graphite_reserve ? (
            <>
              <h3>{countryForIso(iso3)} Percentage of Global Reserves:</h3>
              <SingleBar
                value_frac={graphite_reserve / totalReserves}
                places={2}
              />
            </>
          ) : null}
          {graphite_reserve && graphite_production ? (
            <>
              <h3>
                {countryForIso(iso3)} Production as a percentage of Reserves:
              </h3>
              <SingleBar
                value_frac={graphite_production / graphite_reserve}
                places={2}
              />
            </>
          ) : null}
        </Fragment>
      )}

      <p style={{ marginTop: "1rem", fontSize: "1.125rem" }}>
        {isProducer && (
          <Fragment>
            Production: {fmt(graphite_production || 0)} (thousand tons)
            <br />
            Reserves: {fmt(graphite_reserve || 0)} (thousand tons)
            <br />
          </Fragment>
        )}
      </p>
    </div>
  );
};

if (environment !== "production") {
  DataCountryFocusTab.propTypes = {
    iso3: T.string,
    onChange: T.func,
  };
}
