{
  "data": [
    {
      "extraction": "AUS",
      "processing": "CHN",
      "battery": "LMO",
      "assembly": "",
      "extraction_heat_factor": 0.094565,
      "processing_heat_factor": 0.107,
      "assembly_heat_factor": ""
    },
    {
      "extraction": "AUS",
      "processing": "CHN",
      "battery": "NMC111",
      "assembly": "",
      "extraction_heat_factor": 0.094565,
      "processing_heat_factor": 0.107,
      "assembly_heat_factor": ""
    },
    {
      "extraction": "AUS",
      "processing": "CHN",
      "battery": "NMC622",
      "assembly": "",
      "extraction_heat_factor": 0.094565,
      "processing_heat_factor": 0.107,
      "assembly_heat_factor": ""
    },
    {
      "extraction": "AUS",
      "processing": "CHN",
      "battery": "LFP",
      "assembly": "",
      "extraction_heat_factor": 0.094565,
      "processing_heat_factor": 0.10706,
      "assembly_heat_factor": ""
    },
    {
      "extraction": "AUS",
      "processing": "CHN",
      "battery": "NCA",
      "assembly": "",
      "extraction_heat_factor": 0.094565,
      "processing_heat_factor": 0.10706,
      "assembly_heat_factor": ""
    },
    {
      "extraction": "AUS",
      "processing": "CHN",
      "battery": "NMC811",
      "assembly": "",
      "extraction_heat_factor": 0.094565,
      "processing_heat_factor": 0.10706,
      "assembly_heat_factor": ""
    },
    {
      "extraction": "CHL",
      "processing": "CHL",
      "battery": "",
      "assembly": "",
      "extraction_heat_factor": 0.070233,
      "processing_heat_factor": 0.070233,
      "assembly_heat_factor": ""
    },
    {
      "extraction": "",
      "processing": "",
      "battery": "",
      "assembly": "*",
      "extraction_heat_factor": "",
      "processing_heat_factor": "",
      "assembly_heat_factor": 0.07062
    }
  ]
}
