const bounds = [
  { lon: -20, lat: -50 }, // w s
  { lon: 320, lat: 50 }, // e n
];

const viewport = {
  latitude: 10,
  longitude: 150,
  zoom: 1,
};

export { bounds, viewport };
