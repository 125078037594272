import { Component } from "react";
import { NavAboutMenu } from "../components/NavAboutMenu";

import { connect } from "react-redux";
import { wrapApiResult } from "../redux/utils";
import { fetchStaticPage } from "../redux/actions";

import App from "./App";
import UhOh from "./UhOh";

export const pages = {
  "user-guide": {
    path: "user_guide.html",
    title: "User Guide",
  },
  methodology: {
    path: "methodology.html",
    title: "Methodology",
    mathjax: true,
  },
  faqs: {
    path: "faqs.html",
    title: "FAQs",
  },
  "further-resources": {
    path: "further_resources.html",
    title: "Further Resources",
  },
  contact: {
    path: "contact.html",
    title: "Contact",
  },
  credits: {
    path: "credits.html",
    title: "Credits",
  },
  "terms-of-use": {
    path: "terms_of_use.html",
    title: "Terms Of Use",
  },
};

export const navPages = Object.entries(pages).map(([k, v]) => ({
  title: v.title,
  path: k,
}));

class StaticPage extends Component {
  constructor(props) {
    super(props);

    this.state = { body: "" };
    this.fetch = this.fetch.bind(this);
    this.componentDidUpdate = this.componentDidUpdate.bind(this);
    this.fetch();
  }

  async fetch() {
    const { page } = this.props;
    const { path } = pages[page] || {};
    await this.props.fetchStaticPage(path);
    const { hasError, getData } = this.props.staticPage;
    if (!hasError()) {
      this.setState({ body: getData() });
    }
  }

  componentDidUpdate(preProps) {
    const { page } = this.props;
    if (page != preProps.page) {
      this.fetch();
    } else {
      if (pages[page].mathjax) {
        if (window.MathJax && window.MathJax.typesetPromise) {
          window.MathJax.typesetPromise();
        } else {
          // This isn't an argument, this is abuse.
          const script = document.createElement("script");
          script.async = true;
          script.src =
            "https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-mml-chtml.js";
          document.body.appendChild(script);
        }
      }
    }
  }

  render() {
    const { page } = this.props;
    const { body } = this.state;

    if (!pages[page]) {
      return <UhOh />;
    }

    const { title } = pages[page];
    return (
      <App pageTitle={title}>
        <article className="inpage inpage--about">
          <header className="inpage__header">
            <div className="inpage__subheader">
              <div className="inpage__headline">
                <h1 className="inpage__title">{title}</h1>
              </div>
            </div>
          </header>
          <NavAboutMenu pages={navPages} />
          <div className="inpage__body">
            <div className="prose" dangerouslySetInnerHTML={{ __html: body }} />
          </div>
        </article>
      </App>
    );
  }
}

function mapStateToProps(state) {
  return {
    staticPage: wrapApiResult(state.staticPage),
  };
}

function dispatcher(dispatch) {
  return {
    fetchStaticPage: (...args) => dispatch(fetchStaticPage(...args)),
  };
}

export default connect(mapStateToProps, dispatcher)(StaticPage);
