import { PropTypes as T } from "prop-types";

import { environment } from "../config";

export const InfoButton = (props) => {
  const { description } = props;

  return (
    (description && (
      <div
        className="form__inner-actions"
        style={{ position: "absolute", top: -2, right: 0 }}
      >
        <button
          type="button"
          className="eci-info"
          data-tooltip-html={description}
          data-tooltip-id="infotip-global"
          data-event="mouseenter mouseout"
        >
          <span>Info</span>
        </button>
      </div>
    )) ||
    ""
  );
};

if (environment !== "production") {
  InfoButton.propTypes = {
    title: T.string,
    description: T.string,
    subtitle: T.string,
    className: T.string,
  };
}
