import { useState, useEffect } from "react";
import countryBlurbs from "../../../html/countries/*.html";
import furtherInformationLinksCsv from "bundle-text:/data/countries_further_information.csv";
import { parse } from "csv-parse/lib/sync";

const furtherInformationLinks = (() => {
  const parsed = parse(furtherInformationLinksCsv, {
    columns: true,
    cast: true,
  });
  const links = {};
  parsed.forEach(({ country, url }) => {
    (links[country] ?? (links[country] = [])).push(url);
  });
  return links;
})();

export const countries = Object.keys(countryBlurbs);

const FurtherInformation = ({ country }) => {
  const url = countryBlurbs[country];
  const [html, setHtml] = useState(null);
  useEffect(() => {
    setHtml(null);
    if (url) {
      fetch(url)
        .then((resp) => resp.text())
        .then((html) => setHtml(html));
    }
  }, [url, setHtml]);
  const furtherInformation = (() => {
    const links = furtherInformationLinks[country];
    if (links)
      return (
        <>
          <ul>
            {links.map((link) => (
              <li key={link}>
                <a href={link}>{link}</a>
              </li>
            ))}
          </ul>
        </>
      );
  })();

  return (
    <>
      <h1 id="further_information" className="country_details_header">
        Further Information
      </h1>
      {furtherInformation ? furtherInformation : <p>No further information</p>}
    </>
  );
};

export default FurtherInformation;
