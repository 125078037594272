const _temp0 = require("./battery_assembly.svg");
const _temp1 = require("./extraction.svg");
const _temp2 = require("./final_shipping.svg");
const _temp3 = require("./processing.svg");
const _temp4 = require("./shipping.svg");
const _temp5 = require("./vehicle_assembly.svg");
module.exports = {
  "battery_assembly": _temp0,
  "extraction": _temp1,
  "final_shipping": _temp2,
  "processing": _temp3,
  "shipping": _temp4,
  "vehicle_assembly": _temp5
}