import { Component } from "react";
import { PropTypes as T } from "prop-types";
import { Group } from "@visx/group";
import { scaleBand, scaleLinear, scaleOrdinal } from "@visx/scale";
import { ParentSize } from "@visx/responsive";
import { LegendOrdinal } from "@visx/legend";
import { withTooltip, TooltipWithBounds, defaultStyles } from "@visx/tooltip";
import { BarStack } from "@visx/shape";
import { LinePath } from "@visx/shape";
import { AxisBottom } from "@visx/axis";
import { environment } from "../config";
import { localPoint } from "@visx/event";
import Figure from "./explore/Figure";
import YAxis from "./explore/YAxis";

import { countryForIso } from "../iso3";

const tickComponent = ({ formattedValue, ...tickProps }) => (
  <text {...tickProps}>{formattedValue}</text>
);
const xTickProps = {
  textAnchor: "middle",
  dy: "-0.1em",
  fontFamily: "Rubik-light",
};

/**
 * The CountryChart component
 */
class CountryChart extends Component {
  constructor(props) {
    super(props);
    this.renderChart = this.renderChart.bind(this);
    this.handleMouseOver = this.handleMouseOver.bind(this);
  }

  handleMouseOver(event, datum) {
    const coords = localPoint(event.target.ownerSVGElement, event);
    this.props.showTooltip({
      tooltipLeft: coords.x,
      tooltipTop: coords.y,
      tooltipData: datum,
    });
  }

  renderChart(parsedData, countries, parentWidth, parentHeight) {
    const _formatter = Intl.NumberFormat("en-US", {
      maximumSignificantDigits: 3,
    });
    const fmt = (x) => _formatter.format(x);

    // Chart properties
    const height = parentHeight - 30; // figcaption
    const width = parentWidth;

    const margin = {
      top: 30,
      bottom: 25,
      left: 50,
      right: 0,
    };
    const xMin = margin.left;
    const xMax = width - margin.right;
    const yMin = margin.top;
    const yMax = height - margin.bottom;

    const tooltipStyles = {
      ...defaultStyles,
      minWidth: 80,
    };

    const {
      units = "",
      colors = {},
      extraSeries,
      extraSeriesLabel,
    } = this.props;

    const {
      tooltipData,
      tooltipLeft,
      tooltipTop,
      tooltipOpen,
      hideTooltip,
      showLegend = true,
    } = this.props;

    const keys = Object.keys(countries).sort();
    const labelMap = countries;

    const years = parsedData.map((e) => e.year);
    const maxVal = Math.max(...parsedData.map((e) => e.total));

    // Define scales
    const xScale = scaleBand({
      range: [xMin, xMax],
      domain: years,
      padding: 0.1,
    });

    const yScale = scaleLinear({
      range: [yMin, yMax],
      domain: [maxVal, 0], // this is backwards, top first.
    });

    // Code name for the keys
    const colorScale = scaleOrdinal({
      domain: keys,
      range: keys.map((e) => colors[e]),
    });

    const barWidth = xScale.bandwidth();

    return (
      <figure className="popchart__chart">
        <div className="popchart__chart_item">
          <svg width={width} height={height}>
            <Group>
              <YAxis
                yScale={yScale}
                label={units}
                xMin={margin.left}
                yMin={0}
                textAnchor="middle"
              />
              <BarStack
                data={parsedData}
                yScale={yScale}
                xScale={xScale}
                x={(d) => d.year}
                keys={keys}
                height={height}
                color={colorScale}
              >
                {(barStacks) => {
                  const rects = barStacks.map((barStack) =>
                    barStack.bars
                      .filter((bar) => bar.bar.data[bar.key] !== undefined)
                      .map((bar) => {
                        const data = bar.bar.data;
                        return (
                          <rect
                            key={`bar-${bar.key}-${data.year}`}
                            x={bar.x}
                            y={bar.y}
                            width={bar.width}
                            height={bar.height}
                            fill={bar.color}
                            onMouseOver={(e) =>
                              this.handleMouseOver(e, {
                                title: labelMap[bar.key],
                                color: "#333",
                                value: data[bar.key],
                                year: data.year,
                              })
                            }
                            onMouseOut={hideTooltip}
                          />
                        );
                      })
                  );
                  const labels = barStacks.length
                    ? barStacks[0].bars.map((bar, idx) => {
                        const total = fmt(bar.bar.data.total);
                        const x = bar.x + bar.width / 2;
                        const y =
                          Math.min(
                            ...barStacks.map(
                              (stack) =>
                                stack.bars[idx].y + stack.bars[idx].height
                            )
                          ) - 5;
                        return (
                          <text key={idx} x={x} y={y} textAnchor="middle">
                            {total}
                          </text>
                        );
                      })
                    : [];
                  return [...rects, ...labels];
                }}
              </BarStack>
              {years.length == 0 ? (
                <text
                  textAnchor="middle"
                  x={margin.left + (xMax - xMin) / 2}
                  y={height / 2}
                  fontSize="1.5rem"
                >
                  N/A
                </text>
              ) : (
                ""
              )}
              {(extraSeries && (
                <g>
                  <LinePath
                    key="line-extraseries"
                    data={extraSeries}
                    x={(d) => xScale(d.year) + barWidth / 2}
                    y={(d) => yScale(d.total)}
                    strokeWidth={2}
                    stroke={"#eee"}
                    strokeDasharray={[3, 3]}
                  />
                  {extraSeries.map((d) => (
                    <circle
                      key={`marker--${d.year}`}
                      r={4}
                      cx={xScale(d.year) + barWidth / 2}
                      cy={yScale(d.total)}
                      stroke={"#aaa"}
                      fill="transparent"
                      onMouseOver={(e) =>
                        this.handleMouseOver(e, {
                          title: extraSeriesLabel,
                          color: "#666",
                          value: d.total,
                        })
                      }
                    />
                  ))}
                </g>
              )) ||
                ""}
              <AxisBottom
                top={yMax + 2}
                scale={xScale}
                hideZero={true}
                className="x-axis"
                hideTicks={true}
                tickLabelProps={xTickProps}
                tickComponent={tickComponent}
                numTicks={6}
              />
            </Group>
          </svg>
          {showLegend && (
            <CountryLegend countries={countries} colors={colors} />
          )}
          {tooltipOpen && (
            <TooltipWithBounds
              key={Math.random()}
              top={tooltipTop}
              left={tooltipLeft}
              style={tooltipStyles}
            >
              <div className={"tooltip-wrapper"}>
                <div style={{ color: tooltipData.color, marginBottom: "8px" }}>
                  <strong>
                    {tooltipData.title} ({tooltipData.year})
                  </strong>
                </div>
                {tooltipData.value !== undefined ? (
                  <div>
                    <strong>
                      {fmt(tooltipData.value)}
                      {units}
                    </strong>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </TooltipWithBounds>
          )}
        </div>
      </figure>
    );
  }

  render() {
    const {
      title,
      description,
      data = [],
      selectedField,
      subtitle,
    } = this.props;

    if (!data.length) {
      return null;
    }

    let accumulator = Object.fromEntries(
      data.map((e) => [e.year, { year: e.year }])
    );
    let countries = {};

    data.forEach((e) => {
      if (!e[selectedField]) {
        return;
      }
      accumulator[e.year][e.country] = e[selectedField];
      countries[e.country] = countryForIso(e.country);
    });

    const parsedData = Object.values(accumulator)
      .filter((e) => Object.keys(e).length >= 2) // filter empties
      .map((e) => ({
        ...e,
        total: Object.entries(e)
          .filter(([k]) => k != "idx" && k != "year") // filter idx, year
          .map(([, v]) => v) // value
          .reduce((a, b) => a + b), // sum
      }));

    return (
      <ParentSize>
        {({ width: parentWidth, height: parentHeight }) =>
          (parentWidth && (
            <Figure
              title={title}
              className="chart__yearlybar"
              description={description}
              subtitle={subtitle}
            >
              {this.renderChart(
                parsedData,
                countries,
                parentWidth,
                parentHeight
              )}
            </Figure>
          )) ||
          ""
        }
      </ParentSize>
    );
  }
}

export const CountryStackedBar = withTooltip(CountryChart);
export const CountryLegend = ({ colors, countries, data, selectedField }) => {
  let labelMap = countries;
  if (!countries) {
    labelMap = Object.fromEntries(
      data
        .filter((e) => e[selectedField])
        .map((e) => [e.country, countryForIso(e.country)])
    );
  }

  const keys = Object.keys(labelMap).sort();

  // Full name for the keys
  const legendScale = scaleOrdinal({
    domain: keys.map((e) => labelMap[e]),
    range: keys.map((e) => colors[e]),
  });

  return (
    <LegendOrdinal
      scale={legendScale}
      direction="row"
      labelMargin="0 6px 0 0"
      className="legend__wrap"
    />
  );
};

if (environment !== "production") {
  CountryChart.propTypes = {
    title: T.string,
    description: T.string,
    chains: T.array,
    onSetBenchmarkChain: T.func,
  };
  CountryStackedBar.propTypes = {
    title: T.string,
    description: T.string,
    chains: T.array,
    onSetBenchmarkChain: T.func,
  };
}
