import { Component } from "react";

import App from "./App";

class CompanyData extends Component {
  render() {
    return (
      <App pageTitle="Company Data">
        <article className="inpage inpage--about">
          <header className="inpage__header">
            <div className="inpage__subheader">
              <div className="inpage__headline">
                <h1 className="inpage__title">Company Data</h1>
              </div>
            </div>
          </header>
          <div /> {/* nav spacing */}
          <div className="inpage__body">
            <div className="prose">
              <p>
                Governments, manufacturers and consumers are increasingly guided
                by their concern for the environment and on their efforts
                towards decarbonization to help meet the Paris Agreement.
                Companies along the lithium, battery and EV supply chain are key
                to contributing to efforts on decarbonization, minimizing
                environmental and social impacts and data transparency is a
                first step towards this effort.
              </p>
              <p>
                This page will allow you, as a company, to publish your GHG,
                energy and water footprint on a or several given stage(s) of the
                lithium supply chain (lithium extraction; lithium processing;
                battery manufacturing; and EV production). This page also holds
                space for companies to share their environmental, social and
                governance targets and metrics. This not only allows users to
                better understand lithium supply chains on a more granular
                basis, but also helps other companies and stakeholders to look
                to those companies setting an example to reduce their GHG and
                environmental footprint holistically.
              </p>
              <p style={{ color: "#999" }}>Feature available soon.</p>

              <img
                style={{ maxWidth: "100%" }}
                src={
                  new URL(
                    "/app/assets/graphics/content/lithium-salt.jpeg",
                    import.meta.url
                  )
                }
              />
            </div>
          </div>
        </article>
      </App>
    );
  }
}

export default CompanyData;
