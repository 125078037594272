const _temp0 = require("./AD.svg");
const _temp1 = require("./AE.svg");
const _temp2 = require("./AF.svg");
const _temp3 = require("./AG.svg");
const _temp4 = require("./AI.svg");
const _temp5 = require("./AL.svg");
const _temp6 = require("./AM.svg");
const _temp7 = require("./AO.svg");
const _temp8 = require("./AQ.svg");
const _temp9 = require("./AR.svg");
const _temp10 = require("./AS.svg");
const _temp11 = require("./AT.svg");
const _temp12 = require("./AU.svg");
const _temp13 = require("./AW.svg");
const _temp14 = require("./AX.svg");
const _temp15 = require("./AZ.svg");
const _temp16 = require("./BA.svg");
const _temp17 = require("./BB.svg");
const _temp18 = require("./BD.svg");
const _temp19 = require("./BE.svg");
const _temp20 = require("./BF.svg");
const _temp21 = require("./BG.svg");
const _temp22 = require("./BH.svg");
const _temp23 = require("./BI.svg");
const _temp24 = require("./BJ.svg");
const _temp25 = require("./BL.svg");
const _temp26 = require("./BM.svg");
const _temp27 = require("./BN.svg");
const _temp28 = require("./BO.svg");
const _temp29 = require("./BQ.svg");
const _temp30 = require("./BR.svg");
const _temp31 = require("./BS.svg");
const _temp32 = require("./BT.svg");
const _temp33 = require("./BV.svg");
const _temp34 = require("./BW.svg");
const _temp35 = require("./BY.svg");
const _temp36 = require("./BZ.svg");
const _temp37 = require("./CA.svg");
const _temp38 = require("./CC.svg");
const _temp39 = require("./CD.svg");
const _temp40 = require("./CF.svg");
const _temp41 = require("./CG.svg");
const _temp42 = require("./CH.svg");
const _temp43 = require("./CI.svg");
const _temp44 = require("./CK.svg");
const _temp45 = require("./CL.svg");
const _temp46 = require("./CM.svg");
const _temp47 = require("./CN.svg");
const _temp48 = require("./CO.svg");
const _temp49 = require("./CR.svg");
const _temp50 = require("./CU.svg");
const _temp51 = require("./CV.svg");
const _temp52 = require("./CW.svg");
const _temp53 = require("./CX.svg");
const _temp54 = require("./CY.svg");
const _temp55 = require("./CZ.svg");
const _temp56 = require("./DE.svg");
const _temp57 = require("./DJ.svg");
const _temp58 = require("./DK.svg");
const _temp59 = require("./DM.svg");
const _temp60 = require("./DO.svg");
const _temp61 = require("./DZ.svg");
const _temp62 = require("./EC.svg");
const _temp63 = require("./EE.svg");
const _temp64 = require("./EG.svg");
const _temp65 = require("./EH.svg");
const _temp66 = require("./ER.svg");
const _temp67 = require("./ES-CT.svg");
const _temp68 = require("./ES.svg");
const _temp69 = require("./ET.svg");
const _temp70 = require("./EU.svg");
const _temp71 = require("./FI.svg");
const _temp72 = require("./FJ.svg");
const _temp73 = require("./FK.svg");
const _temp74 = require("./FM.svg");
const _temp75 = require("./FO.svg");
const _temp76 = require("./FR.svg");
const _temp77 = require("./GA.svg");
const _temp78 = require("./GB-ENG.svg");
const _temp79 = require("./GB-NIR.svg");
const _temp80 = require("./GB-SCT.svg");
const _temp81 = require("./GB-WLS.svg");
const _temp82 = require("./GB.svg");
const _temp83 = require("./GD.svg");
const _temp84 = require("./GE.svg");
const _temp85 = require("./GF.svg");
const _temp86 = require("./GG.svg");
const _temp87 = require("./GH.svg");
const _temp88 = require("./GI.svg");
const _temp89 = require("./GL.svg");
const _temp90 = require("./GM.svg");
const _temp91 = require("./GN.svg");
const _temp92 = require("./GP.svg");
const _temp93 = require("./GQ.svg");
const _temp94 = require("./GR.svg");
const _temp95 = require("./GS.svg");
const _temp96 = require("./GT.svg");
const _temp97 = require("./GU.svg");
const _temp98 = require("./GW.svg");
const _temp99 = require("./GY.svg");
const _temp100 = require("./HK.svg");
const _temp101 = require("./HM.svg");
const _temp102 = require("./HN.svg");
const _temp103 = require("./HR.svg");
const _temp104 = require("./HT.svg");
const _temp105 = require("./HU.svg");
const _temp106 = require("./ID.svg");
const _temp107 = require("./IE.svg");
const _temp108 = require("./IL.svg");
const _temp109 = require("./IM.svg");
const _temp110 = require("./IN.svg");
const _temp111 = require("./IO.svg");
const _temp112 = require("./IQ.svg");
const _temp113 = require("./IR.svg");
const _temp114 = require("./IS.svg");
const _temp115 = require("./IT.svg");
const _temp116 = require("./JE.svg");
const _temp117 = require("./JM.svg");
const _temp118 = require("./JO.svg");
const _temp119 = require("./JP.svg");
const _temp120 = require("./KE.svg");
const _temp121 = require("./KG.svg");
const _temp122 = require("./KH.svg");
const _temp123 = require("./KI.svg");
const _temp124 = require("./KM.svg");
const _temp125 = require("./KN.svg");
const _temp126 = require("./KP.svg");
const _temp127 = require("./KR.svg");
const _temp128 = require("./KW.svg");
const _temp129 = require("./KY.svg");
const _temp130 = require("./KZ.svg");
const _temp131 = require("./LA.svg");
const _temp132 = require("./LB.svg");
const _temp133 = require("./LC.svg");
const _temp134 = require("./LI.svg");
const _temp135 = require("./LK.svg");
const _temp136 = require("./LR.svg");
const _temp137 = require("./LS.svg");
const _temp138 = require("./LT.svg");
const _temp139 = require("./LU.svg");
const _temp140 = require("./LV.svg");
const _temp141 = require("./LY.svg");
const _temp142 = require("./MA.svg");
const _temp143 = require("./MC.svg");
const _temp144 = require("./MD.svg");
const _temp145 = require("./ME.svg");
const _temp146 = require("./MF.svg");
const _temp147 = require("./MG.svg");
const _temp148 = require("./MH.svg");
const _temp149 = require("./MK.svg");
const _temp150 = require("./ML.svg");
const _temp151 = require("./MM.svg");
const _temp152 = require("./MN.svg");
const _temp153 = require("./MO.svg");
const _temp154 = require("./MP.svg");
const _temp155 = require("./MQ.svg");
const _temp156 = require("./MR.svg");
const _temp157 = require("./MS.svg");
const _temp158 = require("./MT.svg");
const _temp159 = require("./MU.svg");
const _temp160 = require("./MV.svg");
const _temp161 = require("./MW.svg");
const _temp162 = require("./MX.svg");
const _temp163 = require("./MY.svg");
const _temp164 = require("./MZ.svg");
const _temp165 = require("./NA.svg");
const _temp166 = require("./NC.svg");
const _temp167 = require("./NE.svg");
const _temp168 = require("./NF.svg");
const _temp169 = require("./NG.svg");
const _temp170 = require("./NI.svg");
const _temp171 = require("./NL.svg");
const _temp172 = require("./NO.svg");
const _temp173 = require("./NP.svg");
const _temp174 = require("./NR.svg");
const _temp175 = require("./NU.svg");
const _temp176 = require("./NZ.svg");
const _temp177 = require("./OM.svg");
const _temp178 = require("./PA.svg");
const _temp179 = require("./PE.svg");
const _temp180 = require("./PF.svg");
const _temp181 = require("./PG.svg");
const _temp182 = require("./PH.svg");
const _temp183 = require("./PK.svg");
const _temp184 = require("./PL.svg");
const _temp185 = require("./PM.svg");
const _temp186 = require("./PN.svg");
const _temp187 = require("./PR.svg");
const _temp188 = require("./PS.svg");
const _temp189 = require("./PT.svg");
const _temp190 = require("./PW.svg");
const _temp191 = require("./PY.svg");
const _temp192 = require("./QA.svg");
const _temp193 = require("./RE.svg");
const _temp194 = require("./RO.svg");
const _temp195 = require("./RS.svg");
const _temp196 = require("./RU.svg");
const _temp197 = require("./RW.svg");
const _temp198 = require("./SA.svg");
const _temp199 = require("./SB.svg");
const _temp200 = require("./SC.svg");
const _temp201 = require("./SD.svg");
const _temp202 = require("./SE.svg");
const _temp203 = require("./SG.svg");
const _temp204 = require("./SH.svg");
const _temp205 = require("./SI.svg");
const _temp206 = require("./SJ.svg");
const _temp207 = require("./SK.svg");
const _temp208 = require("./SL.svg");
const _temp209 = require("./SM.svg");
const _temp210 = require("./SN.svg");
const _temp211 = require("./SO.svg");
const _temp212 = require("./SR.svg");
const _temp213 = require("./SS.svg");
const _temp214 = require("./ST.svg");
const _temp215 = require("./SV.svg");
const _temp216 = require("./SX.svg");
const _temp217 = require("./SY.svg");
const _temp218 = require("./SZ.svg");
const _temp219 = require("./TC.svg");
const _temp220 = require("./TD.svg");
const _temp221 = require("./TF.svg");
const _temp222 = require("./TG.svg");
const _temp223 = require("./TH.svg");
const _temp224 = require("./TJ.svg");
const _temp225 = require("./TK.svg");
const _temp226 = require("./TL.svg");
const _temp227 = require("./TM.svg");
const _temp228 = require("./TN.svg");
const _temp229 = require("./TO.svg");
const _temp230 = require("./TR.svg");
const _temp231 = require("./TT.svg");
const _temp232 = require("./TV.svg");
const _temp233 = require("./TW.svg");
const _temp234 = require("./TZ.svg");
const _temp235 = require("./UA.svg");
const _temp236 = require("./UG.svg");
const _temp237 = require("./UM.svg");
const _temp238 = require("./UN.svg");
const _temp239 = require("./US.svg");
const _temp240 = require("./UY.svg");
const _temp241 = require("./UZ.svg");
const _temp242 = require("./VA.svg");
const _temp243 = require("./VC.svg");
const _temp244 = require("./VE.svg");
const _temp245 = require("./VG.svg");
const _temp246 = require("./VI.svg");
const _temp247 = require("./VN.svg");
const _temp248 = require("./VU.svg");
const _temp249 = require("./WF.svg");
const _temp250 = require("./WS.svg");
const _temp251 = require("./XK.svg");
const _temp252 = require("./YE.svg");
const _temp253 = require("./YT.svg");
const _temp254 = require("./ZA.svg");
const _temp255 = require("./ZM.svg");
const _temp256 = require("./ZW.svg");
module.exports = {
  "AD": _temp0,
  "AE": _temp1,
  "AF": _temp2,
  "AG": _temp3,
  "AI": _temp4,
  "AL": _temp5,
  "AM": _temp6,
  "AO": _temp7,
  "AQ": _temp8,
  "AR": _temp9,
  "AS": _temp10,
  "AT": _temp11,
  "AU": _temp12,
  "AW": _temp13,
  "AX": _temp14,
  "AZ": _temp15,
  "BA": _temp16,
  "BB": _temp17,
  "BD": _temp18,
  "BE": _temp19,
  "BF": _temp20,
  "BG": _temp21,
  "BH": _temp22,
  "BI": _temp23,
  "BJ": _temp24,
  "BL": _temp25,
  "BM": _temp26,
  "BN": _temp27,
  "BO": _temp28,
  "BQ": _temp29,
  "BR": _temp30,
  "BS": _temp31,
  "BT": _temp32,
  "BV": _temp33,
  "BW": _temp34,
  "BY": _temp35,
  "BZ": _temp36,
  "CA": _temp37,
  "CC": _temp38,
  "CD": _temp39,
  "CF": _temp40,
  "CG": _temp41,
  "CH": _temp42,
  "CI": _temp43,
  "CK": _temp44,
  "CL": _temp45,
  "CM": _temp46,
  "CN": _temp47,
  "CO": _temp48,
  "CR": _temp49,
  "CU": _temp50,
  "CV": _temp51,
  "CW": _temp52,
  "CX": _temp53,
  "CY": _temp54,
  "CZ": _temp55,
  "DE": _temp56,
  "DJ": _temp57,
  "DK": _temp58,
  "DM": _temp59,
  "DO": _temp60,
  "DZ": _temp61,
  "EC": _temp62,
  "EE": _temp63,
  "EG": _temp64,
  "EH": _temp65,
  "ER": _temp66,
  "ES-CT": _temp67,
  "ES": _temp68,
  "ET": _temp69,
  "EU": _temp70,
  "FI": _temp71,
  "FJ": _temp72,
  "FK": _temp73,
  "FM": _temp74,
  "FO": _temp75,
  "FR": _temp76,
  "GA": _temp77,
  "GB-ENG": _temp78,
  "GB-NIR": _temp79,
  "GB-SCT": _temp80,
  "GB-WLS": _temp81,
  "GB": _temp82,
  "GD": _temp83,
  "GE": _temp84,
  "GF": _temp85,
  "GG": _temp86,
  "GH": _temp87,
  "GI": _temp88,
  "GL": _temp89,
  "GM": _temp90,
  "GN": _temp91,
  "GP": _temp92,
  "GQ": _temp93,
  "GR": _temp94,
  "GS": _temp95,
  "GT": _temp96,
  "GU": _temp97,
  "GW": _temp98,
  "GY": _temp99,
  "HK": _temp100,
  "HM": _temp101,
  "HN": _temp102,
  "HR": _temp103,
  "HT": _temp104,
  "HU": _temp105,
  "ID": _temp106,
  "IE": _temp107,
  "IL": _temp108,
  "IM": _temp109,
  "IN": _temp110,
  "IO": _temp111,
  "IQ": _temp112,
  "IR": _temp113,
  "IS": _temp114,
  "IT": _temp115,
  "JE": _temp116,
  "JM": _temp117,
  "JO": _temp118,
  "JP": _temp119,
  "KE": _temp120,
  "KG": _temp121,
  "KH": _temp122,
  "KI": _temp123,
  "KM": _temp124,
  "KN": _temp125,
  "KP": _temp126,
  "KR": _temp127,
  "KW": _temp128,
  "KY": _temp129,
  "KZ": _temp130,
  "LA": _temp131,
  "LB": _temp132,
  "LC": _temp133,
  "LI": _temp134,
  "LK": _temp135,
  "LR": _temp136,
  "LS": _temp137,
  "LT": _temp138,
  "LU": _temp139,
  "LV": _temp140,
  "LY": _temp141,
  "MA": _temp142,
  "MC": _temp143,
  "MD": _temp144,
  "ME": _temp145,
  "MF": _temp146,
  "MG": _temp147,
  "MH": _temp148,
  "MK": _temp149,
  "ML": _temp150,
  "MM": _temp151,
  "MN": _temp152,
  "MO": _temp153,
  "MP": _temp154,
  "MQ": _temp155,
  "MR": _temp156,
  "MS": _temp157,
  "MT": _temp158,
  "MU": _temp159,
  "MV": _temp160,
  "MW": _temp161,
  "MX": _temp162,
  "MY": _temp163,
  "MZ": _temp164,
  "NA": _temp165,
  "NC": _temp166,
  "NE": _temp167,
  "NF": _temp168,
  "NG": _temp169,
  "NI": _temp170,
  "NL": _temp171,
  "NO": _temp172,
  "NP": _temp173,
  "NR": _temp174,
  "NU": _temp175,
  "NZ": _temp176,
  "OM": _temp177,
  "PA": _temp178,
  "PE": _temp179,
  "PF": _temp180,
  "PG": _temp181,
  "PH": _temp182,
  "PK": _temp183,
  "PL": _temp184,
  "PM": _temp185,
  "PN": _temp186,
  "PR": _temp187,
  "PS": _temp188,
  "PT": _temp189,
  "PW": _temp190,
  "PY": _temp191,
  "QA": _temp192,
  "RE": _temp193,
  "RO": _temp194,
  "RS": _temp195,
  "RU": _temp196,
  "RW": _temp197,
  "SA": _temp198,
  "SB": _temp199,
  "SC": _temp200,
  "SD": _temp201,
  "SE": _temp202,
  "SG": _temp203,
  "SH": _temp204,
  "SI": _temp205,
  "SJ": _temp206,
  "SK": _temp207,
  "SL": _temp208,
  "SM": _temp209,
  "SN": _temp210,
  "SO": _temp211,
  "SR": _temp212,
  "SS": _temp213,
  "ST": _temp214,
  "SV": _temp215,
  "SX": _temp216,
  "SY": _temp217,
  "SZ": _temp218,
  "TC": _temp219,
  "TD": _temp220,
  "TF": _temp221,
  "TG": _temp222,
  "TH": _temp223,
  "TJ": _temp224,
  "TK": _temp225,
  "TL": _temp226,
  "TM": _temp227,
  "TN": _temp228,
  "TO": _temp229,
  "TR": _temp230,
  "TT": _temp231,
  "TV": _temp232,
  "TW": _temp233,
  "TZ": _temp234,
  "UA": _temp235,
  "UG": _temp236,
  "UM": _temp237,
  "UN": _temp238,
  "US": _temp239,
  "UY": _temp240,
  "UZ": _temp241,
  "VA": _temp242,
  "VC": _temp243,
  "VE": _temp244,
  "VG": _temp245,
  "VI": _temp246,
  "VN": _temp247,
  "VU": _temp248,
  "WF": _temp249,
  "WS": _temp250,
  "XK": _temp251,
  "YE": _temp252,
  "YT": _temp253,
  "ZA": _temp254,
  "ZM": _temp255,
  "ZW": _temp256
}