const _temp0 = require("./about.html");
const _temp1 = require("./contact.html");
const _temp2 = require("./credits.html");
const _temp3 = require("./data_sources.html");
const _temp4 = require("./faqs.html");
const _temp5 = require("./further_resources.html");
const _temp6 = require("./getting_started.html");
const _temp7 = require("./methodology.html");
const _temp8 = require("./overview.html");
const _temp9 = require("./terms_of_use.html");
const _temp10 = require("./user_guide.html");
module.exports = {
  "about.html": _temp0,
  "contact.html": _temp1,
  "credits.html": _temp2,
  "data_sources.html": _temp3,
  "faqs.html": _temp4,
  "further_resources.html": _temp5,
  "getting_started.html": _temp6,
  "methodology.html": _temp7,
  "overview.html": _temp8,
  "terms_of_use.html": _temp9,
  "user_guide.html": _temp10
}