import { Component } from "react";
import T from "prop-types";

import MetaTags from "../components/MetaTags";
import PageHeader from "../components/PageHeader";
import GlobalLoading from "../components/GlobalLoading";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { InView } from "react-intersection-observer";

import { environment, appTitle, appDescription } from "../config";

class App extends Component {
  render() {
    const {
      pageTitle,
      className,
      children,
      location,
      hamburgerOnClick,
      onHeaderInView,
    } = this.props;

    const title = pageTitle ? `${pageTitle} — ` : "";
    return (
      <div className={`page ${className || ""}`}>
        <GlobalLoading />
        <MetaTags title={`${title}${appTitle} `} description={appDescription} />
        <PageHeader location={location} hamburgerOnClick={hamburgerOnClick} />
        {onHeaderInView ? (
          <InView
            as="span"
            onChange={(inView, entry) =>
              onHeaderInView ? onHeaderInView(inView, entry) : false
            }
          ></InView>
        ) : (
          ""
        )}
        <main className="page__body" role="main">
          {children}
        </main>
        <Tooltip
          id="infotip-global"
          className="infotip-global"
          place="right"
          multiline
          clickable={false}
          effect="float"
          float
          variant="light"
          border
          borderColor="#33465e"
        />
      </div>
    );
  }
}

if (environment !== "production") {
  App.propTypes = {
    className: T.string,
    pageTitle: T.string,
    location: T.object,
    children: T.node,
    hamburgerOnClick: T.func,
  };
}

export default App;
