import { useState } from "react";
import { PropTypes as T } from "prop-types";

import NavGlobalMenu from "./NavGlobalMenu";
import useMediaQuery from "../useMediaQuery";

import { environment } from "../config";

export default function PageHeader() {
  const [expanded, setExpanded] = useState(false);
  const hamburgerOnClick = () => setExpanded((e) => !e);
  const isSmall = useMediaQuery("(max-width: 1023px)");
  return (
    <header className="page__header" role="banner">
      <div className="page__headline">
        <nav
          className={`page__prime-nav nav ${
            expanded ? "nav-expanded" : "nav-collapsed"
          }`}
          role="navigation"
        >
          <NavGlobalMenu
            forHeader
            isSmall={isSmall}
            hamburgerOnClick={hamburgerOnClick}
            isExpanded={expanded}
          />
        </nav>
      </div>
    </header>
  );
}

if (environment !== "production") {
  PageHeader.propTypes = {
    location: T.object,
    hamburgerOnClick: T.func,
  };
}
