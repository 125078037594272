import { Component } from "react";
import { PropTypes as T } from "prop-types";

import { environment } from "../config";

import Tabs from "./Tabs";
import ShadowScrollbars from "./ShadowScrollbar";

class DataSnapshotTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
    };
  }

  render() {
    const { activeTab } = this.state;
    const {
      selectedCountry,
      handleSelectedCountry,
      data,
      OverviewTab,
      CountryFocusTab,
    } = this.props;

    const tabs = [
      {
        title: "Global Overview",
        tab_name: "Overview",
        contents: () => <OverviewTab {...this.props} />,
      },
      {
        title: "Country Focus",
        tab_name: "Country Focus",
        contents: () => (
          <CountryFocusTab
            {...this.props}
            iso3={selectedCountry}
            onChange={handleSelectedCountry}
            data={data}
          />
        ),
      },
    ];

    return (
      <Tabs
        className="inpage__height"
        tabBodyClassName="sidebar-tab-body-wrapper"
        tabList={tabs}
        activeTab={activeTab}
        onChange={(e) => {
          this.setState({ activeTab: e });
        }}
      />
    );
  }

  componentDidUpdate(oldProps, oldState) {
    if (this.props.selectedCountry !== oldProps.selectedCountry) {
      if (this.props.selectedCountry) {
        this.setState({ activeTab: 1 });
      } else {
        this.setState({ activeTab: 0 });
      }
    }
    if (this.state.activeTab == 0 && oldState.activeTab == 1) {
      this.props.handleSelectedCountry(undefined);
    }
  }
}

if (environment !== "production") {
  DataSnapshotTabs.propTypes = {
    data: T.object,
    selectedCountry: T.string,
    handleSelectedCountry: T.func,
    OverviewTab: T.func,
    CountryFocusTab: T.func,
  };
}

export { DataSnapshotTabs };
