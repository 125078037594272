import ReMap from "./ReMap";
import { scaleLinear } from "@visx/scale";

export const ChloroplethMap = (props) => {
  const { colorRange = ["white", "#00a66b"], data = {} } = props;

  const colorMap = scaleLinear({
    domain: [0, Math.max(...Object.values(data))],
    range: colorRange,
  });

  const country = ["get", "ISO_A3"];

  const country_in_chain = [
    "in",
    country,
    ["literal", Object.keys(data).filter((e) => e)],
  ];
  const colorMatch = [
    "match",
    country,
    ...Object.entries(data)
      .map(([c, v]) => [c, colorMap(v)])
      .flat(),
    "rgba(0,0,0,0)",
  ];

  const style = {
    paint: {
      "fill-opacity": 0.75,
      "fill-color": colorMatch,
    },
  };

  const externalLayers = [
    {
      id: `chloropleth`,
      key: `chloropleth`,
      label: "Chloropleth",
      type: "existing",
      existingLayer: "wb-adm0-fill",
      beforeId: "wb-adm0-fill",

      // stuff we could get dynamically after the map's been loaded
      // But we need one refresh first, so preload
      sourceType: "fill",
      "source-layer": "WB_GAD_ADM0-1dma96",
      source: "composite",

      filter: country_in_chain,
      ...style,
    },
  ];

  return (
    <ReMap
      {...props}
      externalLayers={externalLayers}
      layersState={externalLayers.map(() => true)}
    />
  );
};
