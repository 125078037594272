import { environment } from "../../../config";

import {
  CountryStackedBar,
  CountryLegend,
} from "../../CountryYearlyStackedBar";
import { SingleBar } from "../../SingleBar";

export const DataOverviewTab = ({ data, units, colors }) => {
  return (
    <>
      <div key="graphite_production" style={{ height: "20vh", width: "100%" }}>
        <CountryStackedBar
          title="Graphite Production"
          data={data}
          selectedField="graphite_production"
          units={units}
          colors={colors}
          showLegend={false}
        />
      </div>
      <div key="graphite_reserves" style={{ height: "20vh", width: "100%" }}>
        <CountryStackedBar
          title="Graphite Reserves"
          data={data.map(({ graphite_reserve, ...point }) => ({
            graphite_reserve: graphite_reserve / 1000,
            ...point,
          }))}
          selectedField="graphite_reserve"
          units="million tons"
          colors={colors}
          showLegend={false}
        />
      </div>
      <CountryLegend
        colors={colors}
        data={data}
        selectedField="graphite_reserve"
      />
      <GlobalData data={data} />
    </>
  );
};

const _sumField = (data, field) =>
  data
    .filter((e) => e[field])
    .map((e) => e[field])
    .reduce((a, b) => a + b, 0);

const _formatter = Intl.NumberFormat("en-US");
const fmt = (x) => _formatter.format(x.toFixed(0));

export const GlobalData = ({ data }) => {
  const currentYear = Math.max(...data.map((e) => e.year));
  const currentYearData = data.filter((e) => e.year == currentYear);

  const totalReserves = _sumField(currentYearData, "graphite_reserve");
  const totalProduction = _sumField(currentYearData, "graphite_production");

  return (
    <div style={{ marginTop: "1rem" }}>
      <h2>Global Data ({currentYear})</h2>
      <h3>Production as a percentage of Reserves:</h3>
      <SingleBar value_frac={totalProduction / totalReserves} places={2} />
      <p style={{ marginTop: "1rem", fontSize: "1.125rem" }}>
        Total Production: {fmt(totalProduction)} (thousand tons)
        <br />
        Total Reserves: {fmt(totalReserves)} (thousand tons)
      </p>
    </div>
  );
};

if (environment !== "production") {
  DataOverviewTab.propTypes = {};
}
