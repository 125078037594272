import { PropTypes as T } from "prop-types";

import { environment } from "../config";

export const SingleBar = (props) => {
  const {
    fgColor,
    bgColor,
    value_frac,
    value_pct = 0,
    className,
    places = 0,
  } = props;

  const value = typeof value_frac == "number" ? value_frac * 100 : value_pct;
  const valueStr = `${value.toFixed(places)}%`;

  const small = value < 10;

  return (
    <div
      className={`singlebar__container ${className || ""}`}
      style={{ position: "relative" }}
    >
      <div className="outer-bar" style={{ width: "100%", background: bgColor }}>
        <div
          className="inner-bar"
          style={{ width: valueStr, background: fgColor }}
        >
          {!small ? <span className="value">{valueStr}</span> : ""}
        </div>
        {small ? <div className="value">{valueStr}</div> : ""}
      </div>
    </div>
  );
};

if (environment !== "production") {
  SingleBar.propTypes = {
    fgColor: T.string,
    bgColor: T.string,
    value_pct: T.number,
    value_frac: T.number,
    className: T.string,
  };
}

export default SingleBar;
