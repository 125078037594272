import { environment } from "../../../config";

import { countryForIso } from "../../../iso3";
import { StageMarker } from "../StageMarker";

const Panel = ({ stage, highlightStage, title, _fmt, children }) => {
  const highlightClass = stage == highlightStage ? "highlight" : "";
  return (
    <div className={`overview__panel ${highlightClass}`}>
      <StageMarker stage={stage} className="header_img_marker" />
      <h3 className="side_icon">{title}</h3>
      {children}
    </div>
  );
};

const OverviewTab = ({ chain, highlightStage }) => {
  return (
    <div className="tab__sidebar tab__overview">
      <h2>How the lithium supply chain works</h2>
      <Panel
        highlightStage={highlightStage}
        stage="extraction"
        title="Lithium Extraction"
        key="p-extraction"
      >
        <p>
          Lithium occurs in: hard rock, including spodumene; and in brines with
          different extraction methods:
        </p>
        <ul>
          <li>
            <p>
              <span className="bbold">Brine</span> extraction involves pumping
              out brine and concentrating by solar evaporation
            </p>
          </li>
          <li>
            <p>
              <span className="bbold">Hard rock</span> (such as Spodumene)
              extraction involves digging out hard rock and concentrating it
            </p>
          </li>
        </ul>
        <p>
          The largest lithium producer is AUS, followed by CHL. CHN and ARG also
          produce while countries such as the USA, BRA and ZWE have reserves but
          production is currently low.
        </p>
      </Panel>
      <Panel
        highlightStage={highlightStage}
        key="p-processing"
        stage="processing"
        title="Lithium Processing"
      >
        <p>
          Lithium is processed into: lithium carbonate usually from brine or
          lithium hydroxide usually from spodumene. Both are used to make li-ion
          batteries.
        </p>
        <ul>
          <li>
            <p>
              Brine is processed into lithium carbonate with the addition of
              other chemicals; it can further be processed into lithium
              hydroxide
            </p>
          </li>
          <li>
            <p>
              Processing of hard rock involves roasting and mixing with
              chemicals and can be transformed into lithium carbonate or
              hydroxide in one stage
            </p>
          </li>
        </ul>
        <p>
          Most processing takes place in CHN, with almost all spodumene
          processed through this route, although AUS is building processing
          capacity. CHL’s brine is mostly processed in country
        </p>
      </Panel>
      <Panel
        highlightStage={highlightStage}
        key="p-battery_assembly"
        stage="battery_assembly"
        title="Battery Manufacturing"
      >
        <p>
          Cathodes for li-on batteries are generally a mix of lithium and other
          minerals including nickel (N), manganese (M), cobalt (C) or iron (Fe).
          Ex: NMC111 batteries use lithium and an equal mix N, M, C.
        </p>
        <ul>
          <li>
            <p>
              Some batteries generally rely on{" "}
              <strong>
                lithium <em>carbonate</em>
              </strong>{" "}
              to make their cathodes (NMC111, NMC622, LMO) while others use{" "}
              <strong>
                lithium <em>hydroxide</em>
              </strong>{" "}
              (NMC811, NCA, LFP)
            </p>
          </li>
        </ul>
        <p>
          Battery manufacturing includes making the cathode, anode and
          electrolyte, as well as assembling the components to form a battery
          cell. Battery cells are then incorporated into battery packs composed
          of modules and protection systems that form the final battery.
        </p>
        <p>
          Battery manufacturing today mainly occurs in Asia, especially in CHN,
          KOR and JPN, with growing production starting to occur in other
          regions, such as the EU and USA.
        </p>
      </Panel>
      <Panel
        highlightStage={highlightStage}
        key="p-vehicle_assembly"
        stage="vehicle_assembly"
        title="Vehicle Assembly"
      >
        <p>
          Vehicle assembly involves a range of different processes beyond the
          battery that are energy intensive to bring together different minerals
          found in an EV (e.g., chassis). The largest assembly locations are
          currently in CHN, EU and the USA.
        </p>
        <p>
          After vehicle assembly is completed, a critical aspect of EVs is the
          source of energy used to power these vehicles. This aspect is not
          currently captured in the dashboard.
        </p>
      </Panel>
      <Panel
        highlightStage={highlightStage}
        key="p-shipping"
        stage="final_shipping"
        title="Shipping"
      >
        <p>
          Shipping is a key component of lithium supply chains for EVs. Minerals
          need to be trucked around mine sites, moved to ports, and shipped
          between countries.
        </p>
        <p>
          Transporting millions of tons of minerals and EVs across the world has
          a large carbon footprint in total, but is only a small percentage of
          the emissions of any one EV.
        </p>
      </Panel>
    </div>
  );
};

if (environment !== "production") {
  OverviewTab.propTypes = {};
}

export default OverviewTab;
