import { useState, useCallback, useEffect } from "react";
import { ParentSize } from "@visx/responsive";
import SingleBar from "../SingleBar";
import { CountryStackedBar } from "../CountryYearlyStackedBar";
import useMediaQuery from "../../useMediaQuery";

import lithiumSupplyDemandCsv from "bundle-text:/data/lithium_supply_demand.csv";
import graphiteSupplyDemandCsv from "bundle-text:/data/graphite_supply_demand.csv";
import evSupplyDemandCsv from "bundle-text:/data/ev_supply_demand.csv";
import { parse as csvParse } from "csv-parse/lib/sync";

const parse = (csv) =>
  csvParse(csv, {
    columns: true,
    cast: true,
  });

const data = {
  lithium: parse(lithiumSupplyDemandCsv),
  graphite: parse(graphiteSupplyDemandCsv),
  ev: parse(evSupplyDemandCsv),
};

export const countries = [...new Set([
  ...data.lithium.filter(e => e.lithium_production || e.lithium_reserve).map(e => e.country),
  ...data.graphite.filter(e => e.graphite_production || e.graphite_reserve).map(e => e.country)
])];

const ResourceChart = ({ title, data, field, width, country }) => {
  const isMillions = data.some((e) => e[field] > 1000);
  return (
    <div style={{ height: "30vh", width }}>
      <CountryStackedBar
        title={title}
        data={
          isMillions
            ? data.map((e) => ({ ...e, [field]: e[field] / 1000 }))
            : data
        }
        selectedField={field}
        units={isMillions ? " million tons" : " thousand tons"}
        showLegend={false}
        colors={{ [country]: "#fed46f" }}
        width={width}
      />
    </div>
  );
};

const GlobalContext = ({ data, resource, width, country }) => {
  const year = Math.max(...data.map((e) => e.year));
  const yearData = data.filter((e) => e.year == year);
  const countryData = yearData.filter((e) => e.country == country);
  const sumField = (data, field) =>
    data
      .map((e) => e[field])
      .filter((e) => e)
      .reduce((a, b) => a + b, 0);
  const country_production = sumField(countryData, `${resource}_production`);
  const global_production = sumField(yearData, `${resource}_production`);
  const country_reserve = sumField(countryData, `${resource}_reserve`);
  const global_reserve = sumField(yearData, `${resource}_reserve`);
  const resources = sumField(countryData, `${resource}_resources`);
  return (
    <div className="countries-data-global-context" style={{ width }}>
      <h6>Global Context ({year})</h6>
      <div className="countries-data-global-context-bars">
        <p>Percentage of Global Production</p>
        <SingleBar
          places={2}
          value_pct={(country_production / global_production) * 100}
        />
        <p>Percentage of Global Reserve</p>
        <SingleBar
          places={2}
          value_pct={(country_reserve / global_reserve) * 100}
        />
        <p>Percentage Production of Reserve</p>
        <SingleBar
          places={2}
          value_pct={(country_production / country_reserve) * 100}
        />
        {resource == "lithium" ? (
          <p>Lithium Type: {countryData[0].lithium_type}</p>
        ) : null}
        <p>Production: {country_production} (thousand tons)</p>
        <p>Reserves: {country_reserve} (thousand tons)</p>
        {resources > 0 ? <p>Resources: {resources} (thousand tons)</p> : null}
      </div>
    </div>
  );
};

const resource = (resource, filteredData, country, isSmallScreen) => {
  const res_lower = resource.toLowerCase();
  const has = (e, field) => e[field] && e[field] !== "" && e[field] > 0;
  const show = filteredData[res_lower].some(
    (e) => has(e, `${res_lower}_reserve`) || has(e, `${res_lower}_production`)
  );
  if (show) {
    return (
      <div style={{ width: "100%" }}>
        <h3 className="countries-data-resource-header">{resource}</h3>
        <ParentSize>
          {({ width }) => {
            const chartWidth = isSmallScreen ? width : width / 3;
            return (
              <div className="countries-data-resource">
                <ResourceChart
                  title={`${resource} Production`}
                  field={`${res_lower}_production`}
                  data={filteredData[res_lower]}
                  width={chartWidth}
                  country={country}
                />
                <ResourceChart
                  title={`${resource} Reserve`}
                  field={`${res_lower}_reserve`}
                  data={filteredData[res_lower]}
                  width={chartWidth}
                  country={country}
                />
                <GlobalContext
                  data={data[res_lower]}
                  resource={res_lower}
                  width={chartWidth}
                  country={country}
                />
              </div>
            );
          }}
        </ParentSize>
      </div>
    );
  }
};

const Data = ({ country }) => {
  const filteredData = {
    lithium: data.lithium.filter((e) => e.country == country),
    graphite: data.graphite.filter((e) => e.country == country),
  };
  const isSmallScreen = useMediaQuery("(max-width: 768px)");

  const lithium = resource("Lithium", filteredData, country, isSmallScreen);
  const graphite = resource("Graphite", filteredData, country, isSmallScreen);

  return (
    <div className="countries-data">
      <h1 id="data" className="country_details_header">
        Data
      </h1>
      {lithium}
      {graphite}
      {lithium || graphite ? null : "No production data is available"}
    </div>
  );
};

export default Data;
