import { Component } from "react";
import { PropTypes as T } from "prop-types";

import { environment } from "../../config";
import Dropdown from "../Dropdown";
import { countryForIso } from "../../iso3";

class CountryDropdown extends Component {
  render() {
    const {
      countryData,
      selectedCountry,
      onChange,
      group = () => "",
    } = this.props;
    const grouped = {};
    Object.keys(countryData).forEach((iso) => {
      const [groupId, groupName] = group(iso);
      if (!grouped[groupId]) {
        grouped[groupId] = { name: groupName, countries: [] };
      }
      // Sort this by mapping the keys to [Name, ISO], which will sort natively.
      // And we need them both anyway.
      grouped[groupId].countries.push([countryForIso(iso), iso]);
    });
    Object.values(grouped).forEach(({ countries }) => countries.sort());

    return (
      <Dropdown
        className="dropdown__country_share-menu"
        triggerClassName="dropdown__country"
        triggerActiveClassName="button--active"
        triggerText={countryForIso(selectedCountry)}
        triggerTitle="Select Country"
        direction="down"
        alignment="left"
        ref={(r) => (this.dropdownRef = r)}
      >
        <ul className="drop__menu">
          {Object.entries(grouped)
            .sort()
            .map(([id, { name, countries }]) => [
              ...(Object.keys(grouped).length > 1
                ? [
                    <li key={id}>
                      <a className="drop__menu-item disabled">{name}</a>
                    </li>,
                  ]
                : []),
              countries.map(([name, iso]) => (
                <li key={iso}>
                  <a
                    className="drop__menu-item"
                    onClick={() => {
                      onChange && onChange(iso);
                      this.dropdownRef.close();
                    }}
                  >
                    <span>{name}</span>
                  </a>
                </li>
              )),
            ])}
        </ul>
      </Dropdown>
    );
  }
}

if (environment !== "production") {
  CountryDropdown.propTypes = {
    results: T.object,
    context: T.string,
    leftElement: T.object,
  };
}

export { CountryDropdown };
