import { useParams, useNavigate, Link } from "react-router-dom";
import { CountryDropdown } from "../components/chains/CountryDropdown";
import { CountryFlag } from "../components/CountryFlag";
import { countryForIso } from "../iso3";
import App from "./App";

import { useCallback } from "react";
import {
  General,
  Data,
  countries as countryList,
} from "../components/countryDetail";
import FurtherInformation from "../components/countryDetail/FurtherInformation";

const countries = Object.fromEntries(
  countryList.map((c) => [c, countryForIso(c)])
);

export const CountrySelect = () => {
  return (
    <App pageTitle="Countries">
      <div className="country-select-page">
        <h1>Explore Country</h1>
        <h4>
          Click on any country from the list below to view a country-level summary of the
          information and data covered in this platform
        </h4>
        <ul className="country-select">
          {Object.entries(countries)
            .sort()
            .map(([iso, country]) => (
              <li key={iso} className="country-select-country">
                <Link to={`/countries/${iso}`}>
                  <CountryFlag iso3={iso} className="country-select-flag" />
                  <span className="country-select-country-label">
                    {country}
                  </span>
                </Link>
              </li>
            ))}
        </ul>
      </div>
    </App>
  );
};

export const CountryDetail = () => {
  const { country: iso3 } = useParams();
  const country = countryForIso(iso3);
  const navigate = useNavigate();
  const changeCountry = useCallback((iso) => navigate(`/countries/${iso}`));
  return (
    <App pageTitle={`Country Data: ${country}`}>
      <article className="inpage inpage--countries">
        <header className="inpage__header">
          <div className="inpage__subheader">
            <div className="inpage__headline">
              <h1 className="inpage__title countries-title">
                <CountryDropdown
                  countryData={countries}
                  selectedCountry={iso3}
                  onChange={changeCountry}
                />
                <CountryFlag iso3={iso3} className="countries-detail-flag" />
              </h1>
            </div>
          </div>
        </header>
        <NavMenu />
        <div className="inpage__body">
          <General country={iso3} />
          <Data country={iso3} />
          <FurtherInformation country={iso3} />
        </div>
      </article>
    </App>
  );
};

const contents = [
  { id: "general", name: "General Information" },
  { id: "data", name: "Data" },
  { id: "further_information", name: "Further Information" },
];

export const NavMenu = () => {
  return (
    <div className="side-nav nav">
      <ul className="sticky-nav">
        {contents.map(({ id, name }, idx) => (
          <li key={idx}>
            <a href={`#${id}`} className="side-nav__link">
              <span>{name}</span>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};
