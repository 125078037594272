import { Component, createRef } from "react";
import { PropTypes as T } from "prop-types";

import { mapboxAccessToken, environment, basemapStyleLink } from "../../config";

import { WebMercatorViewport } from "@math.gl/web-mercator";

import ReactMapGL, {
  NavigationControl,
  Source,
  Layer,
  Popup,
  Marker,
} from "react-map-gl";

/**
 * Id of the last "topmost" layer, before which all GEP layers
 * should be added. This is needed to show place names and borders above
 * all other layers.
 **/
const labelsAndBordersLayer = "wb-boundaries";

// Adds layers for points
const buildLayersForSource = (sourceId) => [
  {
    id: `${sourceId}-line`,
    key: `${sourceId}-line`,
    type: "line",
    source: sourceId,
    filter: ["==", "$type", "LineString"],
    layout: {
      visibility: "none",
    },
    paint: {
      "line-color": "red",
    },
  },
  {
    id: `${sourceId}-polygon`,
    key: `${sourceId}-polygon`,
    type: "fill",
    source: sourceId,
    filter: ["==", "$type", "Polygon"],
    layout: {
      visibility: "none",
    },
    paint: {
      "fill-color": "blue",
    },
  },
  {
    id: `${sourceId}-point`,
    key: `${sourceId}-point`,
    type: "circle",
    source: sourceId,
    filter: ["==", "$type", "Point"],
    paint: {
      "circle-color": "purple",
    },
  },
];

class ReMap extends Component {
  constructor(props) {
    super(props);

    const { layersState, initialBounds } = props;

    const base = new WebMercatorViewport({
      width: window.innerWidth,
      height: window.innerHeight,
    });
    const vp = base.fitBounds(initialBounds.map((e) => [e.lon, e.lat]));
    const { latitude, longitude, zoom } = vp;
    const smallScreen = window.innerWidth <= 600;

    this.state = {
      layersState,
      initialViewport: { latitude: latitude, longitude: longitude, zoom: zoom },
      lngLat: undefined,
      showDetail: false,
      smallScreen: smallScreen,
      cursor: "auto",
    };

    this.renderLayer = this.renderLayer.bind(this);
    this.renderExistingLayer = this.renderExistingLayer.bind(this);
    this.maybeRenderLayer = this.maybeRenderLayer.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.hide = this.hide.bind(this);

    this.mapRef = createRef();
    this.hovered = [];
  }

  onClick(e) {
    if (
      e.target.className === "mapboxgl-popup-close-button" ||
      Object.keys(e.target.dataset ?? {}).length
    ) {
      return;
    }

    const { handleSelectedLocation, selectedLayer } = this.props;
    const selectedLayerId = selectedLayer && `ext-${selectedLayer.id}`;
    const features = selectedLayer
      ? e.features.filter((f) => f.source == selectedLayerId)
      : e.features;

    this.setState({
      showDetail: true,
      lngLat: e.lngLat,
      point: e.point,
      selectedFeatures: features,
    });

    handleSelectedLocation && handleSelectedLocation(e.lngLat, features);
  }

  onMouseMove(e) {
    for (const feature of this.hovered) {
      this.mapRef.current.setFeatureState(feature, { hovered: null });
    }
    this.hovered = e.features.filter((e) => e.id);
    for (const feature of this.hovered) {
      this.mapRef.current.setFeatureState(feature, { hovered: true });
    }
    this.props.onHover && this.props.onHover(e.features, e.lngLat);
  }

  onMouseEnter() {
    this.setState({ cursor: "pointer" });
  }

  onMouseLeave() {
    this.setState({ cursor: "auto" });
  }

  maybeRenderLayer(layer, idx) {
    const { layersState } = this.props;
    if (!layersState[idx]) {
      return "";
    }
    return this.renderLayer(layer);
  }

  renderGeoJsonLayer(layer) {
    // Add layers.
    // Layers come from the model. Each layer object must have:
    // id:            Id of the layer
    // label:         Label for display
    // type:          (geojson)
    // url:           Url to a tilejson or mapbox://. Use interchangeably with tiles

    if (!(layer.url || layer.data)) {
      // eslint-disable-next-line no-console
      console.warn(`Layer [${layer.label}] must have (url) property.`);
      return "";
    }

    const sourceId = `ext-${layer.id}`;
    let options = {
      type: "geojson",
      id: sourceId,
      key: sourceId,
      data: layer.url || layer.data,
    };

    let layers;

    if (layer.style) {
      layers = (
        <Layer
          id={layer.id}
          key={`${sourceId}-point`}
          source={sourceId}
          {...layer.style}
        />
      );
    } else {
      layers = buildLayersForSource(sourceId, "").map((l) => (
        <Layer
          key={l.id}
          {...l}
          visible={true}
          beforeId={labelsAndBordersLayer}
        ></Layer>
      ));
    }

    return (
      <Source {...options} key={sourceId}>
        {layers}
      </Source>
    );
  }

  renderVectorLayer(layer) {
    // Add layers.
    // Layers come from the model. Each layer object must have:
    // id:            Id of the layer
    // label:         Label for display
    // type:          (vector|raster)
    // url:           Url to a tilejson or mapbox://. Use interchangeably with tiles
    // tiles:         Array of tile url. Use interchangeably with url
    // vectorLayers:  Array of source layers to show. Only in case of type vector
    if (!layer.vectorLayers || !layer.vectorLayers.length) {
      // eslint-disable-next-line no-console
      console.warn(
        `Layer [${layer.label}] has missing (vectorLayers) property.`
      );
      return "";
    }
    if ((!layer.tiles || !layer.tiles.length) && !layer.url) {
      // eslint-disable-next-line no-console
      console.warn(
        `Layer [${layer.label}] must have (url) or (tiles) property.`
      );
      return "";
    }

    const sourceId = `ext-${layer.id}`;
    let options = { type: "vector", id: sourceId, key: sourceId };

    if (layer.tiles) {
      options.tiles = layer.tiles;
    } else if (layer.url) {
      options.url = layer.url;
    }

    //const vectorLayers = layer.vectorLayers || [ undefined ];

    return (
      <Source {...options} key={sourceId}>
        {layer.vectorLayers.map((vt) =>
          buildLayersForSource(sourceId, vt).map((l) => (
            <Layer
              key={l.id}
              {...l}
              sourceLayer={vt}
              visible={true}
              beforeId={labelsAndBordersLayer}
            ></Layer>
          ))
        )}
      </Source>
    );
  }

  // Raster layer type.
  renderRasterLayer(layer) {
    // Add layers.
    // Layers come from the model. Each layer object must have:
    // id:            Id of the layer
    // label:         Label for display
    // type:          (vector|raster)
    // url:           Url to a tilejson or mapbox://. Use interchangeably with tiles
    // tiles:         Array of tile url. Use interchangeably with url
    // vectorLayers:  Array of source layers to show. Only in case of type vector
    if (!layer.tiles || !layer.tiles.length) {
      // eslint-disable-next-line no-console
      console.warn(`Layer [${layer.label}] must have (tiles) property.`);
      return "";
    }

    const sourceId = `ext-${layer.id}`;
    const layerId = sourceId + "-tiles";
    return (
      <Source
        id={sourceId}
        key={sourceId}
        type="raster"
        tiles={layer.tiles}
        tileSize={512}
      >
        <Layer
          id={layerId}
          key={layerId}
          type="raster"
          visible={true}
          beforeId={labelsAndBordersLayer}
        ></Layer>
      </Source>
    );
  }

  renderExistingLayer(layer) {
    return (
      <Layer
        {...layer}
        key={layer.key}
        type={layer.sourceType}
        /* {... {'source-layer': sourceLayer.sourceLayer}} */
        /*        source={sourceLayer.source} */
        /*        type={sourceLayer.type} */
      ></Layer>
    );
  }

  renderImageLayer({ id, key, url }) {
    return (
      <Source
        type="image"
        id={id}
        url={url}
        key={key}
        coordinates={[
          [0, 0],
          [0, 0],
          [0, 0],
          [0, 0],
        ]}
      ></Source>
    );
  }

  renderLayer(layer) {
    // Add layers.
    // Layers come from the model. Each layer object must have:
    // id:            Id of the layer
    // label:         Label for display
    // type:          (vector|raster|geojson)
    // url:           Url to a geojson, tilejson or mapbox://. Use interchangeably with tiles
    // tiles:         Array of tile url. Use interchangeably with url for vector type
    // vectorLayers:  Array of source layers to show. Only in case of type vector

    if (layer.type === "vector") {
      return this.renderVectorLayer(layer);
    } else if (layer.type === "geojson") {
      return this.renderGeoJsonLayer(layer);
    } else if (layer.type === "raster") {
      return this.renderRasterLayer(layer);
    } else if (layer.type === "existing") {
      return this.renderExistingLayer(layer);
    } else if (layer.type === "image") {
      return this.renderImageLayer(layer);
    } else {
      // eslint-disable-next-line no-console
      console.warn(
        `Layer [${layer.label}] has unsupported type [layer.type] and won't be added.`
      );
      return "";
    }
  }

  hide(e) {
    this.setState({ showDetail: false });
    e.stopPropagation();
    e.preventDefault();
    return true;
  }

  render() {
    const { initialViewport, lngLat, showDetail, smallScreen, cursor } =
      this.state;
    const {
      externalLayers,
      details,
      layersSelector,
      titleComponent,
      selectedLayer,
      marker,
      tooltip,
    } = this.props;

    return (
      <section className="exp-map">
        <h1 className="exp-map__title">Map</h1>
        <ReactMapGL
          ref={this.mapRef}
          reuseMaps={true}
          initialViewState={initialViewport}
          cursor={cursor}
          dragRotate={false}
          mapStyle={basemapStyleLink}
          mapboxAccessToken={mapboxAccessToken}
          onClick={this.onClick}
          interactiveLayerIds={[selectedLayer, ...externalLayers]
            .filter((l) => l)
            .map((l) => l.id)
            .filter((l) => l)}
          clickRadius={2}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
          onMouseMove={this.onMouseMove}
          // projection={{ name: "naturalEarth" }}
        >
          {titleComponent}
          <div className="map-controls__position">
            <NavigationControl
              style={{ position: "relative" }}
              showCompass={false}
            />
          </div>
          {layersSelector && (
            <div className="layer_selector__position">
              <div>{layersSelector}</div>
            </div>
          )}
          {(externalLayers && externalLayers.map(this.maybeRenderLayer)) || ""}
          {(selectedLayer && this.renderLayer(selectedLayer)) || ""}
          {tooltip && (
            <>
              <Popup
                latitude={tooltip.latLng.lat}
                longitude={tooltip.latLng.lng}
                closeButton={false}
              >
                <div style={{ backgroundColor: "#ffff", padding: "5px" }}>
                  {tooltip.contents}
                </div>
              </Popup>
            </>
          )}
          {(!smallScreen && showDetail && details && (
            <>
              <Popup
                latitude={lngLat.lat}
                longitude={lngLat.lng}
                closeButton={true}
                closeOnClick={false}
                onClose={() => this.setState({ showDetail: false })}
              >
                <div style={{ backgroundColor: "#ffff", padding: "5px" }}>
                  {details}
                </div>
              </Popup>
              {(marker && (
                <Marker
                  latitude={lngLat.lat}
                  longitude={lngLat.lng}
                  offsetLeft={-10}
                  offsetTop={-12}
                  className="popup__marker"
                  captureClick={false}
                  captureDoubleClick={false}
                  captureDrag={false}
                  captureScroll={false}
                >
                  <img
                    height={20}
                    width={20}
                    src="/static/graphics/marker.png"
                  />
                </Marker>
              )) ||
                ""}
            </>
          )) ||
            ""}
        </ReactMapGL>
        {smallScreen && showDetail && details && (
          <div
            className="overlay__detail"
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              padding: "1em",
            }}
          >
            <button
              onClick={this.hide}
              className="mapboxgl-popup-close-button"
              type="button"
              //data-event='click'
            >
              ×
            </button>
            {details}
          </div>
        )}
        {this.props.legend && <div>{this.props.legend}</div>}
      </section>
    );
  }
}

if (environment !== "production") {
  ReMap.propTypes = {
    initialBounds: T.array,
    handleLayerChange: T.func,
    handleSelectedLocation: T.func,
    externalLayers: T.array,
    selectedLayer: T.object,
    layersState: T.array,
    details: T.object,
    title: T.string,
    titleComponent: T.object,
    layersSelector: T.object,
  };
}

export default ReMap;
