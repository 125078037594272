import { Component } from "react";
import { PropTypes as T } from "prop-types";
import { Group } from "@visx/group";
import { Arc } from "@visx/shape";
import { arc as d3arc } from "d3-shape"; // UNDONE required?
import { ParentSize } from "@visx/responsive";
import { Text } from "@visx/text";
import { localPoint } from "@visx/event";
import { withTooltip, TooltipWithBounds, defaultStyles } from "@visx/tooltip";
import { hierarchy, Partition } from "@visx/hierarchy";

import { environment } from "../config";

// Sample data, for the shape.
// Count() just counts the leaves
// sum(accessor(x) => val) Sums up the size.
const _data = {
  name: "TOPICS",
  children: [
    {
      name: "Topic A",
      children: [
        {
          name: "Sub A1",
          size: 4,
        },
        {
          name: "Sub A2",
          size: 4,
        },
      ],
    },
    {
      name: "Topic B",
      children: [
        {
          name: "Sub B1",
          size: 3,
        },
        {
          name: "Sub B2",
          size: 3,
        },
        {
          name: "Sub B3",
          size: 3,
        },
      ],
    },
    {
      name: "Topic C",
      children: [
        {
          name: "Sub A1",
          size: 4,
        },
        {
          name: "Sub A2",
          size: 4,
        },
      ],
    },
  ],
};

const _color = () => "#aaa";

/**
 * The Sunburst component
 */

class Sunburst extends Component {
  constructor(props) {
    super(props);

    this.renderChart = this.renderChart.bind(this);
    this.handleMouseOver = this.handleMouseOver.bind(this);
    this.handleZoom = this.handleZoom.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.setZoomFromPath = this.setZoomFromPath.bind(this);
    this.parseData = this.parseData.bind(this);

    const data = this.props.data || _data;

    this.state = {
      inZoom: false,
      root: data,
      path: [],
      allData: data,
    };
  }

  handleMouseOver(event, datum) {
    const coords = localPoint(event.target.ownerSVGElement, event);
    this.props.showTooltip({
      tooltipLeft: coords.x,
      tooltipTop: coords.y,
      tooltipData: datum,
    });
  }

  handleClick(event, node) {
    if (node.depth == 0) {
      return;
    } // click on existing root node
    const { onNodeChange } = this.props;
    onNodeChange &&
      onNodeChange({
        ...node.data,
        parent: (node.data._depth != 1 && node.parent.data) || {},
      });
    this.handleZoom(event, node);
  }

  handleZoom(event, node) {
    const { path: currentPath } = this.state;
    this.props.hideTooltip();

    if (!node.children) {
      return;
    }

    const traverse = (n) => {
      if (n.parent) {
        let parents = traverse(n.parent);
        parents.push(n.data.name);
        return parents;
      }
      return [];
    };

    const path = [...currentPath, ...traverse(node)];
    this.setZoomFromPath(path);
  }

  setZoomFromPath(path) {
    const { allData } = this.state;
    let newRootData = { ...allData };

    path.forEach((e) => {
      const { children } = newRootData;
      newRootData = children.filter((child) => child.name == e).pop();
    });

    this.setState({
      root: newRootData,
      inZoom: !!path.length,
      path,
    });
  }

  parseData(parsedData, maxHeight) {
    let data = hierarchy(parsedData)
      .each((n) => {
        if (n.depth == maxHeight) {
          n.children = [];
        }
      })
      .sort((a, b) => (b.size || 0) - (a.size || 0))
      .count();
    data.height = Math.min(data.height, maxHeight);
    return data;
  }

  renderChart(width, parentHeight) {
    const {
      color = _color,
      margin = {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      },
    } = this.props;

    const { tooltipData, tooltipLeft, tooltipTop, tooltipOpen, hideTooltip } =
      this.props;

    const tooltipLabelStyle =
      tooltipOpen && tooltipData.color
        ? { color: tooltipData.Color }
        : undefined;

    const { inZoom, root, path } = this.state;
    let parentPath = [...path];
    parentPath.pop();

    const btnDisabled = !path.length ? "disabled" : "";

    const height = parentHeight - 40; // button
    const tooltipStyles = {
      ...defaultStyles,
      minWidth: 80,
      backgroundColor: "rgba(12, 35, 63,0.9)",
      color: "white",
    };

    const radius =
      Math.min(
        width - (margin.left + margin.right),
        height - (margin.top + margin.bottom)
      ) / 2;
    if (radius < 10) return null;

    const rotationAngle = (node) => {
      const arcAngle = ((360 / (2 * Math.PI)) * (node.x1 + node.x0)) / 2;
      return arcAngle <= 90 || arcAngle >= 270 ? arcAngle : 180 + arcAngle;
    };

    const labelFontSize = 10; // px
    const hasSpaceForLabel = (node, label) => {
      if (node.y0 == 0) {
        return label.length < node.y1 * 2;
      }
      // 1.2 is the leading/line-spacing
      const lines = Math.floor((node.y1 - node.y0) / (labelFontSize * 1.2)) - 1;
      // .55 is average width of a character compared to the font size.
      // Upper case are wider, lower case are narrower.
      const avgWidth = label == label.toUpperCase() ? 0.75 : 0.55;
      const width =
        ((node.x1 - node.x0) * node.y0) / (labelFontSize * avgWidth);
      const maxWord = Math.max(...label.split(" ").map((e) => e.length));
      //console.log(`${lines}, ${width}, ${maxWord}, ${label.length}, ${label}`);
      return lines * width > label.length && width > maxWord;
    };

    return (
      <div className="sunburst__container">
        <svg width={width} height={height}>
          <Group top={height / 2} left={width / 2}>
            <Partition
              top={0}
              left={0}
              root={this.parseData(root, width > 500 ? 3 : 2)}
              size={[2 * Math.PI, radius]}
            >
              {(data) => (
                <Group>
                  {data.descendants().map((node) => {
                    const display = inZoom || node.depth;
                    if (!display) {
                      return null;
                    }

                    const key = [
                      node.parent &&
                        node.parent.parent &&
                        node.parent.parent.data.name,
                      node.parent && node.parent.data.name,
                      node.data.name,
                      node.data._i !== undefined && node.data._i.toFixed(0),
                    ]
                      .filter((e) => e)
                      .map((e) => e.replaceAll(" ", ""))
                      .join("-");
                    // if radius is 0, just put the label in the center.
                    const [centroidX, centroidY] = node.y0
                      ? d3arc()
                          .innerRadius(node.y0)
                          .outerRadius(node.y1)
                          .startAngle(node.x0)
                          .endAngle(node.x1)
                          .centroid()
                      : [0, 0];

                    // subtended angle in radians * radius == inner arc length,
                    // or 2x the outer radius if inner radius=0
                    const width = node.y0
                      ? (node.x1 - node.x0) * node.y0
                      : node.y1 * 2;

                    const spaceForName = hasSpaceForLabel(node, node.data.name);
                    // only check the space for the abbr if it exists and we don't have space for the name.
                    const spaceForAbbr =
                      !spaceForName &&
                      node.data.abbr &&
                      hasSpaceForLabel(node, node.data.abbr);
                    const labelText = spaceForName
                      ? node.data.name
                      : spaceForAbbr
                      ? node.data.abbr
                      : "*";

                    const label = (
                      <Text
                        x={centroidX}
                        y={centroidY}
                        textAnchor="middle"
                        verticalAnchor="middle"
                        key={`label-${key}`}
                        angle={
                          spaceForName || spaceForAbbr ? rotationAngle(node) : 0
                        }
                        pointerEvents="none"
                        width={width}
                      >
                        {labelText}
                      </Text>
                    );
                    return (
                      <Group key={`g-${key}`}>
                        <Arc
                          key={`node-${key}`}
                          innerRadius={node.y0}
                          outerRadius={node.y1}
                          startAngle={node.x0}
                          endAngle={node.x1}
                          fill={color(node)}
                          stroke="#fff"
                          strokeWidth={1}
                          onClick={(e) => this.handleClick(e, node)}
                          onMouseOver={(e) =>
                            this.handleMouseOver(e, {
                              label: node.data.name,
                              value: node.data.hover,
                            })
                          }
                          onMouseOut={hideTooltip}
                        />
                        {label}
                      </Group>
                    );
                  })}
                </Group>
              )}
            </Partition>
          </Group>
        </svg>
        {tooltipOpen && (
          <TooltipWithBounds
            key={Math.random()}
            top={tooltipTop}
            left={tooltipLeft}
            style={tooltipStyles}
          >
            <div className="tooltip-wrapper">
              <div className="tooltip__label" style={tooltipLabelStyle}>
                <strong>{tooltipData.label}</strong>
              </div>
              {(tooltipData.value !== "" && (
                <div className="tooltip__value">
                  <strong>{tooltipData.value}</strong>
                </div>
              )) ||
                ""}
            </div>
          </TooltipWithBounds>
        )}
        <a
          className={`resetzoom ${btnDisabled}`}
          onClick={() => {
            this.setZoomFromPath([]);
            this.props.onNodeChange(undefined);
          }}
        >
          Reset Zoom
        </a>
        <a
          className={`backzoom ${btnDisabled}`}
          onClick={() => {
            this.setZoomFromPath(parentPath);
            const getPath = (path) =>
              path.reduce(
                (data, elem) =>
                  data.children.find((child) => child.name == elem),
                this.state.allData
              );
            const data = getPath(parentPath);
            this.props.onNodeChange(
              data.name === "root"
                ? undefined
                : {
                    ...data,
                    parent:
                      data._depth > 1 ? getPath(parentPath.slice(0, -1)) : {},
                  }
            );
          }}
        >
          Up
        </a>
      </div>
    );
  }

  render() {
    return (
      <ParentSize>
        {({ width: parentWidth, height: parentHeight }) =>
          (parentWidth && this.renderChart(parentWidth, parentHeight, _data)) ||
          ""
        }
      </ParentSize>
    );
  }
}

if (environment !== "production") {
  Sunburst.propTypes = {
    results: T.object,
  };
}

export default withTooltip(Sunburst);
